import { useRowsFromInvoiceItems } from '../../invoice-detail/components/InvoiceDetailBody.utils';
import type { InvoiceItemRow } from '../../invoice-detail/components/InvoiceTransactionsTable';
import type {
    ContractSpendTerm,
    InvoicePayment,
    InvoiceTableRow,
    PendingOrgInvoiceItem,
    PracticeInvoice,
    RawPracticeInvoice,
} from '../../invoicing.types';
import {
    convertToPracticeInvoices,
    getTargetPendingInvoiceCreationDate,
    useInvoicesForTableData,
} from '../../invoicing.utils';
import {
    CalculatePracticeInvoicedSpendOverPeriod_Query,
    GetActiveContractAndAssociatedOrgsByOrganizationId_Query,
    GetPracticeBillingOverview_Query,
    GetPracticePendingInvoiceItemsForContract_Query,
    GetPrimaryBillingUser_Query,
    ListInvoiceItemsForExport_Query,
    listInvoicePaymentsForExport_Query,
    ListInvoicesWithItemSummariesForActiveContract_Query,
} from './InvoiceTableProviderQueries.graphql';
import { useLazyQuery, useQuery } from '@apollo/client';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrdersByIds } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useFeatureFlag, usePrintableState } from '@orthly/veneer';
import dayjs from 'dayjs';
import _ from 'lodash';
import { compact, sumBy } from 'lodash';
import React from 'react';

interface PendingItems {
    pending_total_cents: number;
    pending_orders_count: number;
}

interface PrimaryBillingContact {
    email: string;
    name: string;
}

interface InvoicesTableContextType {
    invoices: PracticeInvoice[];
    refetch: () => Promise<unknown>;
    findInvoiceById: (id: string) => PracticeInvoice | undefined;
    tableInvoices: InvoiceTableRow[];
    loading: boolean;
    pendingItems: PendingItems;
    autochargeEnabled: boolean;
    currentSpendTerm: ContractSpendTerm | undefined;
    partnerId: string;
    contractAggregateSpend: number;
    primaryBillingContact: PrimaryBillingContact;

    /** Multi-Location Contract Info */
    isPracticePrimaryForContract: boolean;
    associatedPractices: { id: string; name: string }[];
    selectedOrganizationId: string | undefined;
    setSelectedOrganizationId: React.Dispatch<React.SetStateAction<string>>;
    selectedInvoiceIds: string[];
    setSelectedInvoiceIds: React.Dispatch<React.SetStateAction<string[]>>;
    payableSelectedInvoices: PracticeInvoice[];

    /** printability */
    isReadyToPrint: boolean;
    setIsReadyToPrint: React.Dispatch<React.SetStateAction<boolean>>;
    fetchPrintData: () => void;
    fetchPrintDataLoading: boolean;
    printableSlipIsOpen: boolean;
    openPrintableSlip: () => void;
    closePrintableSlip: () => void;
    invoicePayments: InvoicePayment[];
    rows: InvoiceItemRow[];
}

export const SELECT_ALL_ORGANIZATIONS = 'All locations';

const InvoicesTableContext = React.createContext<InvoicesTableContextType | null>(null);

// eslint-disable-next-line max-lines-per-function
export const InvoicesTableCtxProvider: React.FC = ({ children }) => {
    const partnerId = useSession()?.organization_id ?? '';
    const [selectedOrganizationId, setSelectedOrganizationId] = React.useState(SELECT_ALL_ORGANIZATIONS);
    const [selectedInvoiceIds, setSelectedInvoiceIds] = React.useState<string[]>([]);

    React.useEffect(() => {
        // when the organization id changes, deselect any selected invoices
        setSelectedInvoiceIds([]);
    }, [selectedOrganizationId]);

    // fetch partner billing data
    const { data: { getPartnerBillingAccount: partnerBillingAccount } = {} } = useQuery(
        GetPracticeBillingOverview_Query,
        {
            variables: { partnerId },
            fetchPolicy: 'no-cache',
            skip: !partnerId,
        },
    );

    // fetch primary billing contact
    const { data: { getUser: partnerBillingPrimaryContact } = {} } = useQuery(GetPrimaryBillingUser_Query, {
        variables: { id: partnerBillingAccount?.primary_billing_contact_user_id ?? '' },
        fetchPolicy: 'no-cache',
        skip: !partnerBillingAccount,
    });
    const primaryBillingContact = {
        email: partnerBillingPrimaryContact?.email ?? '',
        name: `${partnerBillingPrimaryContact?.first_name ?? ''} ${partnerBillingPrimaryContact?.last_name ?? ''}`,
    };

    // fetch (new) contract data
    const { data: { getContractAndAssociatedOrgsByOrganizationId: contractData } = {} } = useQuery(
        GetActiveContractAndAssociatedOrgsByOrganizationId_Query,
        {
            variables: { organizationId: partnerId },
            fetchPolicy: 'no-cache',
            skip: !partnerId,
        },
    );
    const { allAssociatedPractices = [], primaryPracticeId } = contractData ?? {};
    const spendTerms = contractData?.contract?.spend_terms ?? [];

    /** Pending Items ------------ */
    const previewPendingItemsForContractQuery = useQuery<{
        previewPracticePendingInvoiceItemsForActiveContract: PendingOrgInvoiceItem[];
    }>(GetPracticePendingInvoiceItemsForContract_Query, {
        variables: {
            targetCreationDate: getTargetPendingInvoiceCreationDate().toString(),
        },
    });

    const { data: { previewPracticePendingInvoiceItemsForActiveContract } = {}, loading: practicePendingItemsLoading } =
        previewPendingItemsForContractQuery;

    const pendingItemsRaw = compact(previewPracticePendingInvoiceItemsForActiveContract);

    const pendingItems = pendingItemsRaw?.filter(
        p => p.organization_id === selectedOrganizationId || selectedOrganizationId === SELECT_ALL_ORGANIZATIONS,
    );

    const orderPendingItems = React.useMemo(() => {
        const onlyPendingOrderItems = pendingItems?.filter(i => !!i.order_id);
        return {
            pending_total_cents: sumBy(onlyPendingOrderItems, 'amount_cents') ?? 0,
            pending_orders_count: onlyPendingOrderItems?.length ?? 0,
        };
    }, [pendingItems]);
    /** End Pending Items ------------ */

    /** Invoices --------------------- */
    // fetch invoices for the current contract
    // if the requesting org id is the primary on the contract, the response will include
    // invoices for *all* associated practices
    // else, the response will only include invoices for the requesting org
    const invoicesWithItemSummariesForActiveContract = useQuery(ListInvoicesWithItemSummariesForActiveContract_Query, {
        skip: !partnerId,
    });

    const {
        data: { listInvoicesWithItemSummariesForActiveContract } = {},
        loading: rawInvoicesLoading = true,
        refetch: refetchRawInvoices,
    } = invoicesWithItemSummariesForActiveContract;

    const practiceInvoices = convertToPracticeInvoices<RawPracticeInvoice>(
        listInvoicesWithItemSummariesForActiveContract ?? [],
    );

    const invoices = practiceInvoices.filter(
        i => i.organization_id === selectedOrganizationId || selectedOrganizationId === SELECT_ALL_ORGANIZATIONS,
    );

    const findInvoiceById = React.useCallback(
        (id: string) => {
            return invoices?.find(i => i.id === id);
        },
        [invoices],
    );
    /** End Invoices --------------------- */

    /** Printability --------------------- */
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();
    const [isReadyToPrint, setIsReadyToPrint] = React.useState(false);
    const [
        fetchInvoiceItems,
        { data: { invoiceItems = [] } = {}, loading: fetchInvoiceItemsLoading, called: invoiceItemsFetched },
    ] = useLazyQuery(ListInvoiceItemsForExport_Query);

    const [
        fetchInvoicePayments,
        { data: { invoicePayments = [] } = {}, loading: fetchInvoicePaymentsLoading, called: invoicePaymentsFetched },
    ] = useLazyQuery(listInvoicePaymentsForExport_Query);

    // fetch the data necessary to populate the invoice(s)
    const fetchPrintData = React.useCallback(() => {
        fetchInvoiceItems({ variables: { invoiceIds: selectedInvoiceIds } });
        fetchInvoicePayments({ variables: { invoiceIds: selectedInvoiceIds } });
    }, [fetchInvoiceItems, fetchInvoicePayments, selectedInvoiceIds]);

    const orderIds = React.useMemo(() => compact(invoiceItems.map(i => i.order_id)), [invoiceItems]);
    const { orders: ordersRaw, loading: ordersLoading } = useOrdersByIds(orderIds);
    const orders = React.useMemo<LabsGqlOrder[]>(() => compact(ordersRaw), [ordersRaw]);
    // TODO: EPDB-1063: wire up credits and credit categories
    const rows = useRowsFromInvoiceItems(invoiceItems, orders, [], []);

    React.useEffect(() => {
        // once we've retrieved the necessary (additional) data, we can open the printable slip
        if (
            !fetchInvoiceItemsLoading &&
            invoiceItemsFetched &&
            !fetchInvoicePaymentsLoading &&
            invoicePaymentsFetched &&
            !ordersLoading
        ) {
            setIsReadyToPrint(true);
        }
    }, [
        fetchInvoiceItemsLoading,
        invoiceItemsFetched,
        fetchInvoicePaymentsLoading,
        invoicePaymentsFetched,
        ordersLoading,
    ]);
    /** End Printability --------------------- */

    const autochargeEnabled = React.useMemo(
        () => partnerBillingAccount?.autocharge_enabled ?? false,
        [partnerBillingAccount],
    );

    const currentSpendTerm = spendTerms.find(t => {
        const today = new Date();
        const startsBeforeToday = new Date(t.effective_start_date ?? '') <= today;
        // if the spend term does not have an end date, it's active (rolling term)
        const endsAfterToday = t.effective_end_date ? new Date(t.effective_end_date) >= today : true;
        return startsBeforeToday && endsAfterToday;
    });

    // the overview table uses additional properties that are not present in the raw invoices
    const tableInvoices = useInvoicesForTableData(
        invoices,
        spendTerms,
        // specifically allowing this to be an array, an empty array, or undefined
        // if undefined, the preview data is not applicable, and if empty, there are no
        // pending items (but we should still represent the current spend)
        pendingItems,
    );

    const isInvoicePayable = (i: PracticeInvoice) => {
        const isOpen = i.status === 'open';
        const amountPaidAndPending = i.amount_paid + i.pending_payment_amount_cents;
        const amountRemaining = i.amount_due - amountPaidAndPending;

        return isOpen && amountRemaining > 0;
    };

    const payableSelectedInvoices = compact(selectedInvoiceIds.map(id => findInvoiceById(id))).filter(isInvoicePayable);

    const now = React.useMemo(() => dayjs(), []);

    const effectiveStartDate = currentSpendTerm?.effective_start_date;
    const effectiveEndDate = currentSpendTerm?.effective_end_date;

    const { data: { calculatePracticeInvoicedSpendOverPeriod: invoicedSpends } = {}, loading: invoicedSpendLoading } =
        useQuery(CalculatePracticeInvoicedSpendOverPeriod_Query, {
            variables: {
                organizationId: partnerId,
                periodStart: effectiveStartDate ?? '',
                periodEnd: effectiveEndDate ?? '',
            },
            skip: !effectiveStartDate || !effectiveEndDate || dayjs(effectiveStartDate) > now,
        });

    const invoicedSpendForOrgs = invoicedSpends?.filter(
        s => s.organization_id === selectedOrganizationId || selectedOrganizationId === SELECT_ALL_ORGANIZATIONS,
    );

    const contractAggregateSpend = React.useMemo(
        () => orderPendingItems.pending_total_cents + _.sumBy(invoicedSpendForOrgs, s => s.aggregate_spend_cents),
        [orderPendingItems, invoicedSpendForOrgs],
    );

    return (
        <InvoicesTableContext.Provider
            value={{
                invoices,
                refetch: refetchRawInvoices,
                findInvoiceById,
                tableInvoices,
                loading: rawInvoicesLoading || practicePendingItemsLoading || invoicedSpendLoading,
                pendingItems: orderPendingItems,
                autochargeEnabled,
                currentSpendTerm,
                partnerId: partnerId ?? '',
                contractAggregateSpend,
                primaryBillingContact,

                /** Multi-Location Contract Info */
                isPracticePrimaryForContract: primaryPracticeId === partnerId,
                associatedPractices: allAssociatedPractices,
                selectedOrganizationId,

                /** Multi-Location controls */
                setSelectedOrganizationId,
                selectedInvoiceIds,
                setSelectedInvoiceIds,
                payableSelectedInvoices,

                /** printability */
                fetchPrintData,
                fetchPrintDataLoading: fetchInvoiceItemsLoading,
                setIsReadyToPrint,
                isReadyToPrint,
                printableSlipIsOpen: printableIsOpen,
                openPrintableSlip: openPrintable,
                closePrintableSlip: closePrintable,
                invoicePayments,
                rows,
            }}
        >
            {children}
        </InvoicesTableContext.Provider>
    );
};

export function useInvoicesTableContext() {
    const ctx = React.useContext(InvoicesTableContext);
    if (!ctx) {
        throw new Error('useInvoicesTableContext must be used within a InvoicesTableCtxProvider');
    }

    return ctx;
}

/**
 * Utility hook that returns true if multi location billing is enabled for this session.
 * This requires that:
 *  - The current organization is the primary organization in the contract
 *  - There are more than 1 practices in the contract
 *  - (NEW) The user isn't explicitly banned from accessing by Feature Flag
 */
export function useIsMultiLocationBillingEnabled() {
    const { isPracticePrimaryForContract, associatedPractices } = useInvoicesTableContext();

    // This feature flag is only meant to be used in very specific edge cases.
    // In particular, we are using it to limit access to multi-location billing for our new DSO being onboarded.
    const { value: isDisabledByFeatureFlag } = useFeatureFlag('multiLocationBillingDisabledForUser');
    if (isDisabledByFeatureFlag) {
        return false;
    }

    return isPracticePrimaryForContract && associatedPractices.length > 1;
}
