import { Box, FlossPalette, Icon, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const StyledBox = styled(Box)({
    display: 'flex',
    gap: '16px',
    flexDirection: 'row',
    borderRadius: '16px',
    background: FlossPalette.ATTENTION_BACKGROUND,
});

const StyledIcon = styled(Icon)({
    color: FlossPalette.ATTENTION_FOREGROUND,
});

const TextContainer = styled(Box)({
    display: 'grid',
    gap: '8px',
});

const getPadding = (variant: 'slim' | 'normal' | 'padded') => {
    switch (variant) {
        case 'slim':
            return '12px';
        case 'normal':
            return '16px';
        case 'padded':
            return '32px 16px';
    }
};

interface OutstandingBalanceNoticeProps {
    onLinkClick: () => void;
    variant?: 'slim' | 'normal' | 'padded';
}

export const OutstandingBalanceNotice: React.VFC<OutstandingBalanceNoticeProps> = ({ variant, onLinkClick }) => {
    const padding = getPadding(variant ?? 'normal');

    return (
        <StyledBox sx={{ padding }}>
            <StyledIcon icon={'Error'} />

            <TextContainer>
                <Text variant={'body2'} medium>
                    Notice: Order on hold upon submission
                </Text>

                <Text variant={'body2'} color={'GRAY'}>
                    This order will be placed on hold upon submission. Visit your{' '}
                    <Text
                        onClick={onLinkClick}
                        component={'span'}
                        variant={'body2'}
                        medium
                        color={'PRIMARY_FOREGROUND'}
                        style={{ cursor: 'pointer' }}
                    >
                        Portal
                    </Text>{' '}
                    for an important update about your account that may impact processing.
                </Text>
            </TextContainer>
        </StyledBox>
    );
};
