import { SCANNER_SUBMIT_ROUTE } from '../CheckoutPaths';
import { DenturesCheckoutActions } from '../dentures-checkout.actions';
import { denturesStepProgression } from './dentures-checkout.progression';
import type { DentureCheckoutState } from './dentures-checkout.types';
import { DentureCheckoutStep } from './dentures-checkout.types';
import { LabsGqlDenturesFabricationMethod, LabsGqlDenturesTeethQuality } from '@orthly/graphql-schema';
import { DentureType } from '@orthly/items';
import { Assert } from '@orthly/runtime-utils';
import { matchPath } from 'react-router-dom';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'redux-first-history';
import type { RouterState } from 'redux-first-history';

const initialDentureCheckoutState: DentureCheckoutState = {
    history: [],
    currentStep: DentureCheckoutStep.PatientInfoStep,
    fields: {},
};

export const dentureCheckoutReducer = handleActions<DentureCheckoutState, any>(
    {
        // reset state when the user navigates away from checkout
        [LOCATION_CHANGE]: (state: DentureCheckoutState, action: Action<RouterState>) =>
            !action.payload.location ||
            matchPath(action.payload.location.pathname, `/lab/submit`) ||
            matchPath(action.payload.location.pathname, SCANNER_SUBMIT_ROUTE)
                ? state
                : initialDentureCheckoutState,
        ...DenturesCheckoutActions.PREV_DENTURES_STEP.reducer(state => {
            if (state.history.length === 0) {
                return state;
            }
            return {
                ...state,
                currentStep: state.history[state.history.length - 1] ?? DentureCheckoutStep.PatientInfoStep,
                history: state.history.slice(0, state.history.length - 1),
            };
        }),
        ...DenturesCheckoutActions.NEXT_DENTURES_STEP.reducer(state => {
            const nextStep = denturesStepProgression(state);

            if (nextStep.type === 'NOT_COMPLETE') {
                return state;
            }

            if (nextStep.type === 'CHANGE_STEP') {
                return {
                    ...state,
                    currentStep: nextStep.step,
                    history: [...state.history, state.currentStep],
                };
            }

            Assert.unreachable(nextStep);
            throw new Error('unreachable');
        }),
        ...DenturesCheckoutActions.CHOOSE_DENTURE_TYPE.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureType: action.payload,
                },
            };
        }),
        ...DenturesCheckoutActions.CHOOSE_IS_EXACT_COPY.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isExactCopy: action.payload,
                },
            };
        }),
        ...DenturesCheckoutActions.ADD_EXACT_COPY_NOTES.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    exactCopyNotes: action.payload,
                },
            };
        }),
        ...DenturesCheckoutActions.CHOOSE_DENTURE_ARCH.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureArch: action.payload,
                },
            };
        }),
        ...DenturesCheckoutActions.ADD_WAX_RIM_PHOTO_URL.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    waxRimPhotoUrls: [...(state.fields.waxRimPhotoUrls ?? []), action.payload],
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_FABRICATION.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureOptions: {
                        ...(state.fields.dentureOptions ?? {}),
                        fabrication: action.payload,
                    },
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_TEETH_QUALITY.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureOptions: {
                        ...(state.fields.dentureOptions ?? {}),
                        teethQuality: action.payload,
                    },
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_PRODUCTION_TYPE.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureOptions: {
                        ...(state.fields.dentureOptions ?? {}),
                        productionType: action.payload,
                    },
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_SHADE.reducer((state, action) => {
            const { name, value } = action.payload;
            const unchangedShades = (state.fields.dentureOptions?.shades ?? []).filter(shade => shade.name !== name);

            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureOptions: {
                        ...(state.fields.dentureOptions ?? {}),
                        shades: value ? [...unchangedShades, { name, value }] : unchangedShades,
                    },
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_STYLE.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureStyle: action.payload,
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_FESTOONING_LEVEL.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    festooningLevel: action.payload,
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_HANDLE_DIASTEMA.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    handleDiastema: action.payload,
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_BITE_ADJUSTMENT.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    biteAdjustment: action.payload,
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_MIDLINE_CORRECTION.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    midlineCorrection: action.payload,
                },
            };
        }),

        ...DenturesCheckoutActions.CHOOSE_DENTURE_AESTHETIC_OPTIONS.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    aestheticOptions: action.payload,
                },
            };
        }),

        ...DenturesCheckoutActions.ADD_DENTURE_NOTES.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dentureNotes: action.payload.length ? action.payload : undefined,
                },
            };
        }),

        ...DenturesCheckoutActions.SET_DENTURE_PHOTO_URLS.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    denturePhotos: action.payload.map(path => ({ url: path })),
                },
            };
        }),
        ...DenturesCheckoutActions.SET_DESKTOP_UPLOAD.reducer((state, action) => {
            return {
                ...state,
                desktopUpload: action.payload,
                currentStep: DentureCheckoutStep.InitialNonWaxRimOrderPhotosReviewStep,
                history: [...state.history, state.currentStep],
            };
        }),
        ...DenturesCheckoutActions.SET_WAX_RIM_CONTINUATION.reducer((state, action) => {
            return {
                ...state,
                currentStep: DentureCheckoutStep.InitialNonWaxRimOrderDentureOptionsStep,
                history: [...state.history, state.currentStep],
                fields: {
                    ...state.fields,
                    dentureType: DentureType.WaxRimContinuation,
                    waxRimContinuation: action.payload,
                    dentureArch: action.payload.arch,
                    implantSupport: action.payload.implantSupport,
                    dentureOptions: {
                        ...(state.fields.dentureOptions ?? {}),
                        teethQuality: LabsGqlDenturesTeethQuality.Printed,
                        fabrication: LabsGqlDenturesFabricationMethod.Printed,
                    },
                },
            };
        }),
        ...DenturesCheckoutActions.SET_NUMBER_OF_SPARES.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    numberOfSpares: action.payload,
                },
            };
        }),
        ...DenturesCheckoutActions.SET_IMPLANT_SUPPORT.reducer((state, action) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    implantSupport: action.payload,
                },
            };
        }),
    },
    initialDentureCheckoutState,
);
