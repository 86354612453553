import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { RootActionDialog, LoadBlocker, useScreenIsMobile } from '@orthly/ui';
import { styled, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

const ActionRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '48px',
});

const Description = styled(Text)({
    marginTop: '24px',
});

const RightButton = styled(Button)({
    marginLeft: 'auto',
});

type Props = {
    order: Pick<LabsGqlOrder, 'id'>;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSubmit: () => void;
    loading: boolean;
};

const Content: React.FC<Props> = ({ setOpen, onSubmit, loading, order }) => {
    const innerOnSubmit = () => {
        AnalyticsClient.track('Practice - DDP Request Dialog Confirmed', {
            $groups: { order: order.id },
            action: 'remove',
        });
        onSubmit();
    };

    return (
        <LoadBlocker blocking={loading}>
            <div>
                <Description color={'DARK_GRAY'}>
                    You're about to cancel your request for a design preview. If canceled, Dandy will proceed with this
                    order as is.
                </Description>
                <ActionRow>
                    <Button variant={'secondary'} onClick={setOpen.bind(null, false)}>
                        Go Back
                    </Button>
                    <RightButton variant={'alert-secondary'} onClick={innerOnSubmit}>
                        Confirm Cancellation
                    </RightButton>
                </ActionRow>
            </div>
        </LoadBlocker>
    );
};

export const OrderDetailDDPRemovalDialog: React.FC<Props> = props => {
    const screenIsMobile = useScreenIsMobile();
    const { open, setOpen } = props;

    return (
        <RootActionDialog
            title={'Cancel Digital Design Preview request'}
            setOpen={setOpen}
            open={open}
            content={<Content {...props} />}
            dialogProps={{ maxWidth: 'sm' }}
            onClose={setOpen.bind(null, false)}
            showCloseButton={!screenIsMobile}
            loading={false}
            hideButton
        />
    );
};
