import { usePracticePause } from '../../../../PracticeBannerProvider.graphql';
import { useAlignerCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { useCheckoutAction } from '../../state/checkout.actions';
import { useScanIsAligner } from '../../state/checkout.selectors';
import { NotificationPreferencesSection } from '../NotificationPreferencesSummarySection/NotificationPreferencesSection';
import { CheckoutExpectedDelivery } from './CheckoutExpectedDelivery';
import { OutstandingBalanceNotice, PracticeScreen } from '@orthly/dentin';
import { useGetNextPartnerOrderNumberQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { SimpleCheckbox } from '@orthly/ui';
import { FlossPalette, createStyles, makeStyles, Grid, Text, Typography } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        halfText: { maxWidth: '50%' },
        borderedRow: {
            position: 'relative',
            flexDirection: 'column',
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            padding: `12px 0`,
        },
    }),
);

type Row = { name?: string; value: string };

const SummaryRow: React.FC<{ row: Row }> = props => {
    const classes = useStyles();
    const { row } = props;
    return (
        <Grid container justifyContent={'space-between'} alignItems={'flex-start'} style={{ paddingBottom: 2 }}>
            <Typography color={'textSecondary'} className={classes.halfText}>
                {row.name}
            </Typography>
            <Typography
                color={'textPrimary'}
                className={classes.halfText}
                style={{ fontWeight: 500, textAlign: 'right' }}
            >
                {row.value}
            </Typography>
        </Grid>
    );
};

const AlignerItemEstimateSection: React.VFC = () => {
    const { estimate, overriddenPatientSteps } = useAlignerCheckoutPropSelector(['estimate', 'overriddenPatientSteps']);
    const requirements = _.compact([
        estimate?.needsIpr ? 'IPR' : undefined,
        estimate?.needsAttachments ? 'Attachments' : undefined,
    ]);
    const rows = React.useMemo(() => {
        return _.compact([
            { name: 'Requirements', value: requirements.length === 0 ? 'none' : requirements.join(', ') },
            overriddenPatientSteps && { name: 'Proposed step limit', value: `${overriddenPatientSteps} steps` },
        ]);
    }, [requirements, overriddenPatientSteps]);
    if (!estimate) {
        return null;
    }
    return (
        <>
            <Grid container justifyContent={'space-between'} alignItems={'flex-start'} style={{ paddingBottom: 2 }}>
                <Text variant={'body2'} color={'BLACK'} style={{ fontWeight: 500 }}>
                    Estimate
                </Text>
            </Grid>
            {rows.map(row => (
                <SummaryRow row={row} key={row.name} />
            ))}
        </>
    );
};

const SummaryItems: React.FC = () => {
    const doctorName = useCheckoutSelector(s => (s.doctor ? s.doctor.formatted_name : '-'));
    const deliveryAddress = useCheckoutSelector(s =>
        s.address ? _.startCase(`${s.address.street_one}`.toLowerCase()) : '-',
    );
    const patient = useCheckoutSelector(s =>
        s.patient_first_name && s.patient_last_name ? `${s.patient_first_name} ${s.patient_last_name}` : '-',
    );
    const rows = React.useMemo(() => {
        return [
            { name: 'Doctor', value: doctorName },
            { name: 'Delivery address', value: deliveryAddress },
            { name: 'Patient', value: patient },
        ];
    }, [deliveryAddress, doctorName, patient]);
    return (
        <>
            {rows.map(row => (
                <SummaryRow row={row} key={row.name} />
            ))}
        </>
    );
};

export const CheckoutSummaryReviewColumn: React.FC = () => {
    const classes = useStyles();
    const isAligner = useScanIsAligner();
    const isTrainingOrder = useCheckoutSelector(s => s.is_training_order);
    const setIsTrainingOrder = useCheckoutAction('SET_IS_TRAINING_ORDER');
    const partner_id = useSession()?.organization_id;
    const history = useHistory();

    const partnerOrderNumber = useGetNextPartnerOrderNumberQuery({
        variables: { partner_id: partner_id ?? '' },
        skip: !partner_id,
    });

    const practiceIsPaused = usePracticePause();

    const isFirstFiveOrder = (partnerOrderNumber?.data?.getNextPartnerOrderNumber ?? Infinity) < 5;

    return (
        <div style={{ height: '100%', overflowY: 'scroll' }}>
            <Grid container item xs={12} direction={'column'} wrap={'nowrap'}>
                <Grid container className={classes.borderedRow}>
                    <SummaryItems />
                </Grid>

                {practiceIsPaused ? (
                    <Grid container style={{ marginTop: 12 }}>
                        <OutstandingBalanceNotice onLinkClick={() => history.push(`/${PracticeScreen.inbox}`)} />
                    </Grid>
                ) : (
                    <Grid container className={classes.borderedRow}>
                        <CheckoutExpectedDelivery />
                    </Grid>
                )}

                {isFirstFiveOrder && (
                    <Grid container className={classes.borderedRow}>
                        <SimpleCheckbox
                            label={'Is this a training order?'}
                            checked={isTrainingOrder}
                            setChecked={() => setIsTrainingOrder(!isTrainingOrder)}
                        />
                    </Grid>
                )}

                {isAligner && (
                    <Grid container className={classes.borderedRow}>
                        <AlignerItemEstimateSection />
                    </Grid>
                )}

                <Grid container style={{ marginTop: 48 }}>
                    <NotificationPreferencesSection />
                </Grid>
            </Grid>
        </div>
    );
};
