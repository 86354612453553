import { TAB_BAR_HEIGHT } from './layouts/mobile/MobileTabs';
import { useShowMobileLayout } from './utils/LayoutUtils';
import { useIsChairside } from './utils/chairside.hooks';
import { useQuery } from '@apollo/client';
import { HORIZONTAL_NAV_HEIGHT, PracticeScreen, useIsPath } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';
import { useScreenIsMd, useScreenIsMobile, useScreenIsSm } from '@orthly/ui-primitives';
import type { PracticeBanner } from '@orthly/veneer';
import { PRACTICE_OUTAGE_BANNER_HEIGHT, useFeatureFlag } from '@orthly/veneer';
import constate from 'constate';

const GetPracticePause_Query = graphql(`
    query GetPracticePause_Query($practice_id: String!) {
        getPracticeOrderingPauseInformation(practice_id: $practice_id) {
            is_archived
        }
    }
`);

const useContentHeightParams = () => {
    const chairside = useIsChairside();

    const screenIsMobile = useScreenIsMobile();
    const screenIsSmall = useScreenIsSm();
    const screenIsMedium = useScreenIsMd();
    const isMobileLayout = useShowMobileLayout();

    return {
        chairside,
        screenIsMobile,
        screenIsSmall,
        screenIsMedium,
        isMobileLayout,
    };
};

interface BannerFeatureFlags {
    maintenanceBannerPractice: { title: string; description: string }[] | undefined;
    isPracticePaused: boolean;
}

const useCalculateContentHeight = (flags: BannerFeatureFlags): string => {
    const { chairside, screenIsMedium, screenIsMobile, isMobileLayout } = useContentHeightParams();

    const { maintenanceBannerPractice, isPracticePaused } = flags;

    let offsetHeight = 0;

    if (!screenIsMobile && !chairside) {
        offsetHeight += HORIZONTAL_NAV_HEIGHT;
    }

    if (isMobileLayout) {
        offsetHeight += TAB_BAR_HEIGHT;
    }

    if ((!!maintenanceBannerPractice?.length || isPracticePaused) && !screenIsMedium) {
        offsetHeight += PRACTICE_OUTAGE_BANNER_HEIGHT;
    }

    return `calc(100vh - ${offsetHeight}px)`;
};

const useCalculatePaddingTop = (flags: BannerFeatureFlags): number => {
    const { chairside, screenIsSmall, screenIsMobile, screenIsMedium } = useContentHeightParams();

    const { maintenanceBannerPractice, isPracticePaused } = flags;

    let paddingTop = 0;

    if (useIsPath(PracticeScreen.onboarding)) {
        return 0;
    }

    if (!screenIsMobile && !screenIsSmall && !chairside) {
        paddingTop += HORIZONTAL_NAV_HEIGHT;
    }

    if ((!!maintenanceBannerPractice?.length || isPracticePaused) && !screenIsMedium) {
        paddingTop += PRACTICE_OUTAGE_BANNER_HEIGHT;
    }

    return paddingTop;
};

interface PracticeBannerData {
    banner: PracticeBanner | null;
    contentPaddingTop: number;
    contentHeight: string;
}

/**
 * The Practice app is wrapped in the `<PracticeBannerProvider>`, which supplies the result of this query.
 * However, since there are certain cases — checkout, for instance — where we'd prefer know whether the practice
 * is paused on-demand, we can use this hook to do so.
 */
export const usePracticePause = () => {
    const { value: enableOutstandingBalanceCommunications } = useFeatureFlag('enableOutstandingBalanceCommunications');

    const session = useSession();

    const { data: practicePauseRawData } = useQuery(GetPracticePause_Query, {
        variables: { practice_id: session?.organization_id ?? '' },
    });

    if (!enableOutstandingBalanceCommunications) {
        return false;
    }

    return (
        !!practicePauseRawData?.getPracticeOrderingPauseInformation &&
        !practicePauseRawData.getPracticeOrderingPauseInformation.is_archived
    );
};

const useContentHeightProps = (flags: BannerFeatureFlags) => {
    const { maintenanceBannerPractice, isPracticePaused } = flags;

    const paddingTop = useCalculatePaddingTop({ maintenanceBannerPractice, isPracticePaused });
    const contentHeight = useCalculateContentHeight({
        maintenanceBannerPractice,
        isPracticePaused,
    });

    return { contentHeight, contentPaddingTop: paddingTop };
};

function usePracticeBannerDataRaw(): PracticeBannerData {
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');

    const isPracticePaused = usePracticePause();

    const contentHeightProps = useContentHeightProps({ maintenanceBannerPractice, isPracticePaused });

    if (maintenanceBannerPractice?.length && maintenanceBannerPractice[0]) {
        return {
            banner: { ...maintenanceBannerPractice[0], type: 'warning' },
            ...contentHeightProps,
        };
    }

    if (isPracticePaused) {
        return {
            banner: {
                title: 'Invoice overdue – new orders are on hold until balance is addressed. Please contact Billing at (914)-402-9354 to address it.',
                type: 'critical',
            },
            ...contentHeightProps,
        };
    }

    return { banner: null, ...contentHeightProps };
}

export const [PracticeBannerProvider, usePracticeBannerData] = constate(usePracticeBannerDataRaw);
