import { useOrderDetailsContext } from '../OrderDetailTracking.context.graphql';
import { Link, Text } from '@orthly/ui-primitives';
import React from 'react';

export const useBillingIssueText = () => {
    const { order } = useOrderDetailsContext();

    if (order.hold_details?.hold_category === 'Billing Issue') {
        return (
            <Text variant={'body2'}>
                To address it, reach out to the Dandy Billing Team at{' '}
                <Text component={'span'} variant={'body2'} medium>
                    <Link href={'tel:914-402-9354'}>(914)-402-9354</Link>
                </Text>
            </Text>
        );
    }

    return null;
};
