import { PracticeUtils } from '../../../utils/utils';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

export const useDoctorId = (): string => {
    const navigate = useNavigate();
    const prefId = PracticeUtils.getParsedQueryProperty(`prefId`, location.search) ?? '';

    React.useEffect(() => {
        if (!prefId) {
            navigate('/inbox');
        }
    }, [prefId]); // eslint-disable-line react-hooks/exhaustive-deps

    return prefId;
};
