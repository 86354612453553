import {
    SELECT_ALL_ORGANIZATIONS,
    useInvoicesTableContext,
    useIsMultiLocationBillingEnabled,
} from './providers/InvoicesTableProvider.graphql';
import type { SimpleSelectProps } from '@orthly/ui';
import { SimpleSelect } from '@orthly/ui';
import { FlossPalette, styled } from '@orthly/ui-primitives';

const Container = styled('div')({
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',

    '& .MuiInputBase-root': {
        padding: '8px 24px 4px 0px !important',
    },

    '& .MuiSvgIcon-root': {
        right: '8px',
    },
});

export const InvoicesTableFilters: React.FC = () => {
    const { associatedPractices, selectedOrganizationId, setSelectedOrganizationId, loading } =
        useInvoicesTableContext();
    const options = associatedPractices.map(r => ({ value: r.id, label: r.name }));

    const commonSelectProps: Partial<SimpleSelectProps> = {
        variant: 'standard',
        backgroundColor: FlossPalette.WHITE,
        FormControlProps: { variant: 'standard' },
        SelectProps: {
            displayEmpty: true,
            disableUnderline: true,
            variant: 'standard',
            SelectDisplayProps: {
                style: { height: '32px' },
            },
        },
        style: { height: '32px' },
        disabled: loading,
    };

    // the associatedPractices arr includes the primary, so if it has more than one entry, the contract has multiple locations
    const showLocationSelect = useIsMultiLocationBillingEnabled();
    return (
        <Container>
            {/* <div>TODO: Doctor filter</div> */}
            {showLocationSelect && (
                <SimpleSelect
                    {...commonSelectProps}
                    options={[{ value: SELECT_ALL_ORGANIZATIONS }, ...options]}
                    onChange={(value?: string | undefined) => {
                        if (value) {
                            setSelectedOrganizationId(value);
                        }
                    }}
                    label={''}
                    value={selectedOrganizationId}
                />
            )}
        </Container>
    );
};
