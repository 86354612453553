import { PricingGuide } from '../../../account/practice-settings/pricing-guide/PricingGuide';
import { useOpenOrderDetailPage } from '../../LabsUtils';
import { ChatAction } from '../../actions/ChatAction';
import { ExploreHelpCenterButton } from '../../actions/ExploreHelpCenterButton';
import {
    OrderActionParent,
    OrderActionParentTrackerIdMap,
    trackRenderedOrderDetailActions,
} from '../../actions/OrderActionsUtils';
import { UploadOrTakePhotoAction } from '../../actions/UploadOrTakePhotoAction';
import {
    AddExistingScanMobileDialog,
    AttachExistingScanMobileButton,
} from '../../actions/practitioners/AttachExistingScanMobileAction';
import { AddNewScanAction } from '../../actions/practitioners/AttachNewScanAction';
import { CancelOrderButton } from '../../actions/practitioners/CancelOrder/CancelOrderButton';
import { CancelOrderDialog } from '../../actions/practitioners/CancelOrder/CancelOrderDialog';
import { EditAddressAction } from '../../actions/practitioners/EditAddressAction';
import { EditDoctorAction } from '../../actions/practitioners/EditDoctorAction';
import {
    PauseOrderMobileDialog,
    PauseOrResumeOrderMobileButton,
} from '../../actions/practitioners/PauseOrResumeOrder/PauseOrResumeOrderMobileAction';
import type { CreateChatMessageMutationVariables } from '../../components/AddOrderNotesDialog';
import { AddOrderNotesDialog } from '../../components/AddOrderNotesDialog';
import { useWaxupMutations } from '../useWaxupMutations';
import { OrderDetailDDPAdditionDialog } from './OrderDetailDDPAdditionDialog';
import { OrderDetailDDPRemovalDialog } from './OrderDetailDDPRemovalDialog';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { AnalyticsEventWithName } from '@orthly/analytics/dist/common';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useCreateChatMessageMutation, useOrderRefetch } from '@orthly/graphql-react';
import { LabsGqlChatEntityTypes, LabsGqlControlPanelActionType, LabsGqlStaffRoleWithAny } from '@orthly/graphql-schema';
import { ExtraCartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn, useScreenIsMobile } from '@orthly/ui';
import { FlossPalette, MoreVertIcon, Button, Popover, Divider, styled } from '@orthly/ui-primitives';
import {
    OrderEditActionButton,
    OrderEditActionDialog,
    useFeatureFlag,
    useIsDesignActionEditable,
} from '@orthly/veneer';
import React from 'react';

const ButtonContainer = styled('div')({
    display: 'flex',
    gridGap: '8px',
});
const DropdownContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
});
const StyledButton = styled(Button)({
    height: 'unset',
    minWidth: 'unset',
});

interface OrderDetailTopBarActionsV2 {
    order: LabsGqlOrder;
}

/* eslint-disable-next-line max-lines-per-function */
export const OrderDetailTopBarActionsV2: React.FC<OrderDetailTopBarActionsV2> = ({ order }) => {
    const { value: enablePostOrderDDP } = useFeatureFlag('enablePostOrderDDP');
    const refetchOrder = useOrderRefetch();
    const openOrder = useOpenOrderDetailPage();
    const screenIsMobile = useScreenIsMobile();
    const [editActionDialogState, setEditActionDialogState] = React.useState<'instructions' | 'edit' | null>(null);
    const [isAttachScansOpen, setIsAttachScansOpen] = React.useState(false);
    const [isPauseOrderDialogOpen, setIsPauseOrderDialogOpen] = React.useState(false);
    const [isCancelOrderDialogOpen, setIsCancelOrderDialogOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'order-detail-menu-popover' : undefined;

    const modifyOrderActionClicked = (isOpen: boolean) => {
        setEditActionDialogState(isOpen ? 'edit' : null);
    };

    const organizationType = useSession()?.organization_type ?? 'practice';
    const [attachmentUrls, setAttachmentUrls] = React.useState<string[]>([]);
    const [toastOpen, setToastOpen] = React.useState(false);
    const [submitCreateChatMessageMtn] = useCreateChatMessageMutation();
    const createChatMessageMtnSubmitter = (data: CreateChatMessageMutationVariables) =>
        submitCreateChatMessageMtn({
            variables: {
                data: {
                    ...data,
                    attachment_urls: attachmentUrls,
                    entity_type: LabsGqlChatEntityTypes.Order,
                    entity_id: order.id,
                    visible_to_roles: getChatMessageVisibilityForWrite(organizationType, [
                        LabsGqlStaffRoleWithAny.LabAny,
                    ]),
                    action_type: LabsGqlControlPanelActionType.LeaveNote,
                },
            },
        });
    const {
        open: addOrderNotesDialogOpen,
        setOpen: setAddOrderNotesDialogOpen,
        submit: sumbitAddOrderNotes,
        submitting: sumbittingAddOrderNotes,
    } = useChangeSubmissionFn<any, [CreateChatMessageMutationVariables]>(createChatMessageMtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [undefined, {}],
        onSuccess: () => {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Control Panel - Leave Note Action Submitted', {
                $groups: { order: order.id },
            });
            setToastOpen(true);
            setAttachmentUrls([]);
        },
    });

    const {
        addWaxup,
        addingWaxup,
        addWaxupOpen,
        setAddWaxupOpen,
        removeWaxup,
        removingWaxup,
        removeWaxupOpen,
        setRemoveWaxupOpen,
    } = useWaxupMutations(order.id, refetchOrder);
    const { value: enableFlexiPartialDesignPreview } = useFeatureFlag('enableFlexiPartialDesignPreview');
    const isDesignEditable = useIsDesignActionEditable(order);
    const itemsEligibleForWaxup = ExtraCartItemV2Utils.shouldEnableWaxupOption(
        OrderItemV2Utils.parseItems(order.items_v2),
        enableFlexiPartialDesignPreview,
    );
    const disableDDP = !(isDesignEditable && itemsEligibleForWaxup);

    let ddpControl = null;
    if (enablePostOrderDDP) {
        if (order.waxup_status) {
            const analytics: AnalyticsEventWithName = {
                name: 'Practice - DDP Request Dialog Opened',
                data: {
                    $groups: { order: order.id },
                    action: 'remove',
                },
            };
            ddpControl = (
                <StyledButton
                    variant={'ghost'}
                    startIcon={'PencilOutlinedIcon'}
                    onClick={() => setRemoveWaxupOpen(true)}
                    disabled={disableDDP}
                    analytics={() => analytics}
                >
                    Cancel Digital Design Preview
                </StyledButton>
            );
        } else {
            const analytics: AnalyticsEventWithName = {
                name: 'Practice - DDP Request Dialog Opened',
                data: {
                    $groups: { order: order.id },
                    action: 'add',
                },
            };
            ddpControl = (
                <StyledButton
                    variant={'ghost'}
                    startIcon={'PencilOutlinedIcon'}
                    onClick={() => setAddWaxupOpen(true)}
                    disabled={disableDDP}
                    analytics={() => analytics}
                >
                    Add Digital Design Preview
                </StyledButton>
            );
        }
    }

    return (
        <>
            <ButtonContainer>
                <OrderEditActionButton
                    hasEndIcon
                    order={order}
                    buttonLabel={'Modify RX & instructions'}
                    setEditOpen={modifyOrderActionClicked}
                />
                <UploadOrTakePhotoAction order={order} isIconOnly={screenIsMobile} />
                <AddNewScanAction
                    isIconOnly
                    order={order}
                    refetch={() => {
                        refetchOrder(order.id);
                    }}
                />
                <StyledButton
                    variant={'ghost'}
                    onClick={event => setAnchorEl(event.currentTarget)}
                    aria-describedby={id}
                    size={'large'}
                    endIcon={!screenIsMobile ? 'MoreVertIcon' : undefined}
                >
                    {!screenIsMobile ? 'Other' : <MoreVertIcon style={{ color: FlossPalette.STAR_GRASS }} />}
                </StyledButton>
            </ButtonContainer>

            <Popover
                id={id}
                data-tracker-id={OrderActionParentTrackerIdMap[OrderActionParent.MobileActionsMenu]}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ style: { padding: 12 } }}
                TransitionProps={{
                    onEnter: () => {
                        trackRenderedOrderDetailActions(order.id, OrderActionParent.MobileActionsMenu);
                    },
                }}
            >
                <DropdownContent
                    onClick={() => {
                        setAnchorEl(null);
                    }}
                >
                    {screenIsMobile && (
                        <>
                            <EditDoctorAction
                                order={order}
                                refetch={async () => {
                                    refetchOrder(order.id);
                                }}
                            />
                            <EditAddressAction
                                order={order}
                                refetch={async () => {
                                    refetchOrder(order.id);
                                }}
                            />
                        </>
                    )}
                    {ddpControl}
                    <AttachExistingScanMobileButton order={order} setOpen={setIsAttachScansOpen} />
                    <PauseOrResumeOrderMobileButton
                        order={order}
                        refetch={() => refetchOrder(order.id)}
                        setOpen={setIsPauseOrderDialogOpen}
                    />
                    <CancelOrderButton order={order} setOpen={setIsCancelOrderDialogOpen} />
                    <Divider style={{ width: `100%` }} />
                    <ExploreHelpCenterButton />
                    <ChatAction order={order} isMobile setAddOrderNotesDialogOpen={setAddOrderNotesDialogOpen} />
                </DropdownContent>
            </Popover>
            <OrderEditActionDialog
                order={order}
                InjectedPricingGuideComponent={<PricingGuide isModal={true} />}
                isProvideInstructionsFlow={editActionDialogState === 'instructions'}
                openOrder={openOrder}
                isEditOpen={editActionDialogState !== null}
                setEditOpen={() => {
                    setEditActionDialogState(null);
                }}
            />
            <PauseOrderMobileDialog
                order={order}
                setOpen={setIsPauseOrderDialogOpen}
                open={isPauseOrderDialogOpen}
                refetch={() => {
                    refetchOrder(order.id);
                }}
            />
            <CancelOrderDialog
                order={order}
                setOpen={setIsCancelOrderDialogOpen}
                open={isCancelOrderDialogOpen}
                refetch={() => {
                    refetchOrder(order.id);
                }}
            />
            <AddOrderNotesDialog
                orderId={order.id}
                open={addOrderNotesDialogOpen}
                setOpen={setAddOrderNotesDialogOpen}
                CustomButton={() => null}
                submit={sumbitAddOrderNotes}
                submitting={sumbittingAddOrderNotes}
                setAttachmentUrls={setAttachmentUrls}
                toastOpen={toastOpen}
                setToastOpen={setToastOpen}
            />
            <AddExistingScanMobileDialog order={order} open={isAttachScansOpen} setOpen={setIsAttachScansOpen} />
            <OrderDetailDDPAdditionDialog
                order={order}
                open={addWaxupOpen}
                setOpen={setAddWaxupOpen}
                onSubmit={addWaxup}
                loading={addingWaxup}
            />
            <OrderDetailDDPRemovalDialog
                order={order}
                open={removeWaxupOpen}
                setOpen={setRemoveWaxupOpen}
                onSubmit={removeWaxup}
                loading={removingWaxup}
            />
        </>
    );
};
