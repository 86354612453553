import type { NavMenuSectionProps } from './NavMenu';
import { buttonLinksToNavButtonProps, IconButtonNavMenu, NavMenuSection } from './NavMenu';
import type { LabsGqlDoctorPreferencesFragment, LabsGqlUserLoginOptionFragment } from '@orthly/graphql-operations';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { AccountGearIcon, DoctorBadgeIcon } from '@orthly/ui';
import type { PopperPlacementType } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Text, Button, Divider, Grid, Icon } from '@orthly/ui-primitives';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    badgeIcon: {
        color: FlossPalette.PRIMARY_FOREGROUND,
        width: 10,
        marginRight: 6,
    },
    menu: {
        width: 560,
        padding: '16px 0',
    },
    menuWide: {
        width: 840,
    },
}));

export interface HorizontalNavAccountSettingsLinks {
    profileMenuLinks: {
        'Account settings': string;
        Notifications: string;
        'Clinical preferences': string;
    };
    practiceMenuLinks: {
        Billing: string;
        Staff: string;
        'Practice settings': string;
        'Pricing guide': string;
    };
}

export interface HorizontalNavAccountSettingsProps {
    smallProfilePhoto: React.ReactNode;
    largeProfilePhoto: React.ReactNode;
    switchProfileAction: () => void;
    logoutText: string | React.ReactNode;
    logoutAction: () => void;
    horizontalNavLinks: HorizontalNavAccountSettingsLinks;
    backdropPaddingTop: number;
    additionalMenuSection?: Omit<NavMenuSectionProps, 'navigationAction'>;
    profile?: Pick<LabsGqlDoctorPreferencesFragment, 'roles' | 'formatted_name' | 'created_at' | 'practice_name'>;
    settingsPlacement?: PopperPlacementType;
    loginOptions: LabsGqlUserLoginOptionFragment[];
}

export const HorizontalNavAccountSettings: React.VFC<HorizontalNavAccountSettingsProps> = props => {
    const {
        profile,
        smallProfilePhoto,
        largeProfilePhoto,
        switchProfileAction,
        logoutText,
        logoutAction,
        backdropPaddingTop,
        additionalMenuSection,
        loginOptions,
        settingsPlacement,
        horizontalNavLinks,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const isDoctor = profile?.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor);

    const displayedProfileLinks = _.omitBy(horizontalNavLinks.profileMenuLinks, (_value, key) => {
        if (isDoctor) {
            return false;
        }

        return ['Notifications', 'Clinical preferences'].includes(key);
    });
    const profileMenuButtonConfig = buttonLinksToNavButtonProps(displayedProfileLinks);

    const practiceMenuButtonConfig = buttonLinksToNavButtonProps(horizontalNavLinks.practiceMenuLinks);

    const active = !![...practiceMenuButtonConfig, ...profileMenuButtonConfig]?.find(
        c => history.location.pathname.replaceAll('/', '') === c.screen?.replaceAll('/', ''),
    );

    const navigationAction = () => {
        setOpen(false);
    };

    const onSwitchProfileAction = () => {
        navigationAction();
        switchProfileAction();
    };

    const onLogoutAction = () => {
        navigationAction();
        logoutAction();
    };

    return (
        <IconButtonNavMenu
            customHeader
            title={'Account settings'}
            icon={<AccountGearIcon />}
            iconAttachment={smallProfilePhoto}
            active={active}
            open={open}
            setOpen={setOpen}
            menuPlacement={settingsPlacement}
            backdropPaddingTop={backdropPaddingTop}
        >
            <>
                <Grid
                    container
                    justifyContent={'space-between'}
                    direction={'row'}
                    style={{ borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`, padding: 16 }}
                >
                    <Grid item>
                        <Grid container direction={'row'}>
                            {largeProfilePhoto}
                            <Grid item style={{ marginLeft: 16 }}>
                                <Text variant={'h5'}>{profile?.formatted_name}</Text>
                                <Grid container alignItems={'center'} style={{ marginTop: 4 }}>
                                    <DoctorBadgeIcon className={classes.badgeIcon} />
                                    <Text variant={'caption'} color={'GRAY'}>
                                        Dandy {isDoctor ? 'doctor' : 'member'} since{' '}
                                        {moment(profile?.created_at).format('MM-DD-YYYY')}
                                    </Text>
                                </Grid>
                                <Button
                                    variant={'ghost'}
                                    onClick={onLogoutAction}
                                    style={{ height: 'unset', padding: 0 }}
                                >
                                    {logoutText}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container direction={'row'}>
                            {loginOptions.length > 1 && (
                                <Grid item>
                                    <Button
                                        variant={'ghost'}
                                        onClick={() => history.push('/login/select-location')}
                                        startIcon={'SwapHorizontalIcon'}
                                        style={{ marginLeft: 18 }}
                                    >
                                        Switch location
                                    </Button>
                                </Grid>
                            )}
                            <Button variant={'ghost'} startIcon={'SwapHorizontalIcon'} onClick={onSwitchProfileAction}>
                                Switch profile
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classNames(classes.menu, { [classes.menuWide]: !!additionalMenuSection })}>
                    <NavMenuSection
                        title={'You'}
                        Icon={props => <Icon icon={'PersonOutlineOutlined'} {...props} />}
                        menuButtons={profileMenuButtonConfig}
                        navigationAction={navigationAction}
                    />

                    <Grid item>
                        <Divider orientation={'vertical'} light />
                    </Grid>

                    <NavMenuSection
                        title={profile?.practice_name ?? ''}
                        Icon={props => <Icon icon={'PracticeIcon'} {...props} />}
                        menuButtons={practiceMenuButtonConfig}
                        navigationAction={navigationAction}
                    />

                    {additionalMenuSection && (
                        <>
                            <Grid item>
                                <Divider orientation={'vertical'} light />
                            </Grid>

                            <NavMenuSection {...additionalMenuSection} navigationAction={navigationAction} />
                        </>
                    )}
                </Grid>
            </>
        </IconButtonNavMenu>
    );
};
