import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const SendResetPasswordEmail_Mutation = graphql(`
    mutation SendResetPasswordEmail($email: String!) {
        sendResetPasswordEmail(email: $email)
    }
`);

export const useSendResetPasswordEmail = () => {
    const [submit] = useMutation(SendResetPasswordEmail_Mutation);
    const [error, setError] = React.useState<string>();
    const navigate = useNavigate();

    return {
        error,
        ...useChangeSubmissionFn<any, any>(variables => submit({ variables }), {
            disableDefaultErrorMessage: true,
            successMessage: () => ['Email sent!', {}],
            onSuccess: () => {
                setError(undefined);
                navigate('/login');
            },
            onError: e => {
                setError(e.message);
            },
        }),
    };
};
