import { useDoctorId } from '../../state/useDoctorId';
import { PracticeScreen } from '@orthly/dentin';
import { useNavigate } from 'react-router-dom-v5-compat';

export const useNextStep = () => {
    const navigate = useNavigate();
    const prefId = useDoctorId();

    return () => navigate(`${PracticeScreen.add_phone}/?prefId=${prefId}`);
};
