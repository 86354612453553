import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import { useInvoiceDetailStatus } from '../../invoicing.utils';
import { PaymentAction } from '../../payments/components/PaymentAction.graphql';
import { InvoiceDetailActionContainer } from './InvoiceDetailActionContainer';
import { LabsGqlPaymentStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { Button, FlossPalette, Text, styled, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';

const StyledContainer = styled('div')({
    display: 'flex',
});

const Layout = styled('div')(({ theme }) => ({
    background: FlossPalette.TAN,
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px 32px',
    '@media print': {
        display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '16px',
        gap: '16px',
    },
}));

const OverviewItemContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
});

const FlexGrowDiv = styled('div')({
    flexGrow: 1,
});

const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        height: '40px',
    },
}));

export const InvoiceDetailOverviewContainer: React.FC = () => {
    const { invoice, autochargeEnabled, refetchInvoice } = useInvoiceDetailContext();
    const status = useInvoiceDetailStatus(invoice, autochargeEnabled);
    const isMobile = useScreenIsMobile();

    const hasPendingPayment = invoice.payments.filter(p => p.status === LabsGqlPaymentStatus.Pending).length > 0;
    const showPaymentButton = invoice.amount_remaining > 0 && !hasPendingPayment;

    return (
        <Layout>
            <StyledContainer>
                <OverviewItemContainer>
                    <Text variant={isMobile ? 'caption' : 'body2'} color={isMobile ? 'GRAY' : 'BLACK'}>
                        Date
                    </Text>
                    <Text variant={isMobile ? 'caption' : 'body2'} medium>
                        {invoice.month_formatted}
                    </Text>
                </OverviewItemContainer>

                <OverviewItemContainer>
                    <Text variant={isMobile ? 'caption' : 'body2'} color={isMobile ? 'GRAY' : 'BLACK'}>
                        Status
                    </Text>
                    <Text variant={isMobile ? 'caption' : 'body2'} medium>
                        {status}
                    </Text>
                </OverviewItemContainer>

                <OverviewItemContainer>
                    <Text variant={isMobile ? 'caption' : 'body2'} color={isMobile ? 'GRAY' : 'BLACK'}>
                        Total
                    </Text>
                    <Text variant={isMobile ? 'caption' : 'body2'} medium>
                        {Format.currency(invoice.amount_due)}
                    </Text>
                </OverviewItemContainer>

                {!isMobile && showPaymentButton && (
                    <FlexGrowDiv>
                        <PaymentAction
                            invoiceId={invoice.id}
                            refetchInvoice={refetchInvoice}
                            CustomButton={({ onClick }) => (
                                <StyledButton variant={'primary'} onClick={onClick}>
                                    Pay
                                </StyledButton>
                            )}
                        />
                    </FlexGrowDiv>
                )}
            </StyledContainer>
            {isMobile && (
                <>
                    {showPaymentButton && (
                        <FlexGrowDiv>
                            <PaymentAction
                                invoiceId={invoice.id}
                                refetchInvoice={refetchInvoice}
                                CustomButton={({ onClick }) => (
                                    <StyledButton variant={'primary'} onClick={onClick}>
                                        Pay
                                    </StyledButton>
                                )}
                            />
                        </FlexGrowDiv>
                    )}
                    <InvoiceDetailActionContainer />
                </>
            )}
        </Layout>
    );
};
