import { PracticeSettingsTitle } from '../account/components/PracticeSettings/PracticeSettingsBaseTitle';
import { NotificationLogsTable } from '@orthly/veneer';
import React from 'react';

export const NotificationLogsRoot: React.FC = () => {
    return (
        <>
            <PracticeSettingsTitle title={'Notification Logs'} />
            <NotificationLogsTable />
        </>
    );
};
