import { InvoicesTableFilters } from './components/InvoicesTableFilters';
import {
    SELECT_ALL_ORGANIZATIONS,
    useInvoicesTableContext,
    useIsMultiLocationBillingEnabled,
} from './components/providers/InvoicesTableProvider.graphql';
import type { ContractSpendTerm, PracticeInvoice } from './invoicing.types';
import { useNextInvoicingDateDetails } from './invoicing.utils';
import { PaymentAction, paymentActionId } from './payments/components/PaymentAction.graphql';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import { Button, FlossPalette, Text, styled, useScreenIsMobile } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

const FlexContainer = styled('div')({
    display: 'flex',
});

interface TopSummaryItemProps {
    title: React.ReactNode;
    amount: React.ReactNode;
    detail?: React.ReactNode;
    Action?: React.FC;
    dataTest?: string;
}

const TopSummaryItemInfo: React.FC<TopSummaryItemProps> = ({ Action, title, amount, detail }) => (
    <>
        <FlexContainer>
            <Text variant={'body2'} style={{ fontWeight: 600 }}>
                {title}
            </Text>
        </FlexContainer>
        <Text variant={'h5'} style={{ paddingTop: 5 }}>
            {amount}
        </Text>
        {Action && <Action />}
        {detail && (
            <Text variant={'caption'} color={'GRAY'}>
                {detail}
            </Text>
        )}
    </>
);

interface StyledSummaryCardProps {
    isMobile: boolean;
}

const StyledSummaryCard = styled('div', {
    shouldForwardProp: p => p !== 'isMobile',
})<StyledSummaryCardProps>(({ isMobile }) => ({
    display: 'flex',
    flexShrink: 0,
    width: isMobile ? '230px' : 'auto',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    background: FlossPalette.TAN,
}));

const TopSummaryItem: React.FC<TopSummaryItemProps> = ({ dataTest, ...props }) => {
    const isMobile = useScreenIsMobile();

    return (
        <StyledSummaryCard data-test={dataTest} isMobile={isMobile}>
            <TopSummaryItemInfo {...props} />
        </StyledSummaryCard>
    );
};

const MinimumCommitmentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

interface MinimumCommitmentProps {
    usageAmountCents: number;
    currentSpendTerm?: ContractSpendTerm;
    isMultiMonthTerm: boolean;
}

const MinimumCommitmentMessage: React.FC<MinimumCommitmentProps> = ({
    usageAmountCents,
    currentSpendTerm,
    isMultiMonthTerm,
}) => {
    const now = React.useMemo(() => dayjs(), []);
    const { nextInvoiceGeneration } = useNextInvoicingDateDetails();
    const activeMinimumCommitmentCents = currentSpendTerm?.spend_minimum_cents ?? 0;
    const term = isMultiMonthTerm ? 'cycle' : 'month';
    const message = React.useMemo(() => {
        if (!isMultiMonthTerm && usageAmountCents > activeMinimumCommitmentCents) {
            const amountToBeInvoiced = Format.currency(Math.max(usageAmountCents));
            return `You'll be invoiced ${amountToBeInvoiced} this month`;
        }
        const commitmentText = isMultiMonthTerm ? 'Aggregate minimum spend' : 'Minimum commitment';
        return `${commitmentText}: ${Format.currency(activeMinimumCommitmentCents)}`;
    }, [usageAmountCents, activeMinimumCommitmentCents, isMultiMonthTerm]);

    if (activeMinimumCommitmentCents <= 0) {
        return null;
    }

    const remainingSpend = activeMinimumCommitmentCents - usageAmountCents;

    // Don't intimidate users by explicitly showing them a huge spend amount at the beginning of the cycle.
    const endOfCycle = !!currentSpendTerm?.effective_end_date
        ? dayjs(currentSpendTerm.effective_end_date)
        : nextInvoiceGeneration;
    const shouldShowRemainingSpendMessage = endOfCycle.diff(now, 'days') < 10;
    const remainingSpendMessage =
        remainingSpend > 0
            ? `Place ${Format.currency(remainingSpend)} in orders to avoid unmet minimum fees!`
            : `You've hit your minimum spend this ${term}.`;
    return (
        <MinimumCommitmentContainer>
            {message}
            <br />
            {shouldShowRemainingSpendMessage && remainingSpendMessage}
        </MinimumCommitmentContainer>
    );
};

// wrapping the button text since we don't know how long practice names might be
const StyledButton = styled(Button)({
    textWrap: 'wrap',
});

const SummaryPaymentItem: React.FC<{
    totalBalanceDue: number;
    unpaidInvoices: PracticeInvoice[];
}> = ({ totalBalanceDue, unpaidInvoices }) => {
    const { associatedPractices, selectedOrganizationId } = useInvoicesTableContext();
    const isMultiLocationBillingEnabled = useIsMultiLocationBillingEnabled();

    let paymentText = 'Pay invoice';
    // if there *are* multiple locations on the contract, the primary can filter by location
    // if a location is selected, indicate that they're only paying off invoices for the selected practice
    if (isMultiLocationBillingEnabled) {
        const selectedPracticeName = associatedPractices.find(p => p.id === selectedOrganizationId)?.name;
        const isNotFilteredByLocation = selectedOrganizationId === SELECT_ALL_ORGANIZATIONS || !selectedPracticeName;
        const locationText = isNotFilteredByLocation ? 'across all locations' : `for ${selectedPracticeName}`;
        const invoiceText = unpaidInvoices.length > 1 ? 'invoices' : 'invoice';
        paymentText = `Pay ${invoiceText} ${locationText}`;
    } else {
        // if the current user is not the primary practice, OR there aren't multiple locations on the contract
        // then they can only see/pay their own invoices. they'll still be able to pay multiple invoices at once,
        // we just don't need to differentiate between location names
        if (unpaidInvoices.length > 1) {
            paymentText = 'Pay all open invoices';
        }
    }

    return (
        <TopSummaryItem
            dataTest={'amount-due-summary'}
            title={'Your amount due'}
            amount={Format.currency(totalBalanceDue)}
            Action={() =>
                !!unpaidInvoices.length ? (
                    <PaymentAction
                        invoiceIds={unpaidInvoices.map(i => i.id)}
                        CustomButton={({ onClick }) => (
                            <StyledButton variant={'primary'} onClick={onClick} id={paymentActionId}>
                                {paymentText}
                            </StyledButton>
                        )}
                    />
                ) : null
            }
        />
    );
};

const SummarySpendItem: React.FC<{ isMultiMonthTerm: boolean }> = ({ isMultiMonthTerm }) => {
    const { pendingItems, currentSpendTerm, associatedPractices, selectedOrganizationId, contractAggregateSpend } =
        useInvoicesTableContext();
    let spendTitle = `Spend this ${isMultiMonthTerm ? 'cycle' : 'month'}`;
    // associatedPractices includes the primary practice, so we need to check if there are more than one
    const hasAssociatedPractices = useIsMultiLocationBillingEnabled();
    if (hasAssociatedPractices) {
        if (selectedOrganizationId !== SELECT_ALL_ORGANIZATIONS) {
            const selectedPracticeName = associatedPractices.find(p => p.id === selectedOrganizationId)?.name;
            spendTitle += ` (for ${selectedPracticeName})`;
        } else {
            spendTitle += ' (all locations)';
        }
    }

    const shouldShowMinimumCommitmentMessage =
        !hasAssociatedPractices || selectedOrganizationId === SELECT_ALL_ORGANIZATIONS;
    const spendAmount = isMultiMonthTerm ? contractAggregateSpend : pendingItems.pending_total_cents;

    return (
        <TopSummaryItem
            dataTest={'minimum-commitment-summary'}
            title={spendTitle}
            amount={Format.currency(spendAmount)}
            detail={
                shouldShowMinimumCommitmentMessage && (
                    <MinimumCommitmentMessage
                        usageAmountCents={spendAmount}
                        currentSpendTerm={currentSpendTerm}
                        isMultiMonthTerm={isMultiMonthTerm}
                    />
                )
            }
        />
    );
};

interface ContainerProps {
    isMobile: boolean;
}

const Container = styled('div', {
    shouldForwardProp: p => p !== 'isMobile',
})<ContainerProps>(({ isMobile }) => ({ padding: isMobile ? '0px' : undefined }));

const TitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '64px',
    padding: '20px 24px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
});

const SummaryContainer = styled('div', {
    shouldForwardProp: p => p !== 'isMobile',
})<ContainerProps>(({ isMobile }) => {
    if (isMobile) {
        return {
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            padding: '12px',
            width: 'fit-content',
        };
    }

    return {
        display: 'inline-grid',
        gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns for 4 cards
        gap: '16px',
        padding: '20px 24px',
    };
});

interface InvoicesTableHeaderProps {
    lastInvoiceDate?: Date;
}

export const InvoicesTableHeader: React.FC<InvoicesTableHeaderProps> = ({ lastInvoiceDate }) => {
    const { invoices, loading, pendingItems, autochargeEnabled, currentSpendTerm } = useInvoicesTableContext();
    const isMobile = useScreenIsMobile();

    const { nextInvoiceDue, invoiceDueDayOfMonth } = useNextInvoicingDateDetails();
    const ordersSince = lastInvoiceDate ? dayjs(lastInvoiceDate).format('MM/DD/YYYY') : undefined;
    const daysTillNextInvoiceDue = React.useMemo(() => {
        return nextInvoiceDue.endOf('day').diff(dayjs().utc().endOf('day'), 'days');
    }, [nextInvoiceDue]);

    const unpaidInvoices = invoices.filter(i => i.status === 'open' && i.pending_payment_amount_cents === 0);
    const totalBalanceDue = _.sumBy(unpaidInvoices, i => i.amount_due);

    const isMultiMonthTerm = !!currentSpendTerm && currentSpendTerm.spend_cycle_length > 1;

    return (
        <Container isMobile={isMobile}>
            <TitleContainer>
                <Text variant={'h5'} medium data-test={'billing-table-title'}>
                    Billing
                </Text>
                <InvoicesTableFilters />
            </TitleContainer>

            <LoadBlocker
                blocking={loading}
                ContainerProps={{
                    style: {
                        paddingBottom: '20px',
                        overflowX: 'scroll',
                    },
                }}
                overlayColor={FlossPalette.TAN}
            >
                <SummaryContainer isMobile={isMobile}>
                    <SummaryPaymentItem totalBalanceDue={totalBalanceDue} unpaidInvoices={unpaidInvoices} />
                    <TopSummaryItem
                        title={
                            autochargeEnabled
                                ? `You'll be charged in`
                                : `Next invoice ${totalBalanceDue ? 'will be charged' : 'due'} in`
                        }
                        amount={
                            daysTillNextInvoiceDue === 0
                                ? 'Today'
                                : `${Format.pluralize('day', daysTillNextInvoiceDue)}`
                        }
                        detail={`Automatically charged on the ${invoiceDueDayOfMonth}th of every month`}
                    />
                    <SummarySpendItem isMultiMonthTerm={isMultiMonthTerm} />
                    <TopSummaryItem
                        title={ordersSince ? 'Orders since last invoice' : 'Orders on next invoice'}
                        amount={pendingItems.pending_orders_count}
                        detail={ordersSince ? <>Last Invoice was sent on {ordersSince}</> : undefined}
                    />
                </SummaryContainer>
            </LoadBlocker>
        </Container>
    );
};
