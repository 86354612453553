import { useOrderDetailsContext } from '../OrderDetailTracking.context.graphql';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const OrderDetailHoldMessage: React.VFC = () => {
    const { order, holdMessage, onPause, needsAdditionalScans } = useOrderDetailsContext();

    const isOnHold = order.status === LabsGqlLabOrderStatus.OnHold;
    const isHoldInReview = order.hold_in_review;
    const holdVisibleToPractice = order.show_hold_status_to_practice;
    const isDelayed = order.practice_dates.is_delivery_delayed;
    const isBillingIssue = order.hold_details?.hold_category === 'Billing Issue';

    const showOrderDelayedWithHoldMessage = holdMessage && holdVisibleToPractice && !onPause && !isBillingIssue;
    const showOrderDelayedWithoutHoldMessage = !holdMessage && isDelayed && !onPause && !isBillingIssue;
    const showAvoidDelaysMessage =
        isOnHold && holdVisibleToPractice && !isHoldInReview && !needsAdditionalScans && !onPause;
    const showHoldInReviewMessage = isOnHold && holdVisibleToPractice && isHoldInReview && !needsAdditionalScans;
    const showBillingIssueMessage = isBillingIssue && holdVisibleToPractice && !onPause;

    if (order.status === LabsGqlLabOrderStatus.Cancelled) {
        return null;
    }

    if (showBillingIssueMessage) {
        return <span>Fabrication will resume and ETA updated upon payment resolution.</span>;
    }

    if (showOrderDelayedWithHoldMessage) {
        return (
            <span>
                <span style={{ fontFamily: 'Inter Semibold, Verdana, sans-serif' }}>Order was delayed</span>
                {holdMessage}
            </span>
        );
    }

    if (showOrderDelayedWithoutHoldMessage) {
        return <span style={{ fontFamily: 'Inter Semibold, Verdana, sans-serif' }}>Order was delayed.</span>;
    }

    if (showAvoidDelaysMessage) {
        return (
            <span style={{ color: FlossPalette.BLACK }}>Avoid delays by getting back to us as soon as possible.</span>
        );
    }

    if (showHoldInReviewMessage) {
        return <span>Your response is being reviewed by Dandy, and fabrication will proceed shortly.</span>;
    }

    if (order.can_mark_delivered) {
        return <span>Dandy is not receiving tracking updates from the shipping carrier.</span>;
    }

    return null;
};
