import { useDandyBillingAddress } from '../../../../utils/useDandyBillingAddress';
import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import type { SummaryRow } from './InvoiceDetailBody.utils';
import { isPaymentOrCreditRefundCategory, useSummaryRows } from './InvoiceDetailBody.utils';
import { InvoiceFilters } from './InvoiceFilters';
import { InvoiceTransactionsTable } from './InvoiceTransactionsTable';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker, Table } from '@orthly/ui';
import {
    Box,
    FlossPalette,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Text,
    styled,
    useScreenIsMobile,
} from '@orthly/ui-primitives';

const AddressDetails: React.FC = () => {
    const { billingContactInfoLoading, organizationName, billingEmail } = useInvoiceDetailContext();
    const dandyBillingAddress = useDandyBillingAddress();
    const isMobile = useScreenIsMobile();

    return (
        <LoadBlocker blocking={billingContactInfoLoading}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Text variant={isMobile ? 'body2' : 'body1'} sx={{ marginBottom: '8px' }} medium>
                    From
                </Text>
                <Text variant={isMobile ? 'caption' : 'body2'} color={'GRAY'}>
                    {dandyBillingAddress.name}
                    <br />
                    {dandyBillingAddress.street_one}
                    <br />
                    {dandyBillingAddress.city}, {dandyBillingAddress.state} {dandyBillingAddress.postal_code}
                    <br />
                    cxbilling@meetdandy.com
                </Text>

                {organizationName && (
                    <>
                        <Text
                            variant={isMobile ? 'body2' : 'body1'}
                            medium
                            sx={{ marginTop: isMobile ? '12px' : '24px', marginBottom: '8px' }}
                        >
                            To
                        </Text>
                        <Text variant={isMobile ? 'caption' : 'body2'} color={'GRAY'}>
                            {organizationName}
                            <br />
                            {billingEmail}
                        </Text>
                    </>
                )}
            </Box>
        </LoadBlocker>
    );
};

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        flexDirection: 'column',
    },
}));

const SummaryContainer = styled('div')(({ theme }) => ({
    width: '67%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const AddressContainer = styled('div')(({ theme }) => ({
    width: '33%',
    marginLeft: '24px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px 0',
        width: '100%',
    },
}));

const TitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const StyledTitle = styled(Text)({
    marginRight: 'auto',
});

const StyledTable = styled(Table)({
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: '4px !important',
    borderCollapse: 'separate',
});

const StyledTableHead = styled(TableHead)({
    height: '56px',
    borderTopLeftRadius: '4px !important',
    borderTopRightRadius: '4px !important',
});

const StyledTableCell = styled(TableCell)({
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});

export const TextWithIconContainer = styled('div')({
    display: 'inline-flex',
    alignItems: 'center',
});

const StyledTableHeadCell = styled(TableCell)({
    backgroundColor: FlossPalette.TAN,
    '&:first-of-type': {
        // subtract 1px to account for the border on the table
        borderTopLeftRadius: '3px !important',
    },
    '&:last-of-type': {
        // subtract 1px to account for the border on the table
        borderTopRightRadius: '3px !important',
    },
});

const StyledTableBody = styled(TableBody)({
    '& tr': {
        height: '72px',
    },
});

const SummaryTable: React.FC<{ summaryRows: SummaryRow[] }> = ({ summaryRows }) => {
    return (
        <StyledTable>
            <StyledTableHead>
                <TableRow>
                    <StyledTableHeadCell variant={'head'}>Type</StyledTableHeadCell>
                    <StyledTableHeadCell variant={'head'}>Quantity</StyledTableHeadCell>
                    <StyledTableHeadCell variant={'head'}>Amount</StyledTableHeadCell>
                </TableRow>
            </StyledTableHead>
            <StyledTableBody>
                {summaryRows.map(row => {
                    const rowTextColor = isPaymentOrCreditRefundCategory(row) ? 'GREEN' : 'BLACK';
                    return (
                        <TableRow key={row.id}>
                            <StyledTableCell>
                                <TextWithIconContainer>
                                    <Text variant={'body2'} color={rowTextColor} noWrap>
                                        {row.id}
                                    </Text>
                                    {row.tooltip}
                                </TextWithIconContainer>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant={'body2'} color={rowTextColor}>
                                    {row.quantity ?? ''}
                                </Text>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant={'body2'} color={rowTextColor}>
                                    {Format.currency(row.amountCents)}
                                </Text>
                            </StyledTableCell>
                        </TableRow>
                    );
                })}
            </StyledTableBody>
        </StyledTable>
    );
};

export const InvoiceDetailBody: React.FC = () => {
    const { invoice, rows, rowsLoading } = useInvoiceDetailContext();
    const summaryRows = useSummaryRows(invoice, rows);
    const isMobile = useScreenIsMobile();

    return (
        <LoadBlocker blocking={rowsLoading}>
            <Box sx={{ width: '100%' }}>
                <Box component={'section'} sx={{ padding: isMobile ? '24px' : '32px' }}>
                    <TitleContainer>
                        <StyledTitle variant={isMobile ? 'h6' : 'h5'}>Invoice #{invoice.invoice_number}</StyledTitle>
                        <InvoiceFilters />
                    </TitleContainer>

                    <Container>
                        {!isMobile && (
                            <SummaryContainer>
                                <SummaryTable summaryRows={summaryRows} />
                            </SummaryContainer>
                        )}
                        <AddressContainer>
                            <AddressDetails />
                        </AddressContainer>
                        {isMobile && (
                            <SummaryContainer>
                                <SummaryTable summaryRows={summaryRows} />
                            </SummaryContainer>
                        )}
                    </Container>
                </Box>
                <InvoiceTransactionsTable />
            </Box>
        </LoadBlocker>
    );
};
