import { useFullPageFormStyles } from '../../../../layouts/FullPageForm.styles';
import { LoginRoutes } from '../../../logged-out/LoginPaths';
import { MobileAuthSelectOrganization } from '../../../mobile-auth/screens/MobileAuthSelectOrganization';
import { Login } from './Login';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import { useScreenIsMobile, Grid } from '@orthly/ui-primitives';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

export const LoginWrapper: React.FC<{
    originalLocation: LocationDescriptorObject;
}> = ({ originalLocation }) => {
    const { loginOptions } = useStaffMemberLoginProps();
    const isMobile = useScreenIsMobile();
    const classes = useFullPageFormStyles();

    return (
        <Switch>
            {loginOptions && (
                <CompatRoute path={LoginRoutes.select_location}>
                    <Grid item container={isMobile} lg={7} md={9} sm={12}>
                        <Grid className={classes.leftContentWrapper}>
                            <MobileAuthSelectOrganization />
                        </Grid>
                    </Grid>
                </CompatRoute>
            )}
            <CompatRoute exact path={'*'}>
                <Login originalLocation={originalLocation} />
            </CompatRoute>
        </Switch>
    );
};
