import { getFlagVariantValueChecker } from '../../../../utils/utils';
import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import DandyRecommended from '@orthly/dentin/assets/images/hd-skus/Dandy_Recommended_Upsell.png';

export const WellnessScanInsuranceConfig: InboxUpsellItemConfigProps = {
    assetName: '202409 Wellness Scan Insurance Reimbursement MVP',
    title: 'Educate Patients, Get Reimbursed',
    subtitle: 'Transform patient education and unlock potential reimbursement with Diagnostic Scans.',
    ctaText: 'Learn more',
    destination: {
        url: 'https://help.meetdandy.com/hc/en-us/articles/29524290679437-A-Comprehensive-Guide-to-Dandy-s-Wellness-Scans',
    },
    imgSrc: DandyRecommended,
    imgStyle: { maxHeight: 80 },
    skuType: 'wellness-scan-insurance',
    filterCriteria: null,
    featureFlagKey: 'upsellWellnessScan',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_a'),
};
