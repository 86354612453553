import { ScanbodyRequestActions } from './ScanbodyRequest.actions';
import { ScanbodyStage } from './ScanbodyRequest.types';
import type { LabsGqlCreateScanbodyRequestCommand } from '@orthly/graphql-schema';
import { matchPath } from 'react-router-dom';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'redux-first-history';
import type { RouterState } from 'redux-first-history';

export interface ScanbodyRequestState {
    stage: ScanbodyStage;
    request: ScanbodyRequestPartial;
    declinedSurgicalReport: boolean;
}

type ScanbodyRequest = Omit<LabsGqlCreateScanbodyRequestCommand, 'practice_id'>;
export type ScanbodyRequestPartial = { [P in keyof ScanbodyRequest]: ScanbodyRequest[P] | null };

const EMPTY_REQUEST: ScanbodyRequestPartial = {
    manufacturer: null,
    system: null,
    connection: null,
    appointment_date: null,
    address: null,
    surgical_report_file: null,
};

const initialState: ScanbodyRequestState = {
    stage: ScanbodyStage.OVERVIEW,
    request: { ...EMPTY_REQUEST },
    declinedSurgicalReport: false,
};

export const scanbodyRequestReducer = handleActions<ScanbodyRequestState, any>(
    {
        [LOCATION_CHANGE]: (state: ScanbodyRequestState, action: Action<RouterState>) =>
            !action.payload.location || matchPath(action.payload.location.pathname, `/scanbodies`)
                ? state
                : initialState,
        ...ScanbodyRequestActions.SET_REQUEST.reducer<ScanbodyRequestState>((state, action) => ({
            ...state,
            request: action.payload,
        })),
        ...ScanbodyRequestActions.SET_REQUEST_FIELD.reducer<ScanbodyRequestState>((state, action) => ({
            ...state,
            request: { ...state.request, [action.payload.property]: action.payload.value },
        })),
        ...ScanbodyRequestActions.RESET_REQUEST.reducer<ScanbodyRequestState>(() => initialState),
        ...ScanbodyRequestActions.SET_STAGE.reducer<ScanbodyRequestState>((state, action) => ({
            ...state,
            stage: action.payload,
        })),
        ...ScanbodyRequestActions.SET_DECLINED_SURGICAL_REPORT.reducer<ScanbodyRequestState>((state, action) => ({
            ...state,
            declinedSurgicalReport: action.payload,
        })),
    },
    initialState,
);
