import { IconButtonNavMenu } from './NavMenu';
import { SidebarChatIcon } from '@orthly/ui';
import React from 'react';

export interface HorizontalNavChatProps {
    setChatOpen: (value: boolean) => void;
    chatOpen: boolean;
    unreadCount: number;
    backdropPaddingTop: number;
    disableBackdrop?: boolean;
}

export const HorizontalNavChat: React.VFC<HorizontalNavChatProps> = props => {
    const { chatOpen, unreadCount, setChatOpen, backdropPaddingTop, disableBackdrop } = props;

    return (
        <IconButtonNavMenu
            disableBackdrop={disableBackdrop}
            customHeader
            title={'Chat with Dandy'}
            icon={<SidebarChatIcon />}
            open={chatOpen}
            setOpen={setChatOpen}
            badgeCount={unreadCount}
            clickAway={false}
            backdropPaddingTop={backdropPaddingTop}
        />
    );
};
