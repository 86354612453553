import { useCSTAction, useOnCSTOpen } from '../../chat/cst-state/cst.actions';
import { AddOrderNotes } from '../components/AddOrderNotes';
import { refetchTimelineItemsAtom } from '../components/atoms/Timeline.atoms';
import { OrderAction, OrderActionTrackerIdMap } from './OrderActionsUtils';
import { isCsAvailable } from '@orthly/date-fns-business-days';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrderRefetch } from '@orthly/graphql-react';
import { MiniWindowVariant } from '@orthly/ui';
import { Button, Divider, FlossPalette, Text, styled } from '@orthly/ui-primitives';
import { useAtom } from 'jotai';
import React from 'react';

interface ChatActionProps {
    isMobile?: boolean;
    order: LabsGqlOrder;
    setAddOrderNotesDialogOpen?: (addOrderNotesDialogOpen: boolean) => void;
}

const OnlineDot = styled('div')({
    width: '4px',
    height: '4px',
    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
    borderRadius: '50%',
});

const OfflineDot = styled('div')({
    width: '4px',
    height: '4px',
    backgroundColor: FlossPalette.ATTENTION_FOREGROUND,
    borderRadius: '50%',
});

const SubtextWrapper = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'flex',
    flexDirection: 'column',
    gridGap: '4px',
    padding: isMobile ? '0px 12px' : '0px 12px 16px',
}));

const OnlineStatusTextWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gridGap: '8px',
});

export const ChatAction: React.FC<ChatActionProps> = ({ order, isMobile, setAddOrderNotesDialogOpen }) => {
    const setCSTVariant = useCSTAction('SET_WINDOW_VARIANT');
    const openCSTWithoutGroupAction = useOnCSTOpen(() => {
        setCSTVariant(MiniWindowVariant.Maximized, 'move');
    });
    const refetchOrder = useOrderRefetch();
    const [refetchTimelineItems] = useAtom(refetchTimelineItemsAtom);

    const isWithinBusinessHours = isCsAvailable(new Date());
    const refetchAll = () => {
        refetchOrder(order.id);
        refetchTimelineItems?.();
    };

    return (
        <>
            <Button
                disabled={!isWithinBusinessHours}
                leftAlign
                variant={'ghost'}
                startIcon={'SidebarChatIcon'}
                onClick={openCSTWithoutGroupAction}
            >
                Chat with us live
            </Button>
            <SubtextWrapper isMobile={Boolean(isMobile)}>
                <OnlineStatusTextWrapper>
                    {isWithinBusinessHours ? <OnlineDot /> : <OfflineDot />}
                    <Text color={isWithinBusinessHours ? 'BLACK' : 'GRAY'} bold variant={'caption'}>
                        {isWithinBusinessHours
                            ? 'Our support team is online!'
                            : 'Our support team is currently offline'}
                    </Text>
                </OnlineStatusTextWrapper>
                {!isWithinBusinessHours && !isMobile && (
                    <Text color={'GRAY'} variant={'caption'}>
                        {'We’re available Mon-Fri 8-9pm & Sat 9-4pm ET'}
                    </Text>
                )}
            </SubtextWrapper>
            {!isWithinBusinessHours && order.can_leave_notes && (
                <>
                    {isMobile ? (
                        <>
                            <Button
                                data-tracker-id={OrderActionTrackerIdMap[OrderAction.LeaveNote]}
                                variant={'ghost'}
                                onClick={() => setAddOrderNotesDialogOpen?.(true)}
                                startIcon={'ReprioritizeIcon'}
                            >
                                Leave a message for our team
                            </Button>
                        </>
                    ) : (
                        <>
                            <Divider />
                            <AddOrderNotes refetchMessages={refetchAll} orderId={order.id} />
                        </>
                    )}
                </>
            )}
        </>
    );
};
