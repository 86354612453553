import { usePracticeBannerData } from '../../../PracticeBannerProvider.graphql';
import { ChatWindowBase } from './ChatWindowBase';
import { useScreenIsMobile, styled } from '@orthly/ui-primitives';
import React from 'react';

const HorizontalNavCSTAnchor = styled('div')(({ theme }) => ({
    position: 'fixed',
    right: 5,
    zIndex: 1500,
    height: 688,
    '& > *': {
        position: 'absolute',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        height: '100vh',
    },
}));

export const ChatWindowRoot: React.VFC = () => {
    const isMobile = useScreenIsMobile();
    const { contentPaddingTop } = usePracticeBannerData();

    if (isMobile) {
        return null;
    }

    return (
        <HorizontalNavCSTAnchor style={{ top: contentPaddingTop }}>
            <ChatWindowBase />
        </HorizontalNavCSTAnchor>
    );
};
