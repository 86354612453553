import { IconButtonNavMenu } from './NavMenu';
import { SidebarChatIcon } from '@orthly/ui';
import React from 'react';

export interface HorizontalNavCSTProps {
    setCSTOpen: (value: boolean) => void;
    cstOpen: boolean;
    unreadCount: number;
    backdropPaddingTop: number;
}

export const HorizontalNavCST: React.VFC<HorizontalNavCSTProps> = props => {
    const { cstOpen, unreadCount, setCSTOpen, backdropPaddingTop } = props;

    return (
        <IconButtonNavMenu
            customHeader
            title={'Contact Dandy Support'}
            icon={<SidebarChatIcon />}
            iconAttachment={'Chat with us'}
            open={cstOpen}
            setOpen={setCSTOpen}
            badgeCount={unreadCount}
            clickAway={false}
            backdropPaddingTop={backdropPaddingTop}
            critical
        />
    );
};
