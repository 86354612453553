import type { InvoiceUrlArgs } from '@orthly/dentin';
import { DandyNotificationsPresenter } from '@orthly/dentin';
import { useMarkEventReadMutation, useNotificationItemsQuery } from '@orthly/graphql-react';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface DandyNotificationsProps {
    doctorMatches: (cmp: string) => boolean;
    navigationAction: (path: string) => void;
    backdropPaddingTop?: number;
}

export const DandyNotifications: React.FC<DandyNotificationsProps> = props => {
    const isMobile = useScreenIsMobile();
    const {
        data: notificationData,
        refetch: inboxRefetch,
        loading,
    } = useNotificationItemsQuery({ fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache' });
    const [markAsRead] = useMarkEventReadMutation();

    const history = useHistory();
    const pathname = history.location.pathname;

    React.useEffect(() => {
        void inboxRefetch();
    }, [pathname, inboxRefetch]);

    return (
        <DandyNotificationsPresenter
            isMobile={isMobile}
            loading={loading}
            notificationData={notificationData}
            backdropPaddingTop={props.backdropPaddingTop}
            inboxRefetch={async () => {
                await inboxRefetch();
            }}
            doctorMatches={props.doctorMatches}
            notificationActionOptions={{
                markAsRead: async eventIds => {
                    await markAsRead({ variables: { eventIds: [eventIds] } });
                },
                getInvoiceUrl: ({ invoiceId }: InvoiceUrlArgs) => {
                    return `/invoices/${invoiceId}`;
                },
                navigationAction: props.navigationAction,
                getOrderUrl: (orderId: string) => `/lab/${orderId}`,
            }}
        />
    );
};
