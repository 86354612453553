import { PracticeBannerProvider } from './PracticeBannerProvider.graphql';
import { PracticePortalRouter } from './PracticePortalRouter.graphql';
import { PracticeTopBanner } from './PracticeTopBanner';
import { LogoutButton } from './components/LogoutButton';
import { PracticeTopNav } from './components/PracticeTopNav';
import { ZendeskChatUnavailablePanel } from './components/ZendeskChatUnavailablePanel';
import { ChatWindowRoot } from './screens/chat/ChatWindow/ChatWindowRoot';
import { useChatAction } from './screens/chat/chat-state/chat.actions';
import { useChatSelector } from './screens/chat/chat-state/chat.selectors';
import { useZendeskChat } from './screens/chat/useZendeskChat';
import { PracticeInboxProvider } from './screens/inbox/state/PracticeInboxProvider.graphql';
import { LabsContextProvider } from './screens/labs/LabsContext';
import { CurrentPartnerCtxProvider } from './utils/CurrentPartnerContext';
import { getPartnerId } from './utils/authorization';
import { SessionGuard, SessionGuardUtils, useSession } from '@orthly/session-client';
import { ErrorPage } from '@orthly/ui';
import { stylesFactory, useScreenIsMd, Grid } from '@orthly/ui-primitives';
import { OutageBanner } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    headerWrapper: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
    },
}));

const PartnerAppRootBase: React.VFC = () => {
    const session = useSession();
    const screenIsMedium = useScreenIsMd();
    const outOfOfficeOpen = useChatSelector(({ outOfOfficeOpen }) => outOfOfficeOpen);
    const closeOutOfOffice = useChatAction('CLOSE_OUT_OF_OFFICE');

    useZendeskChat({ autoOpen: false });

    return (
        <SessionGuard
            test={SessionGuardUtils.hasOrgType(['lab', 'practice'])}
            fallback={
                <ErrorPage
                    message={'Unauthorized'}
                    iconName={'PanTool'}
                    text={"You don't have permission to see this."}
                >
                    <LogoutButton />
                </ErrorPage>
            }
        >
            <CurrentPartnerCtxProvider partnerId={getPartnerId(session)}>
                <LabsContextProvider>
                    {/* PracticeInboxProvider is here to ensure sidebar item count is up to date on all screens */}
                    <PracticeInboxProvider>
                        <PracticePortalRouter />
                        <ZendeskChatUnavailablePanel open={outOfOfficeOpen} onClose={closeOutOfOffice} />
                    </PracticeInboxProvider>
                </LabsContextProvider>
                {/* Take out below OutageBanner when we init PracticeOutageBanner on mobile */}
                {screenIsMedium && <OutageBanner audience={'practice'} />}
                <ChatWindowRoot />
            </CurrentPartnerCtxProvider>
        </SessionGuard>
    );
};

export const PartnerAppRoot: React.VFC = () => {
    const classes = useStyles();

    return (
        <Grid container id={'partner-container'}>
            <PracticeBannerProvider>
                <Grid container className={classes.headerWrapper}>
                    <PracticeTopBanner />
                    <PracticeTopNav />
                </Grid>
                <PartnerAppRootBase />
            </PracticeBannerProvider>
        </Grid>
    );
};
