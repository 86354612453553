import { StaffProfilePhoto } from '../../../components/StaffProfilePhoto';
import { usePracticeLogout } from '../../../redux/auth';
import { useSelectStaff } from '../hooks/useSelectStaff';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import type { IconName } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Grid, Button, Text, Avatar, Icon } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    button: {
        margin: 0,
        padding: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    employeeStack: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    staffName: {
        maxWidth: '100%',
    },
    trimAndFlowName: {
        maxWidth: '100%',
        scrollbarWidth: 'none',
        whitespace: 'nowrap',
        overflow: 'auto',
        textOverflow: 'ellipsis',
        '&:hover': {
            overflow: 'visible',
            animation: '$scrollText 8s linear infinite',
        },
    },
    '@keyframes scrollText': {
        '0%': {
            transform: 'translateX(0)',
        },
        '100%': {
            transform: 'translateX(-200%)',
        },
    },
    staffInfo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        overflow: 'hidden',
    },
}));

export const PHOTO_STYLE: React.CSSProperties = {
    width: 136,
    height: 136,
    borderRadius: 16,
    fontSize: 32,
};

const ProfileIcon: React.VFC<{ requireEmployeeAuth: boolean; userCannotLogin: boolean }> = props => {
    const { requireEmployeeAuth, userCannotLogin } = props;

    const config = React.useMemo(() => {
        if (userCannotLogin) {
            return { backgroundColor: FlossPalette.BURGUNDY, icon: 'ToolOutlinedIcon' as IconName };
        } else if (requireEmployeeAuth) {
            return { backgroundColor: FlossPalette.BLACK, icon: 'LockIcon' as IconName };
        }
    }, [userCannotLogin, requireEmployeeAuth]);

    if (!requireEmployeeAuth && !userCannotLogin) {
        return <></>;
    }

    return (
        <div style={{ position: 'absolute', top: 8, right: 8 }}>
            <Avatar style={{ backgroundColor: config?.backgroundColor, height: 30, width: 30 }}>
                {config?.icon && <Icon icon={config?.icon} style={{ color: 'white', height: 20, width: 20 }} />}
            </Avatar>
        </div>
    );
};

export const StaffButton: React.VFC<{
    employee: LabsGqlDoctorPreferencesFragment;
}> = props => {
    const { employee } = props;
    const { value: enableForceUserCredentials = false } = useFeatureFlag('enableForceUserCredentials');
    const classes = useStyles();
    const {
        skipEmployeeAuth,
        requireEmployeeAuth,
        userCannotLogin,
        selectEmployeeAndSession,
        selectEmployeeAndSessionRedirect,
    } = useSelectStaff(employee);
    const logOut = usePracticeLogout();
    const onClickStaff = async () => {
        if (skipEmployeeAuth) {
            selectEmployeeAndSession(employee);
        } else if (userCannotLogin) {
            if (enableForceUserCredentials) {
                selectEmployeeAndSessionRedirect(employee);
            } else {
                selectEmployeeAndSession(employee);
            }
        } else if (requireEmployeeAuth) {
            await logOut();
        } else {
            selectEmployeeAndSession(employee);
        }
    };

    const userCannotLoginFF = enableForceUserCredentials && userCannotLogin;

    const containerRef = React.useRef(null);
    const nameRef = React.useRef(null);

    const isOverflowing = (parentRef: HTMLDivElement | null, childRef: HTMLDivElement | null) => {
        if (parentRef && childRef) {
            return childRef.scrollWidth > parentRef.clientWidth;
        }
        return false;
    };

    return (
        <Button
            variant={'text'}
            onClick={onClickStaff}
            className={classes.button}
            data-test={'select-staff-button'}
            aria-label={employee.formatted_name}
        >
            {!skipEmployeeAuth && (
                <ProfileIcon requireEmployeeAuth={Boolean(requireEmployeeAuth)} userCannotLogin={userCannotLoginFF} />
            )}
            <Grid className={classes.employeeStack}>
                <StaffProfilePhoto staff={employee} style={PHOTO_STYLE} />
                <Grid container justifyContent={'space-between'}>
                    <Grid item className={classes.staffInfo} ref={containerRef}>
                        <Text
                            variant={'body1'}
                            className={
                                isOverflowing(containerRef.current, nameRef.current)
                                    ? classes.trimAndFlowName
                                    : classes.staffName
                            }
                            sx={{ marginTop: '8px' }}
                            ref={nameRef}
                        >
                            {employee.formatted_name}
                        </Text>
                        <Text variant={'body2'} style={{ fontSize: 12 }} color={'DARK_GRAY'}>
                            {employee.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor) ? 'Doctor' : 'Staff'}
                        </Text>
                    </Grid>
                    {userCannotLoginFF && (
                        <Text variant={'body2'} style={{ fontSize: 12 }} color={'BURGUNDY'}>
                            Set login credentials
                        </Text>
                    )}
                </Grid>
            </Grid>
        </Button>
    );
};
