import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import { PrintableInvoice } from '../PrintableInvoice';
import { PracticeScreen } from '@orthly/dentin';
import { downloadAsCsv } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { Button, styled, useScreenIsMobile } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

const StyledContainer = styled('div')(({ theme }) => ({
    padding: '20px 32px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media print': {
        display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
        padding: 0,
    },
}));

const DownloadContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'space-between',
    },
}));

const ExportCsvButton = styled(Button)(({ theme }) => ({
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        fontSize: '12px',
        height: '30px',
        padding: '0 !important',
    },
}));

const DownloadPdfButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        height: '30px',
        padding: '0 !important',
    },
}));

export const InvoiceDetailActionContainer: React.FC = () => {
    const { rows, doctorNameFilter, invoice, printableSlipIsOpen, openPrintableSlip } = useInvoiceDetailContext();
    const history = useHistory();
    const isMobile = useScreenIsMobile();

    const headerOnBack = () => {
        history.push(`/${PracticeScreen.billing}`);
    };

    const onClickDownload = () => {
        const downloadRows = rows.map(r => ({
            transaction_id: r.id,
            amount: Format.currency(r.amount_cents),
            category: r.category,
            effective_date: dayjs(r.effective_date).format('MM/DD/YYYY'),
            details: r.description,
            doctor: r.doctor_name,
        }));
        downloadAsCsv(downloadRows, `Dandy Statement #${invoice.invoice_number}`, { eol: '\n' });
    };

    const onPrint = () => {
        const dateTitle = dayjs(invoice.created_at).format('MMM-YYYY');
        const filterTitle = doctorNameFilter ? ` - ${doctorNameFilter}` : '';

        document.title = `Dandy Statement #${invoice.invoice_number}${filterTitle} - ${dateTitle}`;
        openPrintableSlip();
    };

    return (
        <StyledContainer>
            {!isMobile && (
                <Button onClick={headerOnBack} variant={'ghost'} startIcon={'ChevronLeft'}>
                    Back to all invoices
                </Button>
            )}
            <DownloadContainer>
                <ExportCsvButton variant={'ghost'} onClick={onClickDownload} endIcon={'DownloadIcon'}>
                    Export (CSV)
                </ExportCsvButton>
                <DownloadPdfButton variant={'ghost'} onClick={onPrint} endIcon={'PrintIcon'}>
                    Download PDF
                </DownloadPdfButton>
            </DownloadContainer>
            {printableSlipIsOpen && <PrintableInvoice />}
        </StyledContainer>
    );
};
