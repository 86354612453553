import { useSavedSearchData, useSearchAnalyticsAction } from './DandySearch.hooks';
import { DandySearchPresenter, PracticeScreen } from '@orthly/dentin';
import { useCreateSavedOrderSearchMutation, useOrderSearchResults } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useScreenIsMd } from '@orthly/ui-primitives';
import React from 'react';

// TODO: Make a storybook story, since this flakes in cypress
export const DandySearch: React.VFC<{ backdropPaddingTop: number }> = ({ backdropPaddingTop }) => {
    const session = useSession();
    const screenIsMedium = useScreenIsMd();
    const [submitMtn] = useCreateSavedOrderSearchMutation();

    const { savedSearchData, refetchSavedSearches } = useSavedSearchData();
    const onSearchAnalyticsAction = useSearchAnalyticsAction();

    return (
        <DandySearchPresenter
            screenIsMedium={screenIsMedium}
            getOrderUrl={(orderId: string | number) => `/${PracticeScreen.orders}/${orderId}`}
            sessionId={session?.id}
            createSavedOrderSearchMutation={async data => {
                await submitMtn(data);
            }}
            useOrderSearchResults={useOrderSearchResults}
            savedSearchData={savedSearchData}
            refetchSavedSearches={refetchSavedSearches}
            onSearchAnalyticsAction={onSearchAnalyticsAction}
            backdropPaddingTop={backdropPaddingTop}
        />
    );
};
