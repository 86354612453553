import { PracticeProfilePhoto } from '../../../components/PracticeProfilePhoto';
import { getPartOfDay } from '../../../utils/utils';
import { useSelectStaffAction } from '../../select-staff/state/select-staff.actions';
import type { LabsGqlUserLoginOptionFragment } from '@orthly/graphql-operations';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import { stylesFactory, useScreenIsMd, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const useStyles = stylesFactory(theme => ({
    button: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 136px)',
    },
    practiceInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        maxWidth: '100%',
    },
    signoutButton: {
        position: 'absolute',
        right: 32,
        top: 32,
        [theme.breakpoints.down('lg')]: {
            position: 'unset',
            marginTop: 'auto',
        },
    },
    practiceName: {
        marginTop: 8,
        textAlign: 'left',
        whiteSpace: 'normal',
    },
}));

const PracticeButton: React.VFC<{
    selected: boolean;
    practice: LabsGqlUserLoginOptionFragment;
    onClick: () => void;
}> = props => {
    const classes = useStyles();
    const { practice, onClick, selected } = props;
    return (
        <Button variant={'text'} onClick={onClick} className={classes.button}>
            <Grid style={{ display: 'block' }}>
                <PracticeProfilePhoto selected={selected} />
                <Grid container justifyContent={'space-between'}>
                    <Grid item className={classes.practiceInfo}>
                        <Text variant={'body1'} className={classes.practiceName}>
                            {practice.organization_name}
                        </Text>
                        <Text variant={'body2'} style={{ fontSize: 12 }} color={'DARK_GRAY'}>
                            {practice.organization_type}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </Button>
    );
};

export const MobileAuthSelectOrganization = (): JSX.Element | null => {
    const classes = useStyles();
    const { onSelectToken, loginOptions } = useStaffMemberLoginProps();
    const isMobileOrTablet = useScreenIsMd();
    const navigate = useNavigate();
    const session = useSession();
    const selectedName = session?.organization_name;
    const logoutStaff = useSelectStaffAction('LOGOUT_STAFF');

    const handleSelectLocation = (option: LabsGqlUserLoginOptionFragment) => {
        onSelectToken(option.access_token, option.id);
        /*
            push to location_selected route and let
            MobileAuthLoginRoot handle redirect (async)
        */
        logoutStaff();
        navigate('/inbox');
    };

    if (!loginOptions) {
        return null;
    }

    return (
        <Grid>
            <Text variant={isMobileOrTablet ? 'h5' : 'h4'} color={'GRAY'}>
                Good {getPartOfDay()}!
            </Text>
            <Text variant={isMobileOrTablet ? 'h4' : 'h2'}>Select a practice to continue</Text>
            <Grid container style={{ marginTop: 32, gap: 32 }} className={classes.grid}>
                {loginOptions.map((option, idx) => (
                    <PracticeButton
                        selected={selectedName === option.organization_name}
                        key={idx}
                        practice={option}
                        onClick={() => handleSelectLocation(option)}
                    />
                ))}
            </Grid>
        </Grid>
    );
};
