import type {
    BasicInvoice,
    ContractSpendTerm,
    InvoiceGeneratedFields,
    InvoicePayment,
    InvoiceTableRow,
    PendingInvoiceItem,
    PracticeInvoice,
    PracticeInvoiceDetail,
    SpendTermData,
} from './invoicing.types';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlLabPaymentMethodsQuery, LabsGqlPaymentMethodFragment } from '@orthly/graphql-operations';
import { useLabPaymentMethodsQuery } from '@orthly/graphql-react';
import type { LabsGqlInvoiceDto } from '@orthly/graphql-schema';
import { LabsGqlPaymentStatus, LabsGqlStripeInvoiceStatus, LabsGqlInvoiceItemCategory } from '@orthly/graphql-schema';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { orderBy, sortBy, startCase, sumBy, omit } from 'lodash';
import React from 'react';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export interface NextInvoicingDateDetails {
    invoiceGenerationDayOfMonth: number;
    invoiceDueDayOfMonth: number;
    nextInvoiceGeneration: dayjs.Dayjs;
    nextInvoiceDue: dayjs.Dayjs;
}

export const previewUsageInvoiceId = 'preview-usage';

export const getTargetPendingInvoiceCreationDate = () => dayjs().utc().add(1, 'month').startOf('month');

export function daysUntilInvoiceDue(invoice: PracticeInvoice | PracticeInvoiceDetail): number {
    const dueDate = dayjs(invoice.due_date);
    return dueDate.endOf('day').diff(dayjs().endOf('day'), 'days');
}

export function getLinkToInvoice(invoice: PracticeInvoice): string {
    return `/${PracticeScreen.billing}/${invoice.id}`;
}

export enum InvoiceItemCategoryBuckets {
    Orders = 'Orders',
    UnmetMinimumCharge = 'Unmet Minimum Charge',
    OtherCharges = 'Other Charges',
    CreditsAndRefunds = 'Credits and Refunds',
    Prepayment = 'Prepayment',
    CreditCardProcessingFee = 'Processing Fee (2.9%)',
}

export function categoryFromCategoryEnum(category: LabsGqlInvoiceItemCategory) {
    switch (category) {
        case LabsGqlInvoiceItemCategory.OrderPlaced:
            return InvoiceItemCategoryBuckets.Orders;
        case LabsGqlInvoiceItemCategory.UnusedMinimumCharge:
            return InvoiceItemCategoryBuckets.UnmetMinimumCharge;
        case LabsGqlInvoiceItemCategory.OtherCharge:
            return InvoiceItemCategoryBuckets.OtherCharges;
        case LabsGqlInvoiceItemCategory.OrderRefund:
        case LabsGqlInvoiceItemCategory.OtherCredit:
            return InvoiceItemCategoryBuckets.CreditsAndRefunds;
        case LabsGqlInvoiceItemCategory.PrepaymentUsed:
        case LabsGqlInvoiceItemCategory.PrepaymentCharged:
            return InvoiceItemCategoryBuckets.Prepayment;
        case LabsGqlInvoiceItemCategory.CreditCardProcessingFee:
            return InvoiceItemCategoryBuckets.CreditCardProcessingFee;
    }
}

function baseDescriptionForPayment(payment: InvoicePayment, sources: LabsGqlPaymentMethodFragment[]) {
    const dateFormatted = dayjs(payment.created_at).format('M/D');
    const baseDescription = `Payment ${dateFormatted}`;
    const source = !payment.stripe_payment_source_id
        ? undefined
        : sources.find(s => s.id === payment.stripe_payment_source_id);
    const sourceDescription = !source ? `` : `${source.brand} - ${source.last4}`;
    const formattedDescription = [baseDescription, sourceDescription ? `\n(${sourceDescription})` : ''].join('');
    return { baseDescription, sourceDescription, formattedDescription };
}

export function useDescriptionForPaymentCallback() {
    const paymentMethodsQuery = useLabPaymentMethodsQuery();
    return React.useCallback(
        (payment: InvoicePayment) => {
            const sources: LabsGqlPaymentMethodFragment[] = paymentMethodsQuery.data?.getLabsPaymentMethods ?? [];
            const { formattedDescription } = baseDescriptionForPayment(payment, sources);
            return formattedDescription;
        },
        [paymentMethodsQuery.data],
    );
}

function getInvoiceMonthFormatted<T extends BasicInvoice>(invoice: T) {
    // note: this must be done in "UTC-mode" because invoice.period_start is UTC.
    // otherwise, since period_start will be midnight on the first of the month,
    // if the browser is running East of GMT, invoices will display under the previous month,
    // e.g. `07-01-2020` will display under `June 2020`.
    return dayjs.utc(invoice.period_start).format('MMMM YYYY');
}

function isInvoiceSummary(invoice: PracticeInvoice | PracticeInvoiceDetail): invoice is PracticeInvoice {
    return invoice.hasOwnProperty('pending_payment_amount_cents');
}

export function getLabOrderCount(invoice: PracticeInvoice | undefined, allUnpaidInvoices: PracticeInvoice[]) {
    return invoice ? invoice.items_order_count : sumBy(allUnpaidInvoices, i => i.items_order_count);
}

export function convertToPracticeInvoices<T extends BasicInvoice>(invoicesRaw: T[]): InvoiceGeneratedFields<T>[] {
    // ordered by date created, ASC
    const byCreationAsc = orderBy(invoicesRaw, i => dayjs(i.created_at).valueOf());
    const invoices = byCreationAsc.map<InvoiceGeneratedFields<T>>((invoice, idx) => ({
        ...invoice,
        idx,
        month_formatted: getInvoiceMonthFormatted<T>(invoice),
    }));
    // ordered by date created, DESC
    return orderBy(invoices, i => dayjs(i.created_at).valueOf(), 'desc');
}

export function convertToPracticeInvoiceDetail(
    invoiceDetailRaw?: LabsGqlInvoiceDto,
): PracticeInvoiceDetail | undefined {
    if (!invoiceDetailRaw) {
        return;
    }
    return {
        ...invoiceDetailRaw,
        month_formatted: getInvoiceMonthFormatted(invoiceDetailRaw),
    };
}

const usageInvoiceEmptyFields = {
    card_decline_email_sent: false,
    due_date: '',
    organization_id: '',
    payments: [],
    receipt_email_sent: false,
    summary_email_sent: false,
    updated_at: new Date().toISOString(),
};

function buildUsageInvoice(pendingInvoiceItems: PendingInvoiceItem[] | undefined): PracticeInvoice {
    const targetDate = getTargetPendingInvoiceCreationDate();
    const periodStart = targetDate.subtract(1, 'month').startOf('month');
    const pendingOrderInvoiceItems = pendingInvoiceItems?.filter(pendingItem => !!pendingItem.order_id);
    const amountDue = sumBy(pendingOrderInvoiceItems, i => i.amount_cents);

    return {
        // there will only be one preview invoice so it's safe to hardcode the id
        id: previewUsageInvoiceId,
        // For an invoice that hasn't been finalized yet, we don't have an invoice number
        invoice_number: null,
        amount_due: amountDue,
        amount_paid: 0,
        amount_remaining: amountDue,
        created_at: targetDate.format('YYYY-MM-DD').toString(),
        month_formatted: periodStart.format('MMM YYYY'),
        is_overdue: false,
        period_start: periodStart.toISOString(),
        period_end: periodStart.endOf('month').format('YYYY-MM-DD').toString(),
        status: LabsGqlStripeInvoiceStatus.Draft,
        total: amountDue,
        idx: 0,
        items_order_count: pendingInvoiceItems?.length ?? 0,
        items_total_amount_cents: amountDue,
        pending_payment_amount_cents: 0,
        ...usageInvoiceEmptyFields,
    };
}

const getAllInvoiceDatesForSpendTerm = (term: ContractSpendTerm | undefined) => {
    if (!term) {
        return [];
    }
    const startDate = term.effective_start_date;
    const cycleLength = term.spend_cycle_length;
    const dates = [];
    for (let i = 0; i < cycleLength; i++) {
        dates.push(dayjs(startDate).add(i, 'months'));
    }

    return dates;
};

/**
 * Given an invoice, find the spend term it belongs to.
 * if there are other invoices associated to the same spend term,
 * determine the sequencing of the provided invoice within the spend term.
 * Multi-month spend terms will likely have multiple invoices within the term,
 * and multi-location contracts may have multiple invoices per month
 */
function spendTermDataForInvoice(invoice: PracticeInvoice, spendTerms: ContractSpendTerm[]): SpendTermData {
    const invoiceDate = dayjs(invoice.period_start);

    // Will not match if the end date is null, but all spend terms with null end dates are single month or way in the
    // future. single month spend terms don't need this data as it is used to show invoice location in multi-month
    // spend terms.

    // for the given invoice, find the spend term it belongs to
    const invoiceSpendTerm = spendTerms.find(
        s =>
            dayjs(s.effective_start_date).isSameOrBefore(invoiceDate, 'day') &&
            !!s?.effective_end_date &&
            dayjs(s.effective_end_date).isSameOrAfter(invoiceDate, 'day'),
    );

    // get all the possible invoice dates for the spend term
    // (it's possible, though unlikely, that a practice might not be invoiced for a given month
    // within a spend term, so use the dates themselves as the sequencing source of truth)
    const spendTermDates = getAllInvoiceDatesForSpendTerm(invoiceSpendTerm);
    const spendTermInvoiceCount = invoiceSpendTerm?.spend_cycle_length ?? 0;

    const getInvoiceSequence = () => {
        if (!spendTermInvoiceCount) {
            return 0;
        }

        const dateIndex = spendTermDates.findIndex(d => d.isSame(invoiceDate, 'month'));
        return dateIndex ?? 0;
    };
    const invoiceSequence = getInvoiceSequence();

    return {
        spendTerm: invoiceSpendTerm,
        invoiceSequence,
        spendTermInvoiceCount,
    };
}

export function useInvoicesForTableData(
    practiceInvoices: PracticeInvoice[],
    spendTerms: ContractSpendTerm[],
    pendingInvoiceItems: PendingInvoiceItem[] | undefined,
) {
    return React.useMemo(() => {
        const pendingInvoiceArr = [];
        // preview usage invoice
        if (pendingInvoiceItems) {
            const previewUsageInvoice = buildUsageInvoice(pendingInvoiceItems);
            pendingInvoiceArr.push(previewUsageInvoice);
        }
        // sort invoices by
        // * month in reverse chronological order, then
        // * prepayments always first each month, then finally
        // * day in chronological order
        const sortedInvoices = sortBy(
            [...practiceInvoices, ...pendingInvoiceArr],
            r => -dayjs(r.period_start).startOf(`month`).valueOf(),
            r => dayjs(r.period_start).valueOf(),
        );

        return sortedInvoices.map<InvoiceTableRow>(invoice => {
            const { invoiceSequence, spendTermInvoiceCount, spendTerm } = spendTermDataForInvoice(invoice, spendTerms);

            const spendTermEndDate = !!spendTerm?.effective_end_date ? dayjs(spendTerm?.effective_end_date) : null;

            return {
                ...invoice,
                month_formatted: invoice.month_formatted,
                spendTermSequence: invoiceSequence >= 0 ? invoiceSequence : 0,
                spendTermInvoiceCount,
                ...(invoice.id === previewUsageInvoiceId ? { pending_items: pendingInvoiceItems } : {}),
                spendTermEndDate,
            };
        });
    }, [practiceInvoices, spendTerms, pendingInvoiceItems]);
}

const statsSeparator = ' - ';

function invoiceDetailedStatus(invoice: PracticeInvoice | PracticeInvoiceDetail, autoPayEnabled: boolean): string {
    const daysTillDue = daysUntilInvoiceDue(invoice);
    if (invoice.status !== 'open') {
        return startCase(invoice.status);
    }
    const pendingPaymentAmountCents = isInvoiceSummary(invoice)
        ? invoice.pending_payment_amount_cents
        : sumBy(
              invoice.payments.filter(p => p.status === LabsGqlPaymentStatus.Pending),
              p => p.amount_cents,
          );

    const statusDetails: string[] = [];
    // Has the invoice been partially paid?
    if (invoice.amount_paid > 0) {
        statusDetails.push(`Partially paid`);
    }

    // Any pending payments?
    if (pendingPaymentAmountCents > 0) {
        // Invoice is open with pending payments
        if (pendingPaymentAmountCents + invoice.amount_paid >= invoice.amount_remaining) {
            statusDetails.push(`Final payment(s) pending`);
            return statusDetails.join(statsSeparator);
        } else {
            statusDetails.push(`Partial payment(s) pending`);
        }
    }

    const chargeVerbage = autoPayEnabled ? `You'll be charged` : 'Due';
    if (daysTillDue < 0) {
        statusDetails.push(`Overdue by ${-daysTillDue} days`);
    } else if (daysTillDue === 0) {
        statusDetails.push(`${chargeVerbage} today`);
    } else if (daysTillDue > 7) {
        const dueDate = dayjs(invoice.due_date);
        const formattedDueDate = dueDate.format(`MMMM Do`);
        statusDetails.push(`${chargeVerbage} on ${formattedDueDate}`);
    } else {
        statusDetails.push(`${chargeVerbage} in ${daysTillDue} days`);
    }
    return statusDetails.join(statsSeparator);
}

/**
 * Return the day of month, along with the next date (as a Dayjs object), for when invoices
 * will be due and generated.
 *
 * This is a memoized React hook for getNextInvoiceDateDetails.
 */
export function useNextInvoicingDateDetails(): NextInvoicingDateDetails {
    return React.useMemo(() => getNextInvoiceDateDetails(), []);
}

/**
 * Return the day of month, along with the next date (as a Dayjs object), for when invoices
 * will be due and generated.
 *
 * See useNextInvoicingDateDetails for a memoized React hook.
 */
export function getNextInvoiceDateDetails(): NextInvoicingDateDetails {
    const invoiceGenerationDayOfMonth = 1;
    const invoiceDueDayOfMonth = 8;
    const nextInvoiceGeneration = upcomingInvoicingDate(invoiceGenerationDayOfMonth);
    const nextInvoiceDue = upcomingInvoicingDate(invoiceDueDayOfMonth);
    return {
        invoiceGenerationDayOfMonth,
        invoiceDueDayOfMonth,
        nextInvoiceGeneration,
        nextInvoiceDue,
    };
}

function upcomingInvoicingDate(dayOfMonth: number): dayjs.Dayjs {
    const currentDate = dayjs().utc();
    const currentHour = currentDate.hour();
    if (currentDate.date() < dayOfMonth || (currentDate.date() === dayOfMonth && currentHour < 14)) {
        return currentDate.set('date', dayOfMonth).startOf('date');
    }
    return currentDate.add(1, 'month').set('date', dayOfMonth).startOf('date');
}

export function useInvoiceDetailStatus(
    invoice: PracticeInvoice | PracticeInvoiceDetail,
    autochargeEnabled: boolean,
): string {
    return React.useMemo(() => {
        return invoiceDetailedStatus(invoice, autochargeEnabled);
    }, [autochargeEnabled, invoice]);
}

export const gqlFragmentsToPaymentMethods = (
    rawData: LabsGqlLabPaymentMethodsQuery | undefined,
): LabsGqlPaymentMethodFragment[] => {
    if (!rawData) {
        return [];
    }
    return rawData.getLabsPaymentMethods.map(pm => omit(pm, '__typename')) as LabsGqlPaymentMethodFragment[];
};
