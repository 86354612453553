import { GetUserFromDrPrefId_Query } from '../../../../graphql/useGetUserFromDrPrefId.graphql';
import { useIsChairside, usePortalToChairsideBridgeCommand } from '../../../../utils/chairside.hooks';
import { useNextStep } from './useNextStep';
import { useMutation, useQuery } from '@apollo/client';
import type { CreatePassword_MutationMutationVariables } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { useApolloRefetch } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useNavigate } from 'react-router-dom-v5-compat';

const CreatePassword_Mutation = graphql(`
    mutation CreatePassword_Mutation($args: CreatePasswordArgs!) {
        createPassword(args: $args) {
            user_id
        }
    }
`);

const GetPracticeOnboarding_Query = graphql(`
    query GetPracticeOnboardingPortalComplete {
        getPracticeOnboarding {
            portal_onboarding_complete
        }
    }
`);

export function useCreatePassword() {
    const navigate = useNavigate();
    const isChairside = useIsChairside();
    const [submitMtn] = useMutation(CreatePassword_Mutation);
    const goToNextStep = useNextStep();
    const refetch = useApolloRefetch();
    const doctorCredentialSetupCompletedAction = usePortalToChairsideBridgeCommand('doctorCredentialSetupCompleted');
    const { data: practiceOnboardingData } = useQuery(GetPracticeOnboarding_Query);
    // If the result is null, the practice predates the new onboarding system, and thus does not need to have their training completed.
    const practiceNeedsOnboarding =
        !!practiceOnboardingData?.getPracticeOnboarding &&
        !practiceOnboardingData.getPracticeOnboarding.portal_onboarding_complete;

    const mtnSubmitter = ({ args }: CreatePassword_MutationMutationVariables) => {
        const { password, preferences_id, email } = args;
        return submitMtn({
            variables: {
                args: {
                    password,
                    preferences_id,
                    email,
                },
            },
        });
    };
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully added user to organization!', {}],
        onSuccess: () => {
            refetch.query(GetUserFromDrPrefId_Query);
            if (isChairside) {
                doctorCredentialSetupCompletedAction?.({
                    practice_needs_onboarding: practiceNeedsOnboarding,
                });
            } else if (practiceNeedsOnboarding) {
                navigate('/onboarding');
            } else {
                goToNextStep();
            }
        },
        // error state is handled by generic error handling in useChangeSubmissionFn
    });

    return { submit, submitting };
}
