import type { ChatState } from '../screens/chat/chat-state/chat.reducer';
import type { CSTState } from '../screens/chat/cst-state/cst.reducer';
import type { CheckoutState } from '../screens/checkout-v2/state/checkout.state';
import type { AlignerCheckoutState } from '../screens/checkout-v2/state/reducers/aligners-checkout.types';
import type { DentureCheckoutState } from '../screens/checkout-v2/state/reducers/dentures-checkout.types';
import type { FeedbackState } from '../screens/feedback/state/feedback.reducer';
import type { ImplantReadinessState } from '../screens/implant_readiness/state/ImplantReadiness.types';
import type { MobileAuthState } from '../screens/mobile-auth/state/MobileAuth.reducer';
import type { ScanbodyRequestState } from '../screens/scanbodies/state/ScanbodyRequest.reducer';
import type { SelectStaffState } from '../screens/select-staff/state/select-staff.reducer';
import type { TryInFeedbackState } from '../screens/try_in_feedback/state/TryInFeedback.types';
import type { OnboardingState } from './onboarding/onboarding.reducer';
import type { OrdersState } from './orders/orders.reducer';
import type { PracticeUIState } from './ui';
import type { StateWithAsync } from '@orthly/redux-async-actions';
import type { RouterState } from 'redux-first-history';

export interface PartnerAppState extends StateWithAsync {
    checkout: CheckoutState;
    alignerCheckout: AlignerCheckoutState;
    dentureCheckout: DentureCheckoutState;
    onboarding: OnboardingState;
    orders: OrdersState;
    feedback: FeedbackState;
    router: RouterState;
    ui: PracticeUIState;
    scanbodyRequest: ScanbodyRequestState;
    implantReadiness: ImplantReadinessState;
    staffMember: SelectStaffState;
    tryInFeedback: TryInFeedbackState;
    chat: ChatState;
    mobileAuth: MobileAuthState;
    cst: CSTState;
}
