import { useInvoicesTableContext } from '../components/providers/InvoicesTableProvider.graphql';
import { Link, Text, Tooltip, styled } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    gap: '16px',
    padding: '0 24px 24px',
});

const StyledLink = styled(Link)({
    cursor: 'pointer',
});

export const BillingContactList: React.VFC = () => {
    const {
        primaryBillingContact: { email, name },
    } = useInvoicesTableContext();

    return (
        <Container>
            <Text variant={'body2'} medium color={'DARK_GRAY'}>
                Billing contacts:
            </Text>

            <Text variant={'body2'} color={'DARK_GRAY'}>
                Primary:{' '}
                <Tooltip title={email} placement={'right'} arrow>
                    <StyledLink href={`mailto:${email}`}>{name}</StyledLink>
                </Tooltip>
            </Text>
        </Container>
    );
};
