import { useStripe } from '../../../../utils/useStripe.hook';
import { AddCreditCardModal } from '../../../account/practice-settings/payment/AddCreditCardModal';
import { LinkBankAccount } from '../../../account/practice-settings/payment/LinkBankAccount';
import { usePaymentMethodControls } from '../../../account/practice-settings/payment/usePaymentMethodControls';
import { useInvoicesTableContext } from '../../components/providers/InvoicesTableProvider.graphql';
import { gqlFragmentsToPaymentMethods } from '../../invoicing.utils';
import { PaymentInvoiceDetails } from './PaymentDialogDetails.graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlPaymentMethodFragment } from '@orthly/graphql-operations';
import { useLabPaymentMethodsQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { apolloErrorMessage, LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { Button, FormControl, MenuItem, Select, Text, styled } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const DetailsContainer = styled('div')({
    paddingBottom: '12px',
    width: '100%',
});

const StyledSelect = styled(Select)({
    padding: '0px 0px 8px 0px !important',
    '&& div': {
        padding: '16px 16px 8px 16px !important',
    },
});

const PaymentMethodMenuItem: React.FC<{ paymentMethod: LabsGqlPaymentMethodFragment }> = ({ paymentMethod: pm }) => {
    let content = pm.method === 'card' ? `${pm.brand} ending in ${pm.last4}` : `ACH (${pm.name}) ending in ${pm.last4}`;
    if (pm.is_default) {
        content += ' (default)';
    }

    return <Text variant={'body2'}>{content}</Text>;
};

const PaymentMethodContainer: React.FC<{
    paymentMethods: LabsGqlPaymentMethodFragment[];
    selectedId: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
    setOpenAddCreditCard: (open: boolean) => void;
    setOpenLinkBankAccount: (open: boolean) => void;
    newlyCreatedPaymentMethodId: string;
}> = ({
    paymentMethods,
    selectedId,
    onChange,
    setOpenAddCreditCard,
    setOpenLinkBankAccount,
    newlyCreatedPaymentMethodId,
}) => {
    const addNewCreditCardKey = 'add-credit-card';
    const linkNewBankAccountKey = 'link-bank-account';
    const newPaymentMethodActions = [
        { value: addNewCreditCardKey, label: 'Add a credit card', action: () => setOpenAddCreditCard(true) },
        { value: linkNewBankAccountKey, label: 'Add ACH', action: () => setOpenLinkBankAccount(true) },
    ];

    // Once we know the paymentMethods have been updated and the value now exists in the dropdown,
    // set the selected value to be the newly created payment method if it exists
    React.useEffect(() => {
        onChange(newlyCreatedPaymentMethodId);
        // We only want to set this once we know the paymentMethods have been updated
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethods]);

    const resetPaymentMethodModals = () => {
        setOpenAddCreditCard(false);
        setOpenLinkBankAccount(false);
    };

    return (
        <>
            <Text variant={'h6'} sx={{ marginTop: '24px', marginBottom: '16px' }}>
                Pay with method
            </Text>
            <FormControl fullWidth variant={'standard'}>
                <StyledSelect
                    fullWidth
                    value={selectedId}
                    displayEmpty={true}
                    onChange={event => {
                        const pmId = event.target.value as string;
                        onChange(pmId);
                        resetPaymentMethodModals();
                        const actionItem = newPaymentMethodActions.find(item => item.value === pmId);
                        if (actionItem) {
                            actionItem.action();
                        }
                    }}
                    variant={'standard'}
                >
                    <MenuItem disabled value={''}>
                        <em>Select a payment method</em>
                    </MenuItem>
                    {paymentMethods.map(pm => (
                        <MenuItem sx={{ width: '100%' }} value={pm.id} key={pm.id}>
                            <PaymentMethodMenuItem paymentMethod={pm} />
                        </MenuItem>
                    ))}
                    {newPaymentMethodActions.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                            <em>{item.label}</em>
                        </MenuItem>
                    ))}
                </StyledSelect>
            </FormControl>
        </>
    );
};

interface PaymentContainerProps {
    paymentMethods: LabsGqlPaymentMethodFragment[];
    selectedPaymentMethodId: string;
    setSelectedPaymentMethodId: React.Dispatch<React.SetStateAction<string>>;
    onClose: () => void;
    onSubmit: () => void;
    setOpenAddCreditCard: (open: boolean) => void;
    setOpenLinkBankAccount: (open: boolean) => void;
    newlyCreatedPaymentMethodId: string;
}

const Layout = styled('div')({
    width: '100%',
});

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        gap: '16px',
    },
}));

const PaymentContainer: React.FC<PaymentContainerProps> = ({
    paymentMethods,
    onClose,
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    onSubmit,
    setOpenAddCreditCard,
    setOpenLinkBankAccount,
    newlyCreatedPaymentMethodId,
}) => {
    const { payableSelectedInvoices } = useInvoicesTableContext();
    const buttonText = payableSelectedInvoices.length > 1 ? 'Pay Invoices' : 'Pay Invoice';

    return (
        <Layout>
            <PaymentMethodContainer
                paymentMethods={paymentMethods}
                selectedId={selectedPaymentMethodId}
                onChange={setSelectedPaymentMethodId}
                setOpenAddCreditCard={setOpenAddCreditCard}
                setOpenLinkBankAccount={setOpenLinkBankAccount}
                newlyCreatedPaymentMethodId={newlyCreatedPaymentMethodId}
            />
            <ButtonContainer>
                <Button variant={'secondary'} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant={'primary'} onClick={onSubmit}>
                    {buttonText}
                </Button>
            </ButtonContainer>
        </Layout>
    );
};

const PayContractInvoicesWithSource_Mutation = graphql(`
    mutation PayContractInvoicesWithPaymentSource($data: PayContractInvoicesWithSourceInput!) {
        payContractInvoicesWithPaymentSource(data: $data) {
            id
        }
    }
`);

interface PaymentDialogProps {
    invoiceDetailId?: string; // manually provided invoice id which we're paying
    invoiceIds?: string[]; // manually provided list of *all* payable invoice ids
    refetchInvoice?: () => Promise<unknown>;
    onClose: () => void;
    willBeChargedCCFee?: boolean;
}

/**
 * The payment dialog can be accessed in a few ways:
 * // TODO: EPDB-975: clean up legacy comment
 * - from the invoices overview table (inline) - soon to be deprecated for multilocation payment options
 *   - in this case, we're only paying one invoice, so we provide an `invoiceDetailId`
 *
 * - from the invoices overview table (payment tile)
 *   - here, we're paying *all* of the selected org's open/payable invoices. if no org is selected,
 *     we're paying all of the contracted practice's open/payable invoices, passed in as `invoiceIds`
 *
 * - from the invoices table controls
 *   - here, we're *only* paying the selected "payable" invoices. since selection is handled statefully
 *     in the invoices table context provider, so we don't pass in any variables (use useInvoicesTableContext instead)
 *
 * - from the invoice details screen
 *   - in this case, we're only paying one invoice, so we provide an `invoiceDetailId`
 */
export const PaymentDialog: React.FC<PaymentDialogProps> = ({
    invoiceDetailId,
    invoiceIds,
    refetchInvoice: refetchInvoiceDetail,
    onClose,
    willBeChargedCCFee,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { refetch: refetchInvoicesList, payableSelectedInvoices, setSelectedInvoiceIds } = useInvoicesTableContext();
    const [openAddCreditCard, setOpenAddCreditCard] = React.useState<boolean>(false);
    const [openLinkBankAccount, setOpenLinkBankAccount] = React.useState<boolean>(false);
    const [linkingBankAccount, setLinkingBankAccount] = React.useState<boolean>(false);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState('');
    const [newlyCreatedPaymentMethodId, setNewlyCreatedPaymentMethodId] = React.useState<string>('');

    const { createPaymentMethod } = usePaymentMethodControls();
    const session = useSession();
    const organizationId = session?.organization_id ?? '';
    const stripe = useStripe();

    const { data: paymentMethodsData, loading: paymentMethodsLoading } = useLabPaymentMethodsQuery();
    const paymentMethods = React.useMemo(
        () => gqlFragmentsToPaymentMethods(paymentMethodsData) ?? [],
        [paymentMethodsData],
    );
    const defaultPaymentMethod = React.useMemo(() => paymentMethods.find(pm => pm.is_default), [paymentMethods]);

    React.useEffect(() => {
        // When we finish loading the payment methods and we didn't just create a new payment method from the modal
        if (!paymentMethodsLoading && defaultPaymentMethod && !newlyCreatedPaymentMethodId) {
            // Set the selected payment method to be the default
            setSelectedPaymentMethodId(defaultPaymentMethod.id);
        }
    }, [paymentMethodsLoading, defaultPaymentMethod, newlyCreatedPaymentMethodId]);

    const paymentMethodIsCreditCard = () => {
        const selectedPaymentMethod = paymentMethods.find(pm => pm.id === selectedPaymentMethodId);
        return selectedPaymentMethod?.method === 'card' && selectedPaymentMethod?.funding === 'credit';
    };

    const payContractInvoicesMtn = useMutation(PayContractInvoicesWithSource_Mutation);

    const handleSuccess = async () => {
        // When paying from the invoice detail page, we need to refetch the specific invoice that was being paid so that
        // the payment button disappears
        if (refetchInvoiceDetail) {
            await refetchInvoiceDetail();
        }
        // And to ensure that the list of invoices being rendered on the invoices overview screen also updates after
        // payment is made from the invoice detail screen, we refetch the total list of invoices here
        await refetchInvoicesList();
        // unset any selected invoice ids
        if (!invoiceDetailId && !invoiceIds) {
            setSelectedInvoiceIds([]);
        }
        onClose();
    };

    const { submit: payContractInvoices, submitting: payingContractInvoices } = useRootActionCommand(
        payContractInvoicesMtn,
        {
            successMessage: () => [
                'Thank you! Your payment has been created.',
                { anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
            ],
            onSuccess: handleSuccess,
        },
    );

    const onSubmit = () => {
        // The contract primary has access to paying other locations' invoices.
        // So we need to use the payContractInvoicesWithPaymentSource mutation
        // (even if they're only paying one invoice) to ensure the paying org has permissions
        if (invoiceDetailId) {
            void payContractInvoices({
                data: { invoiceIds: [invoiceDetailId], sourceId: selectedPaymentMethodId },
            });
        } else {
            // contract secondary locations will only have access to their own invoices
            // which they are able to pay simulataneously (if they have multiple selected)
            const ids = invoiceIds ?? payableSelectedInvoices.map(i => i.id);
            void payContractInvoices({
                data: { invoiceIds: ids, sourceId: selectedPaymentMethodId },
            });
        }
    };

    const linkAccount = async (routingNumber: string, accountNumber: string) => {
        if (!session || !organizationId) {
            return;
        }

        /**
         * We use `as` here because the `verification_method` is required to skip verification
         * for ACH accounts, but the typings for the Stripe.js wrapper don't have this property in their type
         */
        const createTokenOptions: stripe.BankAccountTokenOptions = {
            account_holder_name: session.organization_name,
            account_holder_type: 'company',
            country: 'US',
            currency: 'usd',
            account_number: accountNumber,
            routing_number: routingNumber,
            verification_method: 'skip',
        } as stripe.BankAccountTokenOptions;

        setLinkingBankAccount(true);
        let manualErrorThrown = false;
        try {
            const response = await stripe.createToken('bank_account', createTokenOptions);
            if (response.token) {
                await createPaymentMethod({
                    cardToken: response.token.id,
                    partnerId: organizationId,
                    setAsDefault: true,
                });
                setNewlyCreatedPaymentMethodId(response.token.bank_account?.id ?? '');
            } else {
                manualErrorThrown = true;
                throw new Error(response?.error?.message || 'unknown error');
            }
        } catch (e: any) {
            // "createPaymentMethod" already enqueues a snackbar if it throws, but if Stripe's "createToken" ends up returning an error
            // response, we manually throw the error above. To avoid enqueuing a snackbar twice, only do so if we manually threw the error
            if (manualErrorThrown) {
                enqueueSnackbar(apolloErrorMessage(e), { variant: 'error' });
            }
        }
        setLinkingBankAccount(false);
    };

    return (
        <>
            <AddCreditCardModal
                open={openAddCreditCard}
                setOpen={setOpenAddCreditCard}
                createCard={({ partnerId, cardToken }) =>
                    createPaymentMethod({ partnerId, cardToken, setAsDefault: false })
                }
                onOpenBankAccountModal={() => {
                    setOpenAddCreditCard(false);
                    setOpenLinkBankAccount(true);
                }}
                setNewlyCreatedPaymentMethodId={setNewlyCreatedPaymentMethodId}
                onClosePaymentAction={onClose}
            />
            <LinkBankAccount
                open={openLinkBankAccount}
                setOpen={setOpenLinkBankAccount}
                linkAccount={linkAccount}
                linkingBankAccount={linkingBankAccount}
                onClosePaymentAction={onClose}
            />
            <DetailsContainer>
                <PaymentInvoiceDetails
                    invoiceDetailId={invoiceDetailId}
                    invoiceIds={invoiceIds}
                    paymentMethodIsCreditCard={paymentMethodIsCreditCard()}
                    willBeChargedCCFee={willBeChargedCCFee}
                />
            </DetailsContainer>
            <LoadBlocker blocking={paymentMethodsLoading || payingContractInvoices}>
                <PaymentContainer
                    paymentMethods={paymentMethods}
                    selectedPaymentMethodId={selectedPaymentMethodId}
                    setSelectedPaymentMethodId={setSelectedPaymentMethodId}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    setOpenAddCreditCard={setOpenAddCreditCard}
                    setOpenLinkBankAccount={setOpenLinkBankAccount}
                    newlyCreatedPaymentMethodId={newlyCreatedPaymentMethodId}
                />
            </LoadBlocker>
        </>
    );
};
