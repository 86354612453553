import { usePracticeBannerData } from '../PracticeBannerProvider.graphql';
import { useShowMobileLayout } from '../utils/LayoutUtils';
import { MobilePageLayout } from './mobile/MobilePageLayout';
import { TOOLBAR_TABLET_DOWN_HEIGHT } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ChildrenRequiredProps {
    children: React.ReactNode;
}

const useSidebarRightPageContainerClasses = stylesFactory(theme => ({
    root: {
        flexWrap: 'nowrap',
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
            paddingTop: `${TOOLBAR_TABLET_DOWN_HEIGHT}px`,
        },
    },
    nativeRoot: {
        flexWrap: 'nowrap',
        position: 'relative',
    },
    mainSection: {
        overflow: 'scroll',
    },
    sidebarRoot: {
        minWidth: 328,
        maxWidth: 328,
        overflow: 'scroll',
        padding: '0 24px',
        background: FlossPalette.TAN,
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        [theme.breakpoints.down('md')]: { display: 'none' },
    },
}));

const useNoSidebarContainerClasses = stylesFactory(() => ({
    mobileRoot: {
        flexWrap: 'nowrap',
        position: 'relative',
        overflow: 'scroll',
    },
}));

interface SidebarRightPageLayoutProps extends ChildrenRequiredProps {
    sidebarContent: React.ReactNode;
    mobileTitle: string;
    mobileTitleHasBackButton?: boolean;
    titleAction?: React.ReactNode;
    sidebarStyle?: React.CSSProperties;
}

export const SidebarRightPageLayout: React.FC<SidebarRightPageLayoutProps> = props => {
    const { mobileTitle, titleAction, mobileTitleHasBackButton = false, sidebarStyle } = props;
    const classes = useSidebarRightPageContainerClasses();
    const isMobileLayout = useShowMobileLayout();

    const { contentHeight } = usePracticeBannerData();

    if (isMobileLayout) {
        return (
            <MobilePageLayout
                title={mobileTitle}
                titleHasBackButton={mobileTitleHasBackButton}
                titleAction={titleAction}
            >
                <Grid container className={classes.nativeRoot}>
                    <Grid container item className={classes.mainSection} style={{ height: contentHeight }}>
                        <Grid container style={{ alignSelf: 'flex-start', height: '100%' }}>
                            {props.children}
                        </Grid>
                    </Grid>
                    <Grid container item className={classes.sidebarRoot} style={{ height: contentHeight }}>
                        {props.sidebarContent}
                    </Grid>
                </Grid>
            </MobilePageLayout>
        );
    }

    return (
        <Grid container className={classes.root}>
            <Grid container item className={classes.mainSection} style={{ height: contentHeight }}>
                <Grid container style={{ alignSelf: 'flex-start', height: '100%' }}>
                    {props.children}
                </Grid>
            </Grid>
            <Grid container item className={classes.sidebarRoot} style={{ height: contentHeight, ...sidebarStyle }}>
                {props.sidebarContent}
            </Grid>
        </Grid>
    );
};

interface NoSidebarPageLayoutProps extends ChildrenRequiredProps {
    mobileTitle: string;
    mobileTitleHasBackButton?: boolean;
    titleAction?: React.ReactNode;
}

export const NoSidebarPageLayout: React.VFC<NoSidebarPageLayoutProps> = props => {
    const { mobileTitle, titleAction, mobileTitleHasBackButton = false } = props;
    const isMobileLayout = useShowMobileLayout();
    const classes = useNoSidebarContainerClasses();

    const { contentHeight } = usePracticeBannerData();

    if (isMobileLayout) {
        return (
            <MobilePageLayout
                title={mobileTitle}
                titleHasBackButton={mobileTitleHasBackButton}
                titleAction={titleAction}
            >
                <div className={classes.mobileRoot} style={{ height: contentHeight }}>
                    {props.children}
                </div>
            </MobilePageLayout>
        );
    }

    return <>{props.children}</>;
};
