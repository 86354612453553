import { setisNativeApp } from '../analytics/analyticsClient';
import type { PartnerAppState } from './types';
import { PartnerUiActions } from './ui.actions';
import { PracticeScreen } from '@orthly/dentin';
import { generateUseSubstateSelector } from '@orthly/redux-async-actions';
import { QueryString } from '@orthly/ui';
import type { AnyAction, Reducer } from 'redux';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'redux-first-history';
import type { RouterState } from 'redux-first-history';

const query = QueryString.parse(window.location.search);
const isNativeApp = query.launch === 'pwa';
setisNativeApp(isNativeApp);

export interface PracticeUIState {
    isNativeApp: boolean;
    hideTopBar: boolean;
    sidebarOpen: boolean;
    screen: PracticeScreen;
    unreadInboxItems: number;
    unreadChatCount: number;
    activeSpotlight?: 'search' | 'qrCode';
}

const initialState: PracticeUIState = {
    isNativeApp,
    hideTopBar: false,
    sidebarOpen: false,
    screen: PracticeScreen.orders,
    unreadInboxItems: 0,
    unreadChatCount: 0,
};

const actionHandler = handleActions<PracticeUIState, any>(
    {
        [LOCATION_CHANGE]: (state, action: Action<RouterState>) => {
            // We only look at the first part after the leading slash so we can always highlight the link in the navigation menu
            // for pages like product hubs `/lab_products/clear_aligners` or my profile pages like `/my_profile/settings`
            const currentScreen = Object.values(PracticeScreen).find(screenName => {
                return action.payload.location?.pathname.split('/')[1] === screenName;
            });

            if (currentScreen && currentScreen !== state.screen) {
                return { ...state, screen: currentScreen };
            }

            return state;
        },
        ...PartnerUiActions.UPDATE_UI.reducer<PracticeUIState>((s, a) => ({ ...s, ...a.payload })),
        ...PartnerUiActions.SET_SPOTLIGHT_OPEN.reducer<PracticeUIState>((s, a) => ({
            ...s,
            activeSpotlight: a.payload ? 'search' : undefined,
        })),
        ...PartnerUiActions.SET_QR_CODE_OPEN.reducer<PracticeUIState>((s, a) => ({
            ...s,
            activeSpotlight: a.payload ? 'qrCode' : undefined,
        })),
        ...PartnerUiActions.SET_IS_PWA_MODE.reducer<PracticeUIState>((s, a) => ({
            ...s,
            isNativeApp: a.payload,
        })),
    },
    initialState,
);
export const uiReducer = actionHandler as Reducer<PracticeUIState, AnyAction>;
export const usePartnerUiSelector = generateUseSubstateSelector<PartnerAppState, 'ui'>('ui');
