import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import DDPLogo from '../../../../assets/ddp_logo.svg';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { RootActionDialog, LoadBlocker, useScreenIsMobile } from '@orthly/ui';
import { styled, Text, Button, FlossPalette } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const ETAWrapper = styled('div')({
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '8px',
    padding: '24px',
    backgroundColor: '#F9DAF133',
    marginTop: '24px',
});

const ActionRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '48px',
});

const RightButton = styled(Button)({
    marginLeft: 'auto',
});

const LogoImg = styled('img')({
    display: 'block',
    margin: '24px auto 24px auto',
});

type Props = {
    order: Pick<LabsGqlOrder, 'patient' | 'practice_dates' | 'id'>;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSubmit: () => void;
    loading: boolean;
};

const Content: React.FC<Props> = ({ order, setOpen, onSubmit, loading }) => {
    const { practice_dates } = order;
    const eta = practice_dates.digital_design_preview_estimated_completion_date;
    let etaBox = null;

    const innerOnSubmit = () => {
        AnalyticsClient.track('Practice - DDP Request Dialog Confirmed', {
            $groups: { order: order.id },
            action: 'add',
        });
        onSubmit();
    };

    if (eta) {
        etaBox = (
            <ETAWrapper>
                <Text variant={'body2'} color={'BLACK'}>
                    Your Digital Design Preview ETA is{' '}
                    <Text variant={'body2'} color={'SECONDARY_FOREGROUND'} component={'span'} bold>
                        {moment(eta).format('MMM D')}
                    </Text>
                    .
                </Text>
            </ETAWrapper>
        );
    }

    return (
        <LoadBlocker blocking={loading}>
            <div>
                {etaBox}
                <LogoImg src={DDPLogo} alt={'Digital Design Preview'} />
                <Text variant={'caption'} color={'DARK_GRAY'}>
                    Digital Design Previews let you review and approve 3D designs of your restorations before
                    fabrication. Use this tool to review proposed margin lines, assess fit and function, and share
                    feedback with your designer.
                </Text>
                <ActionRow>
                    <Button variant={'secondary'} onClick={setOpen.bind(null, false)}>
                        Go Back
                    </Button>
                    <RightButton variant={'primary'} onClick={innerOnSubmit}>
                        Add Digital Design Preview
                    </RightButton>
                </ActionRow>
            </div>
        </LoadBlocker>
    );
};

export const OrderDetailDDPAdditionDialog: React.FC<Props> = props => {
    const screenIsMobile = useScreenIsMobile();
    const { open, setOpen } = props;
    const { patient } = props.order;

    return (
        <RootActionDialog
            title={`Request a Digital Design Preview for ${patient.first_name} ${patient.last_name}`}
            setOpen={setOpen}
            open={open}
            content={<Content {...props} />}
            dialogProps={{ maxWidth: 'sm' }}
            onClose={setOpen.bind(null, false)}
            showCloseButton={!screenIsMobile}
            loading={false}
            hideButton
        />
    );
};
