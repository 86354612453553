import { LoggedInContainerPractice } from './components/Containers';
import { MobileChatRoot } from './mobile/screens/chat/MobileChatRoot';
import { MobileMoreRoot } from './mobile/screens/more/MobileMoreRoot';
import { MobileSearchRoot } from './mobile/screens/search/MobileSearchRoot';
import { usePartnerUiSelector } from './redux/ui';
import { PartnerProfile } from './screens/account/PartnerProfile';
import { PracticeSettings } from './screens/account/PracticeSettings';
import { AcknowledgeAgreementDialog } from './screens/agreement/AcknowledgeAgreementDialog.graphql';
import { FeedbackPage } from './screens/feedback/FeedbackPage';
import { AppointmentsRoot } from './screens/front-desk/AppointmentsRoot';
import { SetupRoot } from './screens/front-desk/SetupRoot';
import { ImplantReadinessRoot } from './screens/implant_readiness/ImplantReadinessRoot';
import { InboxRoot } from './screens/inbox/InboxRoot';
import { LabsRoot } from './screens/labs/LabsRoot';
import { PracticeOrderChatWrapper } from './screens/labs/components/PracticeOrderChatWrapper';
import { NotificationLogsRoot } from './screens/notification_logs/NotificationLogsRoot';
import { Onboarding } from './screens/onboarding/Onboarding';
import { PaymentMethodAndAddressRoot } from './screens/payment-and-address/PaymentMethodAndAddressRoot';
import { RefabFlowRoot } from './screens/refab-flow/refab-flow-root';
import { ScanbodiesRoot } from './screens/scanbodies/ScanbodiesRoot';
import { SelectStaffRoot } from './screens/select-staff/SelectStaffRoot';
import { TryInFeedbackRoot } from './screens/try_in_feedback/TryInFeedbackRoot';
import { useQuery } from '@apollo/client';
import { PracticeRouteWithMetadata } from '@orthly/analytics/dist/browser';
import { ATTACH_REFAB_FILES_PATH, PracticeScreen } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';
import { GuidedWaxupRoot, MobileRefabFlowFilesUploaderPage, useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const PracticePortalRootQuery = graphql(`
    query PracticePortalRoot_Query($practice_id: String!) {
        practiceOrdersCount(practice_id: $practice_id)
        getOrCreatePracticeSupport(practice_id: $practice_id) {
            agreement_version
        }
    }
`);

const GetPracticeOnboardingQuery = graphql(`
    query GetPracticeOnboardingComplete {
        getPracticeOnboarding {
            portal_onboarding_complete
        }
    }
`);

function usePracticeRouterQuery(): { isOnboarding: boolean; agreementVersion: string | undefined; loading: boolean } {
    const { value: enableGuidedOnboarding } = useFeatureFlag('enableGuidedOnboarding');
    const practice_id = useSession()?.organization_id;
    // If the forceOnboarding flag is on, we display the onboarding
    // view for testing purposes
    const { value: forceOnboarding } = useFeatureFlag('forceOnboarding');
    const { data, loading } = useQuery(PracticePortalRootQuery, {
        variables: { practice_id: practice_id ?? '' },
        skip: !practice_id,
    });

    const { data: practiceOnboardingData, loading: practiceOnboardingLoading } = useQuery(GetPracticeOnboardingQuery);

    const practiceOrdersCount = data?.practiceOrdersCount;
    const agreementVersion = data?.getOrCreatePracticeSupport.agreement_version ?? undefined;

    // If the result is null, the practice predates the new onboarding system, and thus does not need to have their training completed.
    const needsToCompleteOnboarding =
        !!practiceOnboardingData?.getPracticeOnboarding &&
        !practiceOnboardingData?.getPracticeOnboarding?.portal_onboarding_complete;

    let isOnboarding = practiceOrdersCount === 0;

    if (loading && enableGuidedOnboarding && !practiceOnboardingLoading) {
        isOnboarding = false;
    }

    if (forceOnboarding || (enableGuidedOnboarding && needsToCompleteOnboarding)) {
        isOnboarding = true;
    }
    return {
        isOnboarding,
        agreementVersion,
        loading,
    };
}

export const PracticePortalRouter: React.FC = () => {
    const { isOnboarding, agreementVersion, loading } = usePracticeRouterQuery();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const { value: enableGuidedOnboarding, loading: enableGuidedOnboardingLoading } =
        useFeatureFlag('enableGuidedOnboarding');

    return (
        <LoggedInContainerPractice>
            <SelectStaffRoot />
            <AcknowledgeAgreementDialog agreementVersion={agreementVersion} loading={loading} />
            <Route
                render={({ location }) => (
                    <Switch location={location}>
                        <Route
                            path={`/${PracticeScreen.onboarding}`}
                            render={() => {
                                if (enableGuidedOnboardingLoading) {
                                    return null;
                                } else if (enableGuidedOnboarding) {
                                    return <Onboarding />;
                                } else {
                                    return <Redirect to={'/'} />;
                                }
                            }}
                        />
                        <Route path={`/${PracticeScreen.my_profile}`}>
                            <PartnerProfile />
                        </Route>
                        <Route path={`/${PracticeScreen.my_practice}`}>
                            <PracticeSettings />
                        </Route>
                        <Route path={[`/${PracticeScreen.orders}`, `/${PracticeScreen.lab}`]}>
                            <LabsRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.setup}`}>
                            <SetupRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.appointments}`}>
                            <AppointmentsRoot />
                        </Route>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.scanbody_inventory}`}
                            title={isNativeApp ? 'Mobile Scan bodies' : 'Scan bodies'}
                        >
                            <ScanbodiesRoot />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.scanbodies}`}
                            title={isNativeApp ? 'Mobile Implant Readiness' : 'Implant Readiness'}
                        >
                            <ImplantReadinessRoot />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.inbox}`}
                            title={isNativeApp ? 'Mobile Inbox' : 'Inbox'}
                        >
                            <InboxRoot showOnboardingInbox={isOnboarding} loading={loading} />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.chat}`}
                            title={isNativeApp ? 'Mobile Native Chat' : 'Mobile Chat'}
                        >
                            <MobileChatRoot />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.notification_logs}`}
                            title={isNativeApp ? 'Mobile Notification Logs' : 'Notification Logs'}
                        >
                            <NotificationLogsRoot />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.search}`}
                            title={isNativeApp ? 'Mobile Native Search' : 'Mobile Search'}
                        >
                            <MobileSearchRoot />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.more}`}
                            title={isNativeApp ? 'Mobile Native More' : 'Mobile More'}
                        >
                            <MobileMoreRoot />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata
                            path={`/${PracticeScreen.feedback}/:lab_order_id`}
                            title={isNativeApp ? 'Mobile Feedback' : 'Feedback'}
                            metadataProps={params => ({
                                $groups: {
                                    order: params.lab_order_id,
                                },
                            })}
                        >
                            <FeedbackPage />
                        </PracticeRouteWithMetadata>
                        <Route
                            path={`/${PracticeScreen.dentures_feedback}/:orderId`}
                            render={props => (
                                <Redirect to={`/${PracticeScreen.try_in_feedback}/${props.match.params.orderId}`} />
                            )}
                        />
                        <Route
                            path={`/${PracticeScreen.partials_feedback}/:orderId`}
                            render={props => (
                                <Redirect to={`/${PracticeScreen.try_in_feedback}/${props.match.params.orderId}`} />
                            )}
                        />
                        <Route path={`/${PracticeScreen.try_in_feedback}/:orderId`}>
                            <TryInFeedbackRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.refab_flow}/:orderId/${ATTACH_REFAB_FILES_PATH}/items/:itemId`}>
                            <MobileRefabFlowFilesUploaderPage />
                        </Route>
                        <Route path={`/${PracticeScreen.refab_flow}/:orderId/${ATTACH_REFAB_FILES_PATH}`}>
                            <MobileRefabFlowFilesUploaderPage />
                        </Route>
                        <Route path={`/${PracticeScreen.refab_flow}/:orderId`}>
                            <RefabFlowRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.guided_waxup}/:orderId/:revisionId?`}>
                            <GuidedWaxupRoot OrderChatWrapper={PracticeOrderChatWrapper} />
                        </Route>
                        <Route path={`/${PracticeScreen.payment_methods_and_addresses}`}>
                            <PaymentMethodAndAddressRoot />
                        </Route>
                        <Route
                            exact
                            path={`/invoices/:invoiceDetailId`}
                            render={props => {
                                return (
                                    <Redirect
                                        from={`/invoices/:invoiceDetailId`}
                                        to={`/${PracticeScreen.billing}/${props.match.params.invoiceDetailId}`}
                                    />
                                );
                            }}
                        />
                        <Redirect exact from={'/invoices'} to={PracticeScreen.billing} />
                        <Redirect to={{ pathname: `/${PracticeScreen.inbox}`, search: location.search }} />
                    </Switch>
                )}
            />
        </LoggedInContainerPractice>
    );
};
