import { PatientShareScansModelViewerSidebar } from './PatientShareScansModelViewerSidebar';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { MainViewCameraControlsRef, MainViewModelRef, ModelAppearance, ModelPayloadItem } from '@orthly/dentin';
import { MODEL_VIEWER_INITIAL_APPEARANCE, NewModelViewer } from '@orthly/dentin';
import type {
    FragmentType,
    OrderDesignPreviewDesignModelPayloadsScanExport_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, styled, useScreenIsMobile, Text } from '@orthly/ui-primitives';
import { useDesignModelPayloadsForScanInlineGraphql, getScanPayloadItems, useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const Container = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    borderRadius: '16px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    height: '336px',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
    },
}));

const ModelViewerWrapper = styled('div')({
    display: 'relative',
    height: '336px',
    maxHeight: '336px',
    overflow: 'hidden',
});

interface PatientShareScansModelViewerProps {
    scanExportFragment: FragmentType<typeof OrderDesignPreviewDesignModelPayloadsScanExport_FragmentFragmentDoc>;
}

/**
 * Renders a model viewer and the sidebar controls together.
 */
export const PatientShareScansModelViewer: React.VFC<PatientShareScansModelViewerProps> = ({ scanExportFragment }) => {
    const { value: enableCustomLightingShaderMaterial } = useFeatureFlag('useCustomLightingShaderMaterial');

    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    const { result: designPayloads, loading: designPayloadsLoading } =
        useDesignModelPayloadsForScanInlineGraphql(scanExportFragment);

    // We filter out bites because the bite scan is very confusing for a patient to see.
    // The jaws should already be aligned by the doctor, so the bite scan isn't useful in this context.
    const model_payload_items = React.useMemo<ModelPayloadItem[]>(() => {
        const itemsPotentiallyWithBite = getScanPayloadItems(_.compact(designPayloads) ?? []);
        const itemsWithoutBite = itemsPotentiallyWithBite.filter(item => !item.name.includes('Bite'));
        const jaws = _.groupBy(itemsWithoutBite, item => item.jaw);

        // If there are multiple scans per jaw, only return prepped scans.
        return Object.values(jaws).reduce((acc, items) => {
            return acc.concat(items.filter(item => !item.name.includes('PrePrep')));
        }, [] as ModelPayloadItem[]);
    }, [designPayloads]);

    const trackModelViewerInteraction = () => {
        BrowserAnalyticsClientFactory.Instance?.track('Patient App - Share Scans - Model Viewer Interaction', {});
    };

    const isMobile = useScreenIsMobile();

    return (
        <LoadBlocker blocking={designPayloadsLoading} overlayColor={'transparent'}>
            <Container onClick={trackModelViewerInteraction}>
                <PatientShareScansModelViewerSidebar appearance={appearance} setAppearance={setAppearance} />
                <ModelViewerWrapper>
                    <NewModelViewer
                        orderMaterialsHaveLayers={false}
                        // We set this to fullscreen so that it will adapt to the container's height rather than hardcoding to 520px.
                        full_screen={true}
                        model_payload_items={model_payload_items}
                        hideUi={isMobile}
                        // All of these are turned off because patients don't do any QC.
                        enable_qc_tools={{
                            enableAnatomyLayers: false,
                            enableCollisions: false,
                            enableCrossSections: false,
                            enableDynamicHeatmaps: false,
                            enableHeatmaps: false,
                            enableUndercutHeatmap: false,
                            enableTissuePressureHeatmap: false,
                            enableMarginLines: false,
                            enableDoctorMarginLines: false,
                            enableDoctorToothMarkings: false,
                            editMarginLines: false,
                            enableUndercutView: false,
                        }}
                        // These control other tools that the user shouldn't have access to.
                        hide_toolbar
                        hideOverlayTools
                        designQcConfig={{
                            appearance,
                            setAppearance,
                            modelRef,
                            controlRef,
                        }}
                        showOrderScans
                        enableNewScanMeshMaterial={enableCustomLightingShaderMaterial}
                        // Hardcoded to false because patients aren't going to be familiar with these controls.
                        // They're fairly unintuitive to non-Designers.
                        use3ShapeViewerControls={false}
                    />
                </ModelViewerWrapper>
            </Container>
        </LoadBlocker>
    );
};
