import {
    Tooltip,
    Text,
    Icon,
    Link,
    Box,
    ClickAwayListener,
    useScreenIsMobile,
    FlossPalette,
} from '@orthly/ui-primitives';
import React from 'react';

interface ProcessingFeeTooltipProps {
    iconStyles?: React.CSSProperties;
}

export const ProcessingFeeTooltip: React.VFC<ProcessingFeeTooltipProps> = ({ iconStyles }) => {
    const isMobile = useScreenIsMobile();
    const [open, setOpen] = React.useState(false);

    const handleToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpen(prev => !prev);
    };

    const tooltip = (
        <Tooltip
            slotProps={{
                tooltip: {
                    sx: {
                        backgroundColor: FlossPalette.GRAY,
                    },
                },
            }}
            title={
                <Box>
                    <Text color={'WHITE'} variant={'caption'}>
                        Credit card payments are subject to a 2.9% processing fee. Avoid the fee by paying via ACH.{' '}
                    </Text>
                    <Link
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        href={
                            'https://help.meetdandy.com/hc/en-us/articles/14705861615501-Adding-a-Bank-Account-as-Primary-Payment-Method-in-the-Dandy-Portal#adding-a-bank-account-as-primary-payment-method-in-the-dandy-portal-0'
                        }
                        underline={'none'}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <Text bold variant={'caption'} color={'WHITE'}>
                            View instructions here
                        </Text>
                    </Link>
                </Box>
            }
            open={isMobile ? open : undefined}
            onClick={isMobile ? e => handleToggle(e) : undefined}
            placement={isMobile ? 'bottom' : 'right'}
        >
            {/* We need to wrap the icon with something that can hold a ref here, or else MUI's tooltip won't work on hover */}
            <Box component={'span'} sx={{ height: '18px', width: '18px' }}>
                <Icon icon={'InfoOutlinedIcon'} style={{ fontSize: 18, marginLeft: 3, ...iconStyles }} />
            </Box>
        </Tooltip>
    );
    return isMobile ? <ClickAwayListener onClickAway={() => setOpen(false)}>{tooltip}</ClickAwayListener> : tooltip;
};
