import { useConfigureWaxupMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

export function useWaxupMutations(orderID: string, refetchOrder: (id: string) => void) {
    const [submitWaxup] = useConfigureWaxupMutation();
    const {
        submit: addWaxup,
        submitting: addingWaxup,
        open: addWaxupOpen,
        setOpen: setAddWaxupOpen,
    } = useChangeSubmissionFn(
        () =>
            submitWaxup({
                variables: {
                    order_id: orderID,
                    waxup_required: true,
                    doctor_requested: true,
                },
            }),
        {
            closeOnComplete: true,
            closeOnError: true,
            onSuccess: () => refetchOrder(orderID),
        },
    );
    const {
        submit: removeWaxup,
        submitting: removingWaxup,
        open: removeWaxupOpen,
        setOpen: setRemoveWaxupOpen,
    } = useChangeSubmissionFn(
        () =>
            submitWaxup({
                variables: {
                    order_id: orderID,
                    waxup_required: false,
                    doctor_requested: true,
                },
            }),
        {
            closeOnComplete: true,
            closeOnError: true,
            onSuccess: () => refetchOrder(orderID),
        },
    );

    return {
        addWaxup,
        addingWaxup,
        addWaxupOpen,
        setAddWaxupOpen,
        removeWaxup,
        removingWaxup,
        removeWaxupOpen,
        setRemoveWaxupOpen,
    };
}
