/* eslint-disable max-lines */
import {
    uploadFilesCBCTScanFileAtom,
    uploadFilesScansMapAtom,
    uploadFilesUploadedFilesAtom,
} from '../../UploadFiles/atoms/UploadFiles.atoms';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { ScanEntry, ThreeshapeScanName, ScanDefinitionByType, ScanDefinitionType } from '@orthly/forceps';
import { MinimalDcmBufferType, minimalDcmBufferType, ThreeOxzReloader } from '@orthly/forceps';
import type {
    LabsGqlLabOrderFragment,
    LabsGqlPatientScanFileFragment,
    LabsGqlReplaceThreeoxzMutationVariables,
    LabsGqlCancelAndResubmitMutationVariables,
} from '@orthly/graphql-operations';
import { useReplaceThreeoxzMutation, useCancelAndResubmitMutation } from '@orthly/graphql-react';
import { OrderItemV2InputUtils } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';
import {
    useFirebaseStorage,
    getFirebaseDownloadUrl,
    useFirebasePreview,
    OrderEditMode,
    getOrderEditMode,
    ItemEditorV2Utils,
    filterItemsForCancelAndResubmit,
} from '@orthly/veneer';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import JsZip from 'jszip';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const getThreeOxzReloader = async (
    downloadUrl: string | undefined,
    order: LabsGqlLabOrderFragment,
    patientScan?: LabsGqlPatientScanFileFragment,
) => {
    const downloadResult = downloadUrl
        ? await axios.get<ArrayBuffer>(downloadUrl, { responseType: 'arraybuffer' })
        : undefined;
    const buffer = downloadResult?.data;

    return await ThreeOxzReloader.loadThreeoxz(
        {
            order_id: order.id,
            items: OrderItemV2Utils.parseItems(order.items_v2),
            patient: {
                id: order.patient.id,
                first_name: order.patient.first_name,
                last_name: order.patient.last_name,
                birthday: order.patient.birthday,
            },
            scanner_id: patientScan?.scanner_id ?? '',
            doctor_preferences_id: order.doctor_preferences_id,
        },
        buffer,
    );
};

export const useRepackageScansAction = (
    order: LabsGqlLabOrderFragment,
    scans: ScanEntry[],
    setLoading: (loading: boolean) => void,
    setLoaderText: (text: string) => void,
    onSuccessCallback: (orderId: string) => void,
    onErrorCallback?: () => void,
) => {
    const firebase = useFirebaseStorage();
    const firebasePreview = useFirebasePreview(order.scan_export.file_url ?? undefined);

    const [submitReplaceThreeoxzMtn] = useReplaceThreeoxzMutation();
    const replaceThreeoxzMtnSubmitter = (data: LabsGqlReplaceThreeoxzMutationVariables['data']) =>
        submitReplaceThreeoxzMtn({ variables: { data } });
    const { submit: submitReplaceScans } = useChangeSubmissionFn<
        any,
        [LabsGqlReplaceThreeoxzMutationVariables['data']]
    >(replaceThreeoxzMtnSubmitter, {
        closeOnComplete: true,
        onSuccess: data => onSuccessCallback(data?.data?.replaceThreeoxz?.id ?? ''),
        onError: onErrorCallback,
    });

    const [submitCancelAndResubmitMtn] = useCancelAndResubmitMutation();
    const cancelAndResubmitMtnSubmitter = (data: LabsGqlCancelAndResubmitMutationVariables['data']) =>
        submitCancelAndResubmitMtn({ variables: { data } });
    const { submit: submitCancelAndResubmit } = useChangeSubmissionFn<
        any,
        [LabsGqlCancelAndResubmitMutationVariables['data']]
    >(cancelAndResubmitMtnSubmitter, {
        closeOnComplete: true,
    });

    const { editMode } = getOrderEditMode({ order, newScan: true });

    return React.useCallback(async () => {
        setLoaderText('One moment while we upload your scans. This may take a minute...');
        setLoading(true);
        let orderId = order.id;

        try {
            const downloadUrl = order.scan_export.sanitized_url
                ? await getFirebaseDownloadUrl(firebase, order.scan_export.sanitized_url)
                : undefined;
            const reloader = await getThreeOxzReloader(downloadUrl, order);
            const regenerated = await reloader.regenerateWithScans(scans);
            const regeneratedBlob = await regenerated.generateAsync({ type: 'blob' });
            const storagePath = `scans/${order.id}/${order.order_number}-${moment().format('MMDDYYYY_hhmmss')}.3oxz`;
            const storageRef = firebase.ref(storagePath);
            const output = await storageRef.put(regeneratedBlob);

            if (editMode === OrderEditMode.CancelAndResubmit) {
                setLoaderText('Submitting your order...');
                const unfilteredItems = OrderItemV2Utils.parseItems(order.items_v2);
                const orderItems = unfilteredItems.filter(filterItemsForCancelAndResubmit);
                const items_v2_by_sku =
                    OrderItemV2InputUtils.getOrderItemV2InputBySKU(orderItems.map(ItemEditorV2Utils.cleanItem)) ?? {};
                const result = await submitCancelAndResubmit({
                    order_id: order.id,
                    items_v2_by_sku,
                    updates: [],
                });
                const newOrderId = result?.data?.cancelAndResubmit?.replaced_by_ids?.[0];
                if (newOrderId) {
                    orderId = newOrderId;
                }
            }

            await submitReplaceScans({
                order_id: orderId,
                new_3oxz_gcs_path: output.metadata.fullPath,
                old_3oxz_preview_link: firebasePreview?.result ?? null,
                send_email: editMode !== OrderEditMode.CancelAndResubmit,
            });
        } catch (err) {
            console.error(err);
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Upload Scans - Error Encountered When Attempting to Upload Scans',
                {
                    $groups: { order: orderId },
                },
            );
            Sentry.captureException(err);
        } finally {
            setLoaderText('');
            setLoading(false);
        }
    }, [
        firebase,
        order,
        scans,
        setLoading,
        setLoaderText,
        submitReplaceScans,
        firebasePreview?.result,
        editMode,
        submitCancelAndResubmit,
    ]);
};

export const useSelectScanAction = (
    order: LabsGqlLabOrderFragment,
    setSelectedScan: (scan: LabsGqlPatientScanFileFragment) => void,
    setLoading: (loading: boolean) => void,
    setScans: (scans: ScanEntry[]) => void,
    onSelectScanAction?: () => void,
) => {
    const firebase = useFirebaseStorage();

    return React.useCallback(
        async (selectedScan: LabsGqlPatientScanFileFragment) => {
            if (!selectedScan.file_url) {
                return;
            }

            setLoading(true);

            try {
                const downloadUrl = await getFirebaseDownloadUrl(firebase, selectedScan.file_url);
                const reloader = await getThreeOxzReloader(downloadUrl, order, selectedScan);
                setScans(reloader.scans);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
                setSelectedScan(selectedScan);
                onSelectScanAction?.();
            }
        },
        [firebase, order, onSelectScanAction, setScans, setSelectedScan, setLoading],
    );
};

export enum ScanSource {
    ThreeOxz = 'ThreeOxz',
    Zip = 'Zip',
    SingleScan = 'SingleScan',
}

export type ScanUpload =
    | {
          source: ScanSource.ThreeOxz;
          scans: MinimalScanEntry[];
      }
    | {
          source: ScanSource.Zip;
          scans: MinimalScanEntry[];
      }
    | {
          source: ScanSource.SingleScan;
          scan: MinimalScanEntry;
      };

export type CBCTScanUpload = {
    path: string;
    buffer: ArrayBuffer | Buffer;
};

const scanTypes = ['stl', 'ply', 'dcm'] as const;
type ScanType = (typeof scanTypes)[number];

export interface MinimalScanEntry<T extends ScanDefinitionType = ScanDefinitionType> {
    scan_type: ThreeshapeScanName | null;
    file_name: string | null;
    file_type: ScanType;
    buffer: ArrayBuffer | null;
    definition?: ScanDefinitionByType<T> | undefined;
}

const getReloader = async (order: LabsGqlLabOrderFragment, buffer: ArrayBuffer) => {
    return await ThreeOxzReloader.loadThreeoxz(
        {
            order_id: order.id,
            items: [],
            scanner_id: '',
            doctor_preferences_id: order.doctor_preferences_id,
            patient: { ...order.patient },
        },
        buffer,
    );
};

const getScanFileNameComponents = (fileName: string) => {
    // Split the file name and extension into separate strings.
    const fileNameComponents = fileName.toLowerCase().split('.');
    // Remove the extension from the components.
    const extension = fileNameComponents.pop();
    // Rejoin the components to get the file name without the extension.
    const name = fileNameComponents.join('.');
    return { extension, name };
};

const replaceSingleScan = (
    newScans: Map<string, any>,
    existingScanKey: string,
    newScanKey: string,
    scanEntry: IncompleteScanEntry,
) => {
    newScans.delete(existingScanKey);
    newScans.set(newScanKey, { source: ScanSource.SingleScan, scan: scanEntry });
};

/*
 * Determines whether a new scan should replace an existing scan, or be added to the end of the list.
 * Note: Exported for testing. Not intended to be used outside of this file.
 */
export const dedupSingleScan = async (
    fileName: string,
    buffer: ArrayBuffer,
    newScans: Map<string, any>,
    onRemoveAction: () => Promise<void>,
) => {
    let foundMatch = false;

    const scanEntry = {
        scan_type: null,
        file_name: fileName,
        file_type: fileName.split('.').pop(),
        buffer,
    };

    [...newScans.keys()].forEach(existingScanKey => {
        const lowerCasedFileName = fileName.toLowerCase();

        const { extension: existingScanFileExtension, name: existingScanFileName } =
            getScanFileNameComponents(existingScanKey);

        const { extension: currentScanFileExtension, name: currentScanFileName } =
            getScanFileNameComponents(lowerCasedFileName);

        if (currentScanFileName !== existingScanFileName) {
            // The scans have different names, so we'll do nothing.
            return;
        }

        // We've found a match. So either the existing scan should be replaced, or we'll add the new scan at the end.
        foundMatch = true;

        if (currentScanFileExtension === 'dcm') {
            // Since DCMs are the best quality, we'll always replace the existing scan.
            return replaceSingleScan(newScans, existingScanKey, fileName, scanEntry);
        }

        if (currentScanFileExtension === 'ply' && existingScanFileExtension !== 'dcm') {
            // Replace the existing scan (PLY or STL) with the higher quality one (PLY).
            return replaceSingleScan(newScans, existingScanKey, fileName, scanEntry);
        }
    });

    if (!foundMatch) {
        // If we didn't find a match, add the new scan.
        newScans.set(fileName, { source: ScanSource.SingleScan, scan: scanEntry });
    }

    // Remove any scans that are no longer in the new scans map.
    if (!newScans.has(fileName)) {
        await onRemoveAction();
    }
};

interface IncompleteScanEntry {
    file_name: string;
    scan_type: null;
    file_type: string | undefined;
    buffer: ArrayBuffer | Buffer;
}

/*
 * Determines which scans should be removed, if any, when uploading a zip file.
 * Note: Exported for testing. Not intended to be used outside of this file.
 */
export const dedupedZippedScans = (scanEntries: (IncompleteScanEntry | undefined)[]) => {
    const compactScanEntries = _.compact(scanEntries);

    const groupedScanEntries = _.groupBy(compactScanEntries, scanEntry => {
        const { name: currentScanFileName } = getScanFileNameComponents(scanEntry.file_name);
        return currentScanFileName;
    });

    return Object.values(groupedScanEntries).reduce((accum, group) => {
        if (group.length === 1) {
            return accum.concat(group);
        }

        const winner = group.reduce((acc, scanEntry) => {
            const lowerCasedScanKey = scanEntry.file_name.toLowerCase();
            const { extension: scanFileExtension } = getScanFileNameComponents(scanEntry.file_name);
            const { extension: accFileExtension } = getScanFileNameComponents(acc.file_name);

            if (lowerCasedScanKey === acc.file_name.toLowerCase()) {
                // The scans have the same name and extension, so we'll do nothing.
                return acc;
            }

            if (scanFileExtension === 'dcm') {
                // Since DCMs are the best quality, we'll return the existing scan.
                return scanEntry;
            }

            if (scanFileExtension === 'ply' && accFileExtension !== 'dcm') {
                // Return the higher quality scan (PLY).
                return scanEntry;
            }

            return acc;
        });

        return accum.concat(winner);
    }, [] as IncompleteScanEntry[]);
};

export const useGetScansFromFilesAction = (
    order: LabsGqlLabOrderFragment,
    setLoading: (value: boolean) => void,
    setHasUploadedMultipleThreeOxzs: (value: boolean) => void,
    onRemoveFile: (filename: string) => Promise<void>,
    onCompleted?: (didFindScans: boolean) => void,
) => {
    const firebase = useFirebaseStorage();
    const setScansMap = useSetAtom(uploadFilesScansMapAtom);
    const [files] = useAtom(uploadFilesUploadedFilesAtom);
    const setCBCTScan = useSetAtom(uploadFilesCBCTScanFileAtom);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    return React.useCallback(async () => {
        setLoading(true);
        setHasUploadedMultipleThreeOxzs(false);
        const newScans: Map<string, any> = new Map();
        let hasUploadedCBCTScan = false;
        let hasUploadedThreeOxz = false;

        await Promise.all(
            files.map(async file => {
                const downloadUrl = await getFirebaseDownloadUrl(firebase, file.filepath ?? '');
                const downloadResult = await axios.get<ArrayBuffer>(downloadUrl, {
                    responseType: 'arraybuffer',
                });
                const buffer = downloadResult?.data;
                const lowerCasedFileName = file.file.name.toLowerCase();

                if (lowerCasedFileName.endsWith('.3oxz') || lowerCasedFileName.endsWith('.zip')) {
                    const reloader = await getReloader(order, buffer);

                    if (lowerCasedFileName.endsWith('.3oxz') || reloader.scans.length > 0) {
                        // Either the uploaded file is a ThreeOxz file, or the ThreeOxz reloader picked up some scans
                        // from the zip file, so we'll treat it as a ThreeOxz.
                        newScans.set(file.file.name, {
                            source: ScanSource.ThreeOxz,
                            scans: reloader.scans.map(scan => ({
                                ...scan,
                                file_type: 'dcm',
                                definition: scan.definition,
                            })),
                        });
                        if (hasUploadedThreeOxz) {
                            setHasUploadedMultipleThreeOxzs(true);
                        }
                        hasUploadedThreeOxz = true;
                        return;
                    }

                    // The ThreeOxz reloader did not pick up any scans from the zip file, so we need to parse it ourselves.
                    const zip = await JsZip.loadAsync(buffer);
                    const scanEntries = await Promise.all(
                        Object.keys(zip.files).map(async relativePath => {
                            const lowerCasedRelativePath = relativePath.toLowerCase();
                            if (scanTypes.find(type => lowerCasedRelativePath.endsWith(`.${type}`))) {
                                const fileBuffer = await zip
                                    .file(relativePath)
                                    ?.async(lowerCasedRelativePath.endsWith('.dcm') ? 'nodebuffer' : 'arraybuffer');
                                if (!fileBuffer) {
                                    return undefined;
                                }

                                if (lowerCasedRelativePath.endsWith('.dcm')) {
                                    const dcmBufferType = minimalDcmBufferType(fileBuffer);

                                    if (!hasUploadedCBCTScan && dcmBufferType === MinimalDcmBufferType.DICOM) {
                                        setCBCTScan({ path: relativePath, buffer: fileBuffer });
                                        hasUploadedCBCTScan = true;
                                    }

                                    if (dcmBufferType !== MinimalDcmBufferType.DCM) {
                                        return undefined;
                                    }
                                }

                                return {
                                    file_name: relativePath,
                                    scan_type: null,
                                    file_type: relativePath.split('.').pop(),
                                    buffer: fileBuffer,
                                };
                            }
                        }),
                    );

                    newScans.set(file.file.name, {
                        source: ScanSource.Zip,
                        scans: dedupedZippedScans(scanEntries),
                    });
                }

                if (scanTypes.find(type => lowerCasedFileName.endsWith(`.${type}`))) {
                    if (lowerCasedFileName.endsWith('.dcm')) {
                        const dcmBufferType = minimalDcmBufferType(buffer);

                        if (!hasUploadedCBCTScan && dcmBufferType === MinimalDcmBufferType.DICOM) {
                            setCBCTScan({ path: file.file.name, buffer });
                            hasUploadedCBCTScan = true;
                        }

                        if (dcmBufferType !== MinimalDcmBufferType.DCM) {
                            return;
                        }
                    }

                    await dedupSingleScan(
                        file.file.name,
                        buffer,
                        newScans,
                        async () => await onRemoveFile(file.uploadName),
                    );
                }
            }),
        );

        onCompleted?.(newScans.size > 0);
        setScansMap(newScans);
        setLoading(false);
    }, [
        firebase,
        files,
        order,
        setScansMap,
        setCBCTScan,
        onCompleted,
        setLoading,
        setHasUploadedMultipleThreeOxzs,
        onRemoveFile,
    ]);
};
