import { useCSTAction, useOnCSTOpen } from '../../../../chat/cst-state/cst.actions';
import { useAddNewScans } from '../../../actions/practitioners/useAddNewScans';
import { getAskDoctorV2Configuration } from '../AskDoctorResponse/utils.graphql';
import { OrderDetailResolveIssueButton } from './OrderDetailResolveIssueButton';
import { useOrderDetailsContext } from './OrderDetailTracking.context.graphql';
import { OrderDetailHoldMessage } from './TrackingDetailsUtils/OrderDetailHoldMessage';
import { useBillingIssueText } from './TrackingDetailsUtils/useBillingIssueText';
import { TextBubble } from '@orthly/dentin';
import { useMarkFulfillmentDeliveredMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { MiniWindowVariant, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Tooltip, Button, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const Layout = styled('div')({
    display: 'grid',
    gap: 8,
    width: '100%',
    '& > :first-child': {
        marginBottom: 4,
    },
});

const TitleRow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 8,
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '&>button': {
        flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const useOpenCSTAction = () => {
    const setCSTVariant = useCSTAction('SET_WINDOW_VARIANT');

    return useOnCSTOpen(() => {
        setCSTVariant(MiniWindowVariant.Maximized, 'move');
    }, 'practice_order_detail_tracking');
};

export const OrderDetailTrackingTitle: React.VFC = () => {
    const openCSTAction = useOpenCSTAction();
    const {
        title,
        titleDate,
        titleSuffix,
        titleButton,
        subtitle,
        order,
        refetch,
        needsAdditionalScans,
        activeScanReviewFragment,
    } = useOrderDetailsContext();

    const { notesForDoctor } = getAskDoctorV2Configuration(order, activeScanReviewFragment ?? null);
    const billingIssueText = useBillingIssueText();
    const { invokeAttachScans, canAddNewScans } = useAddNewScans(order);

    const [submitMtn] = useMarkFulfillmentDeliveredMutation({ variables: { data: { orderId: order.id } } });
    const { submit, submitting } = useChangeSubmissionFn<any, any>(() => submitMtn(), {
        closeOnComplete: true,
        onSuccess: refetch,
    });

    const isOnHold = order.status === LabsGqlLabOrderStatus.OnHold;
    const isHoldInReview = order.hold_in_review;

    return (
        <Layout>
            <TitleRow>
                <Text variant={'h5'} data-test={'tracking-title'}>
                    {title}
                    {titleDate && <span style={{ color: FlossPalette.SECONDARY_FOREGROUND }}>{titleDate}</span>}
                    {titleSuffix}
                </Text>
                {titleButton}
            </TitleRow>
            {subtitle}

            {needsAdditionalScans && (
                <span>
                    {canAddNewScans ? (
                        <Button variant={'primary'} onClick={() => invokeAttachScans({ id: order.id })}>
                            Capture new scans
                        </Button>
                    ) : (
                        <Tooltip title={'Access Chairside to capture new scans'} arrow>
                            <span>
                                <Button disabled variant={'primary'}>
                                    Capture new scans
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </span>
            )}

            <OrderDetailHoldMessage />

            {order.status === LabsGqlLabOrderStatus.Cancelled && (
                <span style={{ color: FlossPalette.GRAY }}>
                    <Button
                        variant={'ghost'}
                        onClick={openCSTAction}
                        style={{ margin: '-2px 0.5ch 0 0', padding: 0, height: 'unset' }}
                    >
                        Chat with us
                    </Button>
                    or call us at 267-310-3332
                </span>
            )}

            <OrderDetailResolveIssueButton />

            {order.can_mark_delivered && (
                <span>
                    <Button variant={'secondary'} disabled={submitting} onClick={submit}>
                        Mark as delivered
                    </Button>
                </span>
            )}

            {isOnHold && !isHoldInReview && <TextBubble linkify text={notesForDoctor ?? billingIssueText} />}
        </Layout>
    );
};
