import { AnalyticsClient } from '../../../analytics/analyticsClient';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { ExtraCartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Menu, MenuItem, Button } from '@orthly/ui-primitives';
import { useIsDesignActionEditable, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

type OrderFields = 'fulfillment_workflow' | 'status' | 'can_edit' | 'items_v2' | 'waxup_status' | 'id';

type Props = {
    order: Pick<LabsGqlOrder, OrderFields>;
    setEditOpen: (isOpen: boolean) => void;
    setAddDDPOpen: (isOpen: boolean) => void;
    setRemoveDDPOpen: (isOpen: boolean) => void;
};

export const EditOrderAction: React.VFC<Props> = props => {
    const { order, setEditOpen, setAddDDPOpen, setRemoveDDPOpen } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    const { value: enableFlexiPartialDesignPreview } = useFeatureFlag('enableFlexiPartialDesignPreview');
    const isDesignEditable = useIsDesignActionEditable(order);
    const itemsEligibleForWaxup = ExtraCartItemV2Utils.shouldEnableWaxupOption(
        OrderItemV2Utils.parseItems(order.items_v2),
        enableFlexiPartialDesignPreview,
    );
    const disableDDP = !(isDesignEditable && itemsEligibleForWaxup);

    function closeLogAndSetTrue(fn: (value: boolean) => void, action: 'add' | 'remove') {
        return () => {
            AnalyticsClient.track('Practice - DDP Request Dialog Opened', {
                $groups: { order: order.id },
                action: action,
            });
            setAnchorEl(null);
            fn(true);
        };
    }

    let ddpRow = (
        <MenuItem disabled={disableDDP} onClick={closeLogAndSetTrue(setAddDDPOpen, 'add')}>
            Add Digital Design Preview
        </MenuItem>
    );
    if (order.waxup_status) {
        ddpRow = (
            <MenuItem disabled={disableDDP} onClick={closeLogAndSetTrue(setRemoveDDPOpen, 'remove')}>
                Cancel Digital Design Preview
            </MenuItem>
        );
    }

    return (
        <>
            <Button
                ref={buttonRef}
                variant={'secondary'}
                endIcon={'ChevronDown'}
                startIcon={'Edit'}
                fullWidth
                leftAlign
                onClick={openMenu}
                data-tracker-id={'open-modify-rx-menu-tracker-id'}
            >
                Modify RX & instructions
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 40,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: buttonRef.current ? buttonRef.current.offsetWidth : undefined,
                    },
                }}
            >
                <MenuItem onClick={closeLogAndSetTrue(setEditOpen, 'remove')} disabled={!order.can_edit}>
                    Modify RX & instructions
                </MenuItem>
                {ddpRow}
            </Menu>
        </>
    );
};
