/*
 * This is a lighter weight and more streamlined alternative to OrderDesignPreview
 * Advantages:
 *   1.  It lacks the tremendous amount of feature flags, configurations etc that have bloated
 *       OrderDesignPreview component as new features and use cases have evolved.
 *   2.  This strictly uses NewModelViewer
 *   3.  We have tighter control and more flexibility at the model_payloads_level to...
 *           a)  customize what we load for performance
 *           b)  change what we load based on case type
 *           c)  extend in other ways without affecting QC use cases
 *   4.  It's way more readable
 *
 * Disadvantages:
 *   1.  We have duplicated some code.
 * */
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { OrderDesignPreviewPlaceholder } from '../Waxups/OrderDesignPreviewPlaceholder';
import type { ModelMetadataItem } from './OrderDesignPreview.hooks.graphql';
import { useDynamicShadersForHeatmaps, usePollForDesigns } from './OrderDesignPreview.hooks.graphql';
import { useProcessDesignData } from './useProcessDesignData';
import type { DesignCaseParserOrder, DesignQcViewerProps, ModelPayloadItem } from '@orthly/dentin';
import { getPreferredJawScan, isCadItem, Jaw, NewModelViewer, orderMaterialCanHaveLayers } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import { showMetadataTransformWarning } from '@orthly/shared-types';
import { ActionCard, LoadBlocker } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

/** Filters items down to only those needed for digital previews. */
function filterModelItems(items: ModelMetadataItem[]): ModelMetadataItem[] {
    const upperJawScan = getPreferredJawScan(items, Jaw.Upper);
    const lowerJawScan = getPreferredJawScan(items, Jaw.Lower);
    const preExtractionScans = items.filter(
        item => item !== upperJawScan && item !== lowerJawScan && item.name.includes('Pre Extraction Scan'),
    );
    const cadAndRestorativeItems = items.filter(item => {
        return isCadItem(item) || item.isRestorative;
    });
    return _.compact([upperJawScan, lowerJawScan, ...preExtractionScans, ...cadAndRestorativeItems]);
}

export interface DesignViewerLiteProps {
    order: DesignCaseParserOrder;
    refetch?: () => Promise<unknown> | void;
    selectedDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    comparisonDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    fullScreen?: boolean;
    designQcConfig: DesignQcViewerProps;
    externalTools?: React.ReactNode;
    externalToolsLower?: React.ReactNode;
    isMobile?: boolean;
    desiredComparisonDesignFiles: string[];
    setDesiredComparisonDesignFiles: React.Dispatch<React.SetStateAction<string[]>>;
    setMaxMarginDistance?: React.Dispatch<React.SetStateAction<number | undefined>>;
    hideOverlayTools?: boolean;
    camConfig?: {
        zoom?: number;
        position?: THREE.Vector3;
    };
    isImmediateDenture?: boolean;
    isTraining?: boolean;
    /**
     * When set to true, returns the regular loading variant of the modal rather than the error variant,
     * despite no payloads existing.
     */
    isFragmentLoading?: boolean;
}

export const DesignViewerLite: React.FC<DesignViewerLiteProps> = props => {
    const {
        selectedDesignFragment,
        order,
        designQcConfig,
        externalTools,
        externalToolsLower,
        isMobile,
        comparisonDesignFragment,
        desiredComparisonDesignFiles,
        setDesiredComparisonDesignFiles,
        setMaxMarginDistance,
        hideOverlayTools,
        camConfig,
        isImmediateDenture,
        isTraining = false,
        isFragmentLoading,
    } = props;
    const { value: enable3ShapeViewerControls } = useFeatureFlag('enable3ShapeViewerControls');
    const { value: enableMinimum3DSystemRequirements } = useFeatureFlag('enableMinimum3DSystemRequirements');
    const { value: enableTubeMarginLine } = useFeatureFlag('enableTubeMarginLine');

    const selectedDesign = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment);
    const comparisonDesign = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, comparisonDesignFragment);

    const { retriggerAndPoll, pollingDesigns } = usePollForDesigns({
        refetch: props.refetch,
        assets: selectedDesign?.assets ?? [],
    });

    const {
        modellingTreePayload,
        payloads: { result: maybeDesignPayloads, loading: designPayloadsLoading },
        designMetadata,
    } = useProcessDesignData(selectedDesign, order, { filterAndMap: filterModelItems, isImmediateDenture });
    const designPayloads = React.useMemo(() => maybeDesignPayloads ?? [], [maybeDesignPayloads]);

    const {
        modellingTreePayload: comparisonModellingTreePayload,
        payloads: { result: comparisonDesignPayloads, loading: comparisonDesignPayloadsLoading },
        designMetadata: comparisonDesignMetadata,
    } = useProcessDesignData(comparisonDesign, order, {
        desiredFiles: desiredComparisonDesignFiles,
        isImmediateDenture,
    });

    const showTransformWarning = showMetadataTransformWarning(designMetadata);
    const [warningDismissed, setWarningDismissed] = React.useState(false);

    const allModelPayloadItems = React.useMemo<ModelPayloadItem[]>(() => {
        const comparisonPayloads = comparisonDesignPayloads?.map(item => ({ ...item, isPastDesign: true }));
        return designPayloads.concat(comparisonPayloads ?? []);
    }, [designPayloads, comparisonDesignPayloads]);

    const useShaderHeatmaps = useDynamicShadersForHeatmaps(designPayloads, designQcConfig.setAppearance);

    if (!isFragmentLoading && !designPayloadsLoading && !designPayloads.length) {
        return (
            <Grid container direction={'column'} justifyContent={'space-evenly'} alignItems={'center'}>
                <OrderDesignPreviewPlaceholder
                    text={
                        !!props.refetch
                            ? 'Design currently unavailable, try refreshing design files or check back in a few minutes'
                            : 'Design currently unavailable, check back in a few minutes'
                    }
                    isError={true}
                />
                {/**
                 * A refetch method won't be passed into the entrypoint for the refab annotation modal within chairside,
                 * so we are disabling ability to refresh design files there
                 */}
                {props.refetch && (
                    <LoadBlocker blocking={pollingDesigns} ContainerProps={{ style: { maxWidth: 'min-content' } }}>
                        <Button
                            variant={'secondary'}
                            fullWidth={false}
                            endIcon={'RefreshIcon'}
                            onClick={retriggerAndPoll}
                        >
                            Refresh Design Files
                        </Button>
                    </LoadBlocker>
                )}
            </Grid>
        );
    }

    // We would like to show the pre extraction scans for immediate denture, that's why showOrderScans and showPreExtractionScans are true
    const showOrderScans = isImmediateDenture;
    const showPreExtractionScans = isImmediateDenture;

    return (
        <LoadBlocker
            blocking={isFragmentLoading || designPayloadsLoading || comparisonDesignPayloadsLoading}
            overlayColor={'transparent'}
            ContainerProps={{ style: { height: '100%' } }}
        >
            {showTransformWarning && !warningDismissed && (
                <ActionCard
                    style={{ margin: 10, marginRight: 64 }}
                    variant={'alert'}
                    title={'We ran into potential issues displaying the 3D design'}
                    subtitle={'If the design looks misaligned, please request screenshot in feedback'}
                    secondaryAction={{ onClick: () => setWarningDismissed(true), text: 'Dismiss' }}
                />
            )}
            <div
                data-testid={'waxup-design-viewer-lite'}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <NewModelViewer
                    orderMaterialsHaveLayers={orderMaterialCanHaveLayers(order)}
                    full_screen={true}
                    model_payload_items={allModelPayloadItems}
                    modelling_tree_buffer={modellingTreePayload}
                    design_metadata={designMetadata}
                    enable_qc_tools={{
                        externalTools,
                        externalToolsLower,
                        enableAnatomyLayers: true,
                        enableCollisions: isTraining,
                        enableCrossSections: false,
                        enableDynamicHeatmaps: false,
                        enableHeatmaps: !isMobile,
                        enableUndercutHeatmap: false,
                        enableTissuePressureHeatmap: false,
                        enableMarginLines: true,
                        enableDoctorMarginLines: false,
                        enableDoctorToothMarkings: false,
                        editMarginLines: false,
                        enableUndercutView: false,
                    }}
                    style={{ height: '100%', margin: 0 }}
                    designQcConfig={designQcConfig}
                    enableNewScanMeshMaterial={true}
                    hideUi={true}
                    showOrderScans={showOrderScans}
                    showScansHeatmap={isTraining}
                    showPreExtractionScans={showPreExtractionScans}
                    disableHotKeys={!isTraining}
                    setDesiredComparisonDesignFiles={comparisonDesign ? setDesiredComparisonDesignFiles : undefined}
                    use3ShapeViewerControls={enable3ShapeViewerControls}
                    checkMinimumSystemRequirements={enableMinimum3DSystemRequirements}
                    comparisonModellingTreeBuffer={comparisonModellingTreePayload}
                    comparisonDesignMetadata={comparisonDesignMetadata}
                    setMaxMarginDistance={setMaxMarginDistance}
                    displayLocation={'waxup_viewer'}
                    hideOverlayTools={hideOverlayTools}
                    camConfig={camConfig}
                    useShaderHeatmaps={useShaderHeatmaps}
                    enableTubeMarginLine={enableTubeMarginLine}
                    enablePreferredScansIfNoDesigns={isTraining}
                />
            </div>
        </LoadBlocker>
    );
};
