import { useInvoicesTableContext } from '../../components/providers/InvoicesTableProvider.graphql';
import { ProcessingFeeTooltip } from '../../invoice-detail/ProcessingFeeTooltip';
import { TextWithIconContainer } from '../../invoice-detail/components/InvoiceDetailBody';
import type { PracticeInvoice } from '../../invoicing.types';
import { getLabOrderCount } from '../../invoicing.utils';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { Box, styled, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import { compact, groupBy, sumBy } from 'lodash';
import React from 'react';

interface PaymentInvoiceDetailsProps {
    invoiceDetailId?: string;
    invoiceIds?: string[];
    paymentMethodIsCreditCard: boolean;
    willBeChargedCCFee?: boolean;
}

const GetCreditCardProcessingFeeAmountCentsForInvoiceIds_Query = graphql(`
    query GetCreditCardProcessingFeeAmountCentsForInvoiceIds($invoiceIds: [String!]!) {
        ccFees: getCreditCardProcessingFeeAmountCentsForInvoiceIds(invoice_ids: $invoiceIds) {
            amount_cents
            invoice_id
        }
    }
`);

const CalculateCreditCardProcessingFeeForInvoiceAmounts_Query = graphql(`
    query CalculateCreditCardProcessingFeeForInvoiceAmounts($data: [CreditCardFeePerInvoiceInput!]!) {
        calculatedCcFees: calculateCreditCardProcessingFeeForInvoiceAmounts(data: $data) {
            amount_cents
            invoice_id
        }
    }
`);

const StyledText = styled(Text)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '50%',
    },
}));

const DetailsRow: React.FC<{ label: React.ReactNode; value: string }> = ({ label, value }) => {
    const labelText =
        typeof label === 'string' ? (
            <StyledText variant={'body2'} color={'GRAY'}>
                {label}
            </StyledText>
        ) : (
            label
        );

    return (
        <Box sx={{ display: 'flex' }}>
            {labelText}
            <StyledText variant={'body2'} color={'GRAY'} sx={{ marginLeft: 'auto', textAlign: 'right' }}>
                {value}
            </StyledText>
        </Box>
    );
};

const DetailsAmountDue: React.FC<{ amountDue: number }> = ({ amountDue }) => (
    <Box sx={{ display: 'flex', paddingTop: '8px' }}>
        <Text variant={'body1'} medium>
            Total Amount
        </Text>
        <Text variant={'body1'} sx={{ marginLeft: 'auto' }} medium>
            {Format.currency(amountDue)}
        </Text>
    </Box>
);

const getTextAndTrailingText = (entries: string[]): string => {
    const maxEntries = 2;
    if (entries.length <= maxEntries) {
        return entries.join(', ');
    }

    const extraCount = entries.length - maxEntries;
    return `${entries.slice(0, maxEntries).join(', ')} + ${Format.pluralize('other', extraCount)}`;
};

const getInvoiceTotalAmountDue = (invoice: PracticeInvoice): number => {
    const paidAndPendingSum = invoice.amount_paid + invoice.pending_payment_amount_cents;
    return invoice.amount_due - paidAndPendingSum;
};

export const PaymentInvoiceDetails: React.VFC<PaymentInvoiceDetailsProps> = ({
    invoiceDetailId,
    invoiceIds,
    paymentMethodIsCreditCard,
    willBeChargedCCFee,
}) => {
    const { findInvoiceById, payableSelectedInvoices } = useInvoicesTableContext();

    // If there is no specific invoice, then we've entered this modal through a multilocation primary selecting multiple invoices
    // on the Invoices table. Otherwise we'll have one specific invoice to work with
    let invoices: PracticeInvoice[];
    if (invoiceDetailId) {
        invoices = compact([findInvoiceById(invoiceDetailId)]);
    } else if (invoiceIds) {
        invoices = compact(invoiceIds.map(id => findInvoiceById(id)));
    } else {
        invoices = payableSelectedInvoices;
    }

    const invoiceDetail = invoiceDetailId ? invoices[0] : undefined;

    const totalAmountDue = sumBy(invoices, getInvoiceTotalAmountDue);

    const { data: { ccFees = [] } = {} } = useQuery(GetCreditCardProcessingFeeAmountCentsForInvoiceIds_Query, {
        variables: {
            invoiceIds: invoices.map(i => i.id),
        },
    });

    const existingCcFeesMap = ccFees?.reduce<Record<string, number>>(
        (acc, { invoice_id, amount_cents }) => ({
            ...acc,
            [invoice_id]: amount_cents ?? 0,
        }),
        {},
    );

    // sum of the existing processing fees in the invoices
    const existingCcFeeTotal = sumBy(invoices, i => existingCcFeesMap[i.id] ?? 0);

    const labOrdersCount = getLabOrderCount(invoiceDetail, invoices);
    const orderCountText = labOrdersCount ? Format.pluralize('Order', labOrdersCount) : '-';
    const paymentDueDate = dayjs.utc(invoiceDetail?.due_date).format('MMMM DD, YYYY');

    /**
     * If the payment method is a credit card and there is already a processing fee in the invoice,
     * then the subtotal is calculated by subtracting the processing fee from the current invoice total amount due.
     * If there is no processing fee, then the subtotal is just the current invoice total amount due
     */
    const subtotal = totalAmountDue - existingCcFeeTotal;

    const { data: { calculatedCcFees = [] } = {} } = useQuery(CalculateCreditCardProcessingFeeForInvoiceAmounts_Query, {
        variables: {
            data: invoices.map(i => ({
                invoice_id: i.id,
                amount_cents: getInvoiceTotalAmountDue(i),
            })),
        },
    });

    // the invoices might not yet have cc fees - if not, calculate the fee total
    const calculatedCreditCardProcessingFeeTotal = sumBy(calculatedCcFees, 'amount_cents');

    /**
     * If the payment method is a credit card and there is already a processing fee in the invoice,
     * then the processing fee is already available for display. If there is no processing fee,
     * then we need to get the calculated value from the server
     */
    const processingFee = !!existingCcFeeTotal ? existingCcFeeTotal : calculatedCreditCardProcessingFeeTotal;
    /**
     * If the payment method is a credit card and there is already a processing fee in the invoice,
     * then the total amount due is just the current invoice amount due. If there is no processing fee,
     * then we need to get the expected total by adding the calculated value from the server to the current
     * invoice total amount due
     */
    const totalAmountDueWithCreditCardPayment = !!existingCcFeeTotal
        ? totalAmountDue
        : totalAmountDue + calculatedCreditCardProcessingFeeTotal;
    /**
     * If the payment method is NOT a credit card and there is already a processing fee in the invoice,
     * then we need to subtract processing fee from the current invoicetotal amount due. If there is no processing fee,
     * then we already have the expected current invoice total amount due
     */
    const totalAmountDueWithoutCreditCardPayment = !!existingCcFeeTotal
        ? totalAmountDue - existingCcFeeTotal
        : totalAmountDue;

    // If there is no specific invoice, then we've entered this modal through a multilocation primary selecting multiple invoices
    // on the Invoices table. Otherwise we'll have one specific invoice to work with
    const invoiceNumbers = invoices.map(i => i.invoice_number ?? '');
    const invoiceDates = Object.keys(groupBy(invoices, i => i.month_formatted)).sort((a, b) => b.localeCompare(a));
    const dueDates = Object.keys(groupBy(invoices, i => i.due_date))
        .map(d => dayjs(d).format('MMM DD, YYYY'))
        .sort((a, b) => b.localeCompare(a));

    return (
        <Box sx={{ marginTop: '12px' }}>
            {invoiceDetail ? (
                <>
                    <DetailsRow label={'Invoice Number'} value={`#${invoiceDetail.invoice_number}`} />
                    <DetailsRow label={'Date'} value={invoiceDetail.month_formatted} />
                    <DetailsRow label={'Payment Due'} value={paymentDueDate} />
                    <DetailsRow label={'Total Orders'} value={orderCountText} />
                </>
            ) : (
                <>
                    <DetailsRow label={'Invoices'} value={getTextAndTrailingText(invoiceNumbers)} />
                    <DetailsRow label={'Date'} value={getTextAndTrailingText(invoiceDates)} />
                    <DetailsRow label={'Payment Due'} value={getTextAndTrailingText(dueDates)} />
                    <DetailsRow label={'Total Orders'} value={orderCountText} />
                </>
            )}

            {paymentMethodIsCreditCard && willBeChargedCCFee ? (
                <>
                    <DetailsRow label={'Order Subtotal'} value={`${Format.currency(subtotal)}`} />
                    <DetailsRow
                        label={
                            <TextWithIconContainer sx={{ width: '50%' }}>
                                <Text variant={'body2'} color={'GRAY'}>
                                    Processing Fee
                                </Text>
                                <ProcessingFeeTooltip />
                            </TextWithIconContainer>
                        }
                        value={`${Format.currency(processingFee)} (2.9%)`}
                    />
                    <DetailsAmountDue amountDue={totalAmountDueWithCreditCardPayment} />
                </>
            ) : (
                <DetailsAmountDue amountDue={totalAmountDueWithoutCreditCardPayment} />
            )}
        </Box>
    );
};
