import { useLazyQuery, useQuery } from '@apollo/client';
import type {
    VeneerUseDesignOrderRevisionsIndividual_QueryQuery,
    VeneerUseDesignOrderRevisionsSlimLoader_QueryQuery,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import _ from 'lodash';
import React from 'react';

const VeneerUseDesignOrderRevisionsSlimLoader_Query = graphql(`
    query VeneerUseDesignOrderRevisionsSlimLoader_Query($orderId: String!) {
        getAllDesignOrderDesignRevisions(labOrderId: $orderId) {
            id
            source_file_zip_path
            created_at
            is_latest_design
            design_source
            ...VeneerWaxupUtilDesignOrderRevision_Fragment
            ...VeneerUseOrderRevisionItemsDesign_Fragment
        }
    }
`);

const VeneerUseDesignOrderRevisionsIndividual_Query = graphql(`
    query VeneerUseDesignOrderRevisionsIndividual_Query($revisionId: String!) {
        getDesignOrderDesignRevisionById(revisionId: $revisionId) {
            id
            source_file_zip_path
            created_at
            is_latest_design
            design_source
            ...OrderDesignPreviewDesign_Fragment
            ...VeneerUseOrderRevisionItemsDesign_Fragment
            ...AdminOrderDesignToolbarFileDownloadDesign_Fragment
            ...VeneerOrderDesignAnalyticsDesign_Fragment
            ...VeneerDesignFinishingDesign_Fragment
            ...VeneerDesignEditorDesign_Fragment
        }
    }
`);

export function useDesignOrderRevision(revisionId: string, options?: { skip: boolean }) {
    return useQuery(VeneerUseDesignOrderRevisionsIndividual_Query, {
        variables: { revisionId: revisionId },
        skip: options?.skip || !revisionId,
    });
}

/*
 * Internal utility hook that will handle the design cache for a local order.
 */
function useDesignOrderRevisionsLoaderCache() {
    const [cachedDesigns, setCachedDesigns] = React.useState<
        VeneerUseDesignOrderRevisionsIndividual_QueryQuery['getDesignOrderDesignRevisionById'][]
    >([]);

    const [loadDesignRaw] = useLazyQuery(VeneerUseDesignOrderRevisionsIndividual_Query, {
        onCompleted: data => {
            setCachedDesigns(designs => _.compact([...designs, data?.getDesignOrderDesignRevisionById]));
        },
    });

    const loadDesignIntoCacheRaw = React.useCallback(
        async (
            slimDesigns: VeneerUseDesignOrderRevisionsSlimLoader_QueryQuery['getAllDesignOrderDesignRevisions'],
            id: string,
            evictCache: boolean = false,
        ) => {
            // The selected design did not exist in our records, so don't bother requesting it over the wire.
            if (!slimDesigns.some(design => design.id === id)) {
                return;
            }

            // We've already loaded this design -> no need to load it again.
            if (cachedDesigns.some(design => design?.id === id) && !evictCache) {
                return;
            }

            await loadDesignRaw({
                variables: {
                    revisionId: id,
                },
            });
        },
        [cachedDesigns, loadDesignRaw],
    );

    return {
        cachedDesigns,
        setCachedDesigns,
        loadDesignIntoCacheRaw,
    };
}

/*
 * Utility hook to load designs in a slim, "paginated" way.
 * Performs a cheap network request to get the list of all designs, and then loads the full design only when needed.
 * if `shouldLoadDefaultDesign` is set to true, will attempt to load the most recent design whenever the list of designs is fetched automatically.
 */
export function useDesignOrderRevisionsLoader(
    orderId: string | undefined | null,
    shouldLoadDefaultDesign: boolean = true,
    /**
     * When set to true, will set the comparison design to the previously selected design whenever the user changes
     * the selected design.
     *
     * This is going to be replaced very soon (ie I am working on it now) - this is a simple stopgap to avoid
     * breaking the CAD Design Comparison tool while we're reworking it.
     */
    enableLegacyComparisonLoadingBehavior: boolean = true,
) {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
    const [comparisonIndex, setComparisonIndex] = React.useState<number>(-1);

    const { cachedDesigns, setCachedDesigns, loadDesignIntoCacheRaw } = useDesignOrderRevisionsLoaderCache();

    const { data: slimDesignsData, refetch: refetchSlim } = useQuery(VeneerUseDesignOrderRevisionsSlimLoader_Query, {
        variables: {
            orderId: orderId ?? '',
        },
        skip: !orderId,
        onCompleted: async data => {
            if (!shouldLoadDefaultDesign) {
                return;
            }

            const slimDesigns = data?.getAllDesignOrderDesignRevisions ?? [];
            // If we have to pick a design to load for the user, we will default to the latest design OR the last in the array if there isn't a latest.
            const targetDesign =
                slimDesigns[selectedIndex] ?? slimDesigns.find(design => design.is_latest_design) ?? slimDesigns.at(-1);
            const resolvedId = targetDesign?.id;
            const resolvedIndex = slimDesigns.findIndex(d => d.id === resolvedId) ?? -1;

            setSelectedIndex(resolvedIndex);
            if (resolvedId) {
                // We always force load into the cache because if we've just refetched, we could have stale data around!
                await loadDesignIntoCacheRaw(slimDesigns, resolvedId, true);
            }
        },
    });

    const slimDesigns = slimDesignsData?.getAllDesignOrderDesignRevisions;
    /* Given a id, will load the design into the cache of our designs.
     * This performs a few checks:
     * 1) If the design ID isn't found in the list of active designs, we won't load it.
     * 2) unless evictCache is set to true, we won't load designs that have already been loaded
     */
    const loadDesignIntoCache = React.useCallback(
        async (id: string, evictCache: boolean = false) => loadDesignIntoCacheRaw(slimDesigns ?? [], id, evictCache),
        [loadDesignIntoCacheRaw, slimDesigns],
    );

    /*
     * Loads a design into the cache if needed, and selects it as the active design for the requested queue.
     * If provided an undefined id, will load the last design in the array instead.
     * Ensures that the design exists before making any network calls, see loadDesignIntoCache.
     *
     * When the variant is set to `selected`, loads into `selectedIndex`, otherwise puts into `comparisonIndex`.
     */
    const selectDesignForQueue = React.useCallback(
        async (variant: 'selected' | 'comparison', id?: string, evictCache: boolean = false) => {
            const resolvedId = id ?? _.last(slimDesigns)?.id;
            const slimDesignIdx = slimDesigns?.findIndex(design => design.id === resolvedId);
            // The requested design did not exist in our records, so don't bother requesting it over the wire.
            if (!resolvedId || slimDesignIdx === undefined || slimDesignIdx === -1) {
                return;
            }

            if (variant === 'comparison') {
                setComparisonIndex(slimDesignIdx);
            } else {
                if (enableLegacyComparisonLoadingBehavior) {
                    /**
                     * When we update the primary selected design, we set the comparison index to whatever we currently have selected.
                     * This is a temporary measure to ensure we don't break the existing usages of the comparison feature (eg the Design viewer in Ops portal)
                     * while we're reworking the way the comparison selection works under the hood.
                     *
                     * TODO: update this in the next PR for https://meetdandy.atlassian.net/browse/EPDPOR-2207
                     */
                    setComparisonIndex(selectedIndex);
                }

                setSelectedIndex(slimDesignIdx);
            }

            await loadDesignIntoCache(resolvedId, evictCache);
        },
        [
            slimDesigns,
            setSelectedIndex,
            setComparisonIndex,
            loadDesignIntoCache,
            enableLegacyComparisonLoadingBehavior,
            selectedIndex,
        ],
    );

    const selectDesign = React.useCallback(
        async (id?: string, evictCache: boolean = false) => {
            return selectDesignForQueue('selected', id, evictCache);
        },
        [selectDesignForQueue],
    );

    const selectComparisonDesign = React.useCallback(
        async (id?: string, evictCache: boolean = false) => {
            return selectDesignForQueue('comparison', id, evictCache);
        },
        [selectDesignForQueue],
    );

    /*
     * Refetches the design list, and potentially refetches the selected design.
     * NOTE: by default, will reset the selected/previous indices _and_ evict the cache.
     */
    const refetch = React.useCallback(
        async (resetIndices: boolean = true, evictCache: boolean = true) => {
            if (resetIndices) {
                setSelectedIndex(-1);
                setComparisonIndex(-1);
            }

            if (evictCache) {
                setCachedDesigns([]);
            }

            await refetchSlim();
        },
        [setCachedDesigns, setComparisonIndex, setSelectedIndex, refetchSlim],
    );

    const selectedDesignId = slimDesigns?.[selectedIndex]?.id;
    const comparisonDesignId = slimDesigns?.[comparisonIndex]?.id;
    const { selectedDesignFragment, comparisonDesignFragment } = React.useMemo(() => {
        return {
            selectedDesignFragment: cachedDesigns.find(d => d?.id === selectedDesignId),
            comparisonDesignFragment: cachedDesigns.find(d => d?.id === comparisonDesignId),
        };
    }, [selectedDesignId, comparisonDesignId, cachedDesigns]);

    const loadedDesignsById = React.useMemo(() => {
        return _.keyBy(cachedDesigns, d => d?.id ?? '');
    }, [cachedDesigns]);

    return {
        refetch,
        slimDesigns,
        selectedIndex,
        comparisonIndex,
        selectedDesignFragment,
        comparisonDesignFragment,
        loadedDesignsById,
        loadAndSelectDesign: selectDesign,
        loadAndSelectComparisonDesign: selectComparisonDesign,
        loadDesign: loadDesignIntoCache,
        slimDesignFragments: slimDesignsData?.getAllDesignOrderDesignRevisions,
    };
}
