import { AnalyticsClient } from '../../../analytics/analyticsClient';
import type { PartnerAppState } from '../../../redux';
import type { CSTState } from './cst.reducer';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import { createAsyncAction, createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';
import type { MiniWindowVariant, MiniWindowPendingAnimation } from '@orthly/ui';
import { QueryString } from '@orthly/ui';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const PREFIX = 'cst';

// These are url parameters passed in from the various other applications that embed the practice app chat screen
export interface StandaloneCSTURLParams {
    // this is the source
    platform?: 'trios' | 'chairside';
    group?: string;
    liveScanReviewOrder?: string;
    prefId?: string;
}

function trackStandaloneCSTMountedAnalytics(searchParams: StandaloneCSTURLParams) {
    AnalyticsClient.track('Practice - CST Opened', {
        source: searchParams.platform,
    });
}

export function useOnCSTOpen(
    reduxAction: () => void,
    source?: DandyAnalyticsEventSchemaType['Practice - CST Opened']['source'],
) {
    const history = useHistory();
    const isMobile = useScreenIsMobile();

    return React.useCallback(() => {
        if (isMobile) {
            history.push(`/${PracticeScreen.chat}`);
        }
        reduxAction();
        AnalyticsClient.track('Practice - CST Opened', {
            source,
        });
    }, [isMobile, reduxAction, source, history]);
}

export const CSTActions = {
    SET_WINDOW_VARIANT: createSyncAction<CSTState['windowState'], [MiniWindowVariant, MiniWindowPendingAnimation?]>(
        `${PREFIX}/SET_WINDOW_VARIANT`,
        (variant, pendingAnimation = 'none') => ({
            variant,
            pendingAnimation,
        }),
    ),
    SET_WINDOW_PENDING_ANIMATION: createSyncAction<MiniWindowPendingAnimation>(
        `${PREFIX}/SET_WINDOW_PENDING_ANIMATION`,
    ),
    SET_LIVE_SCAN_REVIEW_ORDER: createSyncAction<string | undefined>(`${PREFIX}/SET_LIVE_SCAN_REVIEW_ORDER`),
    OPEN_CHAIRSIDE: createSyncAction<void>(`${PREFIX}/OPEN_CHAIRSIDE`),
    OPEN_UPLOADER: createSyncAction<void>(`${PREFIX}/OPEN_UPLOADER`),
    ON_STANDALONE_CST_MOUNTED: createAsyncAction<null, PartnerAppState>(
        `${PREFIX}/ON_STANDALONE_CST_MOUNTED`,
        async ({ dispatch, getState }) => {
            const searchParams: StandaloneCSTURLParams = QueryString.parse(getState().router.location?.search ?? '');
            trackStandaloneCSTMountedAnalytics(searchParams);
            if (searchParams.platform === 'chairside') {
                dispatch(CSTActions.OPEN_CHAIRSIDE());
            } else {
                dispatch(CSTActions.OPEN_UPLOADER());
            }
            return { payload: null };
        },
    ),
};

export const useCSTAction = generateUseActionHook(CSTActions);
