import { useInvoicesTableContext } from './components/providers/InvoicesTableProvider.graphql';
import { StatusDescriptionCell } from './invoices-table-components/StatusDescriptionCell';
import type { InvoiceTableRow } from './invoicing.types';
import { getLinkToInvoice, previewUsageInvoiceId } from './invoicing.utils';
import { paymentActionId } from './payments/components/PaymentAction.graphql';
import type { Column } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { CheckboxPrimitive, ChevronRight, Grid, Icon, styled, Text } from '@orthly/ui-primitives';
import type { History } from 'history';
import { throttle } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const AmountDueCell: React.FC<{ invoice: InvoiceTableRow }> = ({ invoice }) => {
    const isPreviewInvoice = invoice.id === previewUsageInvoiceId;
    if (isPreviewInvoice) {
        return (
            <Text variant={'body2'} color={'GRAY'}>
                Not yet final
            </Text>
        );
    }

    return <Text variant={'body2'}>{Format.currency(invoice.amount_due)}</Text>;
};

const DetailsCell: React.FC<{ invoice: InvoiceTableRow }> = ({ invoice }) => {
    const orderCount = invoice.pending_items
        ? invoice.pending_items.filter(i => i.category === 'order_placed').length
        : invoice.items_order_count;

    return orderCount > 0 ? (
        <Grid container alignItems={'center'} style={{ flexWrap: 'nowrap' }}>
            {Format.pluralize('Order', orderCount)}
        </Grid>
    ) : (
        <>-</>
    );
};

const StyledLinkCell = styled('div')({
    padding: '0px 16px',
    width: '56px',
    display: 'flex',
    justifyContent: 'space-around',
    cursor: 'pointer',
});

const LinkCell: React.FC<{ invoice: InvoiceTableRow }> = ({ invoice }) => {
    const history = useHistory();
    if (!!invoice.pending_items) {
        return null;
    }

    return (
        <StyledLinkCell onClick={() => history.push(getLinkToInvoice(invoice))}>
            <ChevronRight />
        </StyledLinkCell>
    );
};

export const StyledCheckBox = styled(CheckboxPrimitive)({
    margin: 0,
    padding: 0,
    width: '16px',
    '& .MuiSvgIcon-root': {
        height: '16px',
        width: '16px',
    },
    '&:hover .MuiSvgIcon-root': {
        fill: 'none !important',
    },
});

const invoiceCheckboxId = 'invoice-checkbox';
export const tableRowIdPrefix = 'invoices-table-row';

export const InvoiceTableColumns = (): Column<InvoiceTableRow>[] => {
    const { autochargeEnabled, selectedInvoiceIds, setSelectedInvoiceIds, associatedPractices } =
        useInvoicesTableContext();
    const getPracticeName = (id: string) => {
        const practice = associatedPractices.find(p => p.id === id);
        return practice?.name ?? '';
    };

    const checkBoxColumn: Column<InvoiceTableRow>[] = [
        {
            name: 'checkbox',
            title: ' ',
            render: row => {
                // the pending invoice is not payable, so it is not checkable
                return row.pending_items ? (
                    <></>
                ) : (
                    <StyledCheckBox
                        id={invoiceCheckboxId}
                        color={'secondary'}
                        size={'small'}
                        checked={!!selectedInvoiceIds.includes(row.id)}
                        icon={<Icon icon={'CheckboxIcon'} fontSize={'small'} />}
                        checkedIcon={<Icon icon={'CheckboxCheckedIcon'} fontSize={'small'} />}
                        onChange={_ => {
                            const isSelected = !!selectedInvoiceIds.includes(row.id);
                            setSelectedInvoiceIds(prev => {
                                if (isSelected) {
                                    return [...prev].filter(id => id !== row.id);
                                } else {
                                    return [...prev, row.id];
                                }
                            });
                        }}
                    />
                );
            },
            sort: false,
            filter: false,
            width: '32px',
            style: { padding: '0px 8px' },
            bodyCellWrapStyle: { padding: '0px 8px' },
        },
    ];

    const locationColumn: Column<InvoiceTableRow>[] = [
        {
            name: 'Location',
            sort: false,
            render: row => (
                <Text variant={'body2'} data-test={'invoice-table-location-name-cell'}>
                    {getPracticeName(row.organization_id)}
                </Text>
            ),
        },
    ];
    return [
        ...checkBoxColumn,
        {
            name: 'Date',
            sort: true,
            customSort: (a, b, direction) => {
                const aMoment = moment(a.period_start);
                const bMoment = moment(b.period_start);
                if (direction === 'asc') {
                    return aMoment.valueOf() - bMoment.valueOf();
                }
                return bMoment.valueOf() - aMoment.valueOf();
            },
            defaultSort: 'desc',
            render: row => (
                <Text color={row.id === previewUsageInvoiceId ? 'GRAY' : 'BLACK'} variant={'body2'} medium>
                    {row.month_formatted}
                </Text>
            ),
            type: 'date',
            field: 'period_start',
        },
        {
            name: 'Details',
            sort: false,
            render: row => <DetailsCell invoice={row} />,
        },
        {
            name: 'Status',
            field: 'status',
            sort: false,
            render: r => <StatusDescriptionCell invoice={r} autochargeEnabled={autochargeEnabled} />,
        },
        ...[],
        {
            name: 'Amount',
            sort: false,
            render: row => <AmountDueCell invoice={row} />,
            field: 'amount_due',
        },
        ...[],
        ...locationColumn,
        {
            name: 'link-to-invoice',
            sort: false,
            title: ' ',
            render: row => <LinkCell invoice={row} />,
            width: '56px',
        },
    ];
};

export const handleRowClick = (
    rowClickRef: React.MutableRefObject<{
        history: History<any>;
    }>,
) => {
    return throttle(
        (e: React.MouseEvent<HTMLElement>, row: InvoiceTableRow) => {
            const paymentActionButton = document.getElementById(paymentActionId);
            const isPaymentActionClick = paymentActionButton?.contains(e.target as Node);
            const isCheckBoxClick = (e.target as Element).getAttribute('id') === invoiceCheckboxId;
            const rowContainer = document.getElementById(`${tableRowIdPrefix}-${row.id}`);
            const isRowClick = rowContainer?.contains(e.target as Node);
            // if the click did not originate from the row, do not redirect.
            // the only child element of the row that *should not* redirect to the details page
            // is the payment action button
            if (isPaymentActionClick || !isRowClick || isCheckBoxClick) {
                return;
            }

            if (!!row.pending_items) {
                return null;
            }

            const { history } = rowClickRef.current;
            history.push(getLinkToInvoice(row));
        },
        500,
        {
            leading: true,
            trailing: false,
        },
    );
};
