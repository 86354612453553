import { useRowsFromInvoiceItems } from '../../invoice-detail/components/InvoiceDetailBody.utils';
import type { InvoiceItemRow } from '../../invoice-detail/components/InvoiceTransactionsTable';
import type { AddressOption } from '../../invoice-detail/invoiceItemFilter';
import { useInvoiceItemFilter } from '../../invoice-detail/invoiceItemFilter';
import type {
    BillingCreditCategories,
    Credit,
    PracticeInvoiceDetail,
    PracticeInvoiceItem,
} from '../../invoicing.types';
import {
    GetBillingContactData_Query,
    ListItemsForInvoice_Query,
    ListAllInvoiceCreditsForOrganization_Query,
    ListAllBillingCreditCategories_Query,
    ListAddressesForContract_Query,
} from './InvoiceDetailProviderQueries.graphql';
import { useInvoicesTableContext } from './InvoicesTableProvider.graphql';
import { useQuery } from '@apollo/client';
import type { LabsGqlDoctorDeliveryAddressFragment as Address, LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrdersByIds } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { usePrintableState } from '@orthly/veneer';
import { compact } from 'lodash';
import React from 'react';

export interface InvoiceDetailContextType {
    invoice: PracticeInvoiceDetail;
    refetchInvoice: () => Promise<unknown>;
    rows: InvoiceItemRow[];
    // we already handle the invoice loading state in `InvoicesRoot`, so we don't need to expose it here
    // but we do load the orders in order to populate the row data, so ensure that loading state is handled
    rowsLoading: boolean;
    autochargeEnabled: boolean;

    /** doctors filters */
    doctorNameFilter?: string;
    setDoctorNameFilter: (f?: string) => void;
    doctorOptions: string[];

    /** address filters */
    addressIdFilter?: string;
    setAddressIdFilter: (f?: string) => void;
    addresses: AddressOption[];
    addressFilterName?: string;

    /** printability */
    printableSlipIsOpen: boolean;
    openPrintableSlip: () => void;
    closePrintableSlip: () => void;
    billingContactInfoLoading: boolean;
    organizationName?: string;
    billingEmail?: string;
}

const InvoiceDetailContext = React.createContext<InvoiceDetailContextType | null>(null);

export const InvoiceDetailCtxProvider: React.FC<{
    invoice: PracticeInvoiceDetail;
    refetchInvoice: () => Promise<unknown>;
    autochargeEnabled: boolean;
}> = ({ invoice, refetchInvoice, autochargeEnabled, children }) => {
    const practiceId = useSession()?.organization_id ?? '';
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();
    const { associatedPractices } = useInvoicesTableContext();

    const { data: { billingEmails } = {}, loading: billingContactDataLoading } = useQuery(GetBillingContactData_Query, {
        variables: { organizationId: invoice.organization_id, invoiceIds: [invoice.id] },
    });

    const { data: { listInvoiceItemsForInvoice: items = [] } = {} } = useQuery<{
        listInvoiceItemsForInvoice: PracticeInvoiceItem[];
    }>(ListItemsForInvoice_Query, {
        variables: { invoiceId: invoice.id },
    });

    const { data: { credits = [] } = {}, loading: creditsLoading } = useQuery<{ credits: Credit[] }>(
        ListAllInvoiceCreditsForOrganization_Query,
        {
            variables: { organizationId: practiceId },
        },
    );

    const { data: { getAllBillingCreditCategories: creditCategories = [] } = {}, loading: creditCategoriesLoading } =
        useQuery<{
            getAllBillingCreditCategories: BillingCreditCategories;
        }>(ListAllBillingCreditCategories_Query, {
            variables: { include_archived: true },
        });

    const orderIds = React.useMemo(() => compact(items.map(i => i.order_id)), [items]);
    const { orders: ordersRaw, loading } = useOrdersByIds(orderIds);
    const orders = React.useMemo<LabsGqlOrder[]>(() => compact(ordersRaw), [ordersRaw]);

    /** filter block */
    const { data: { addresses } = {}, loading: addressesLoading } = useQuery<{ addresses: Address[] }>(
        ListAddressesForContract_Query,
    );

    const [doctorName, setDoctorName] = React.useState<string | undefined>();
    const [addressId, setAddressId] = React.useState<string | undefined>();
    const { doctorNameOptions, addressIdOptions, rowFilter } = useInvoiceItemFilter(orders, items, compact(addresses));
    const addressFilterName = addressIdOptions.find(a => a.id === addressId)?.label ?? '';
    const doctorFiltersLoading = loading && doctorNameOptions.length === 0;
    const addressFiltersLoading = addressesLoading && addressIdOptions.length === 0;
    /** end filter block */

    const rows = useRowsFromInvoiceItems(items, orders, credits, creditCategories);
    const visibleItemRows = React.useMemo(
        () => rows.filter(rowFilter({ doctorName, addressId })),
        [rows, doctorName, addressId, rowFilter],
    );

    return (
        <InvoiceDetailContext.Provider
            value={{
                invoice,
                refetchInvoice,
                rows: visibleItemRows,
                rowsLoading:
                    loading ||
                    doctorFiltersLoading ||
                    addressFiltersLoading ||
                    creditsLoading ||
                    creditCategoriesLoading,
                autochargeEnabled,
                /** doctor filters */
                doctorOptions: doctorNameOptions,
                doctorNameFilter: doctorName,
                setDoctorNameFilter: setDoctorName,
                /** address filters */
                addresses: addressIdOptions,
                setAddressIdFilter: setAddressId,
                addressIdFilter: addressId,
                addressFilterName,
                /** printability */
                printableSlipIsOpen: printableIsOpen,
                openPrintableSlip: openPrintable,
                closePrintableSlip: closePrintable,
                billingContactInfoLoading: billingContactDataLoading,
                organizationName: associatedPractices?.find(p => p.id === invoice.organization_id)?.name,
                billingEmail: billingEmails?.[0]?.primary_contact_email ?? undefined,
            }}
        >
            {children}
        </InvoiceDetailContext.Provider>
    );
};

export function useInvoiceDetailContext() {
    const ctx = React.useContext(InvoiceDetailContext);
    if (!ctx) {
        throw new Error('useInvoiceDetailContext must be used within a InvoiceDetailCtxProvider');
    }

    return ctx;
}
