import { LoginWrapper } from '../pre-auth/screens/login';
import { RecoverPassword } from '../pre-auth/screens/recover-password/RecoverPassword';
import { SetNewPassword } from '../pre-auth/screens/set-new-password/SetNewPassword';
import { LoginRoutes } from './LoginPaths';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

interface PracticeLoginRootProps {
    originalLocation: LocationDescriptorObject;
}

export const LoggedOutRoot: React.FC<PracticeLoginRootProps> = props => (
    <Switch>
        <CompatRoute path={LoginRoutes.recover_pass}>
            <RecoverPassword />
        </CompatRoute>
        <CompatRoute path={LoginRoutes.new_pass}>
            <SetNewPassword />
        </CompatRoute>
        <CompatRoute exact path={'*'}>
            <LoginWrapper originalLocation={props.originalLocation} />
        </CompatRoute>
    </Switch>
);
