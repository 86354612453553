import { graphql } from '@orthly/graphql-inline-react';

export const GetPracticeBillingOverview_Query = graphql(`
    query GetPracticeBillingOverview($partnerId: String!) {
        getPartnerBillingAccount(partner_id: $partnerId) {
            primary_billing_contact_user_id
            autocharge_enabled
            id
        }
    }
`);

export const GetPrimaryBillingUser_Query = graphql(`
    query GetPrimaryBillingContact($id: String!) {
        getUser(id: $id) {
            email
            first_name
            last_name
        }
    }
`);

export const GetActiveContractAndAssociatedOrgsByOrganizationId_Query = graphql(`
    query GetActiveContractAndAssociatedOrgsByOrganizationId($organizationId: String!) {
        getContractAndAssociatedOrgsByOrganizationId(organization_id: $organizationId) {
            allAssociatedPracticeIds
            allAssociatedPractices {
                id
                name
            }
            primaryPracticeId
            contract {
                id
                spend_terms {
                    effective_end_date
                    effective_start_date
                    id
                    spend_cycle_length
                    spend_minimum_cents
                }
            }
        }
    }
`);

export const GetPracticePendingInvoiceItemsForContract_Query = graphql(`
    query PreviewPracticePendingInvoiceItemsForActiveContract($targetCreationDate: DateTime!) {
        previewPracticePendingInvoiceItemsForActiveContract(targetCreationDate: $targetCreationDate) {
            amount_cents
            category
            id
            order_id
            organization_id
        }
    }
`);

export const ListInvoicesWithItemSummariesForActiveContract_Query = graphql(`
    query ListInvoicesWithItemSummariesForActiveContract {
        listInvoicesWithItemSummariesForActiveContract {
            amount_due
            amount_paid
            amount_remaining
            created_at
            due_date
            id
            organization_id
            invoice_number
            period_end
            period_start
            status
            total
            items_total_amount_cents
            items_order_count
            is_overdue
            pending_payment_amount_cents
        }
    }
`);

export const GetInvoiceById_Query = graphql(`
    query GetInvoiceById($invoiceId: String!) {
        getInvoiceById(invoiceId: $invoiceId) {
            amount_due
            amount_paid
            amount_remaining
            created_at
            due_date
            id
            invoice_number
            organization_id
            period_end
            period_start
            payments {
                amount_cents
                created_at
                status
                error_summary
                refunds {
                    amount_cents
                    created_at
                }
                stripe_charge_id
                stripe_payment_source_id
            }
            status
            summary_email_sent
            total
        }
    }
`);

export const ListInvoiceItemsForExport_Query = graphql(`
    query ListInvoiceItemsForExport($invoiceIds: [String!]!) {
        invoiceItems: listInvoiceItemsForExport(invoiceIds: $invoiceIds) {
            id
            category
            subcategory
            item_type
            invoice_id
            order_id
            amount_cents
            description
            recurring_item_id
            used_credit_id
            created_at
            updated_at

            order_created_at
            doctor_name
            mailing_address_id
        }
    }
`);

export const listInvoicePaymentsForExport_Query = graphql(`
    query ListInvoicePaymentsForExport($invoiceIds: [String!]!) {
        invoicePayments: listInvoicePaymentsForExport(invoiceIds: $invoiceIds) {
            invoice_id
            amount_cents
            created_at
            status
            error_summary
            refunds {
                amount_cents
                created_at
            }
            stripe_charge_id
            stripe_payment_source_id
        }
    }
`);

export const CalculatePracticeInvoicedSpendOverPeriod_Query = graphql(`
    query CalculatePracticeInvoicedSpendOverPeriod(
        $organizationId: String!
        $periodStart: DateTime!
        $periodEnd: DateTime!
    ) {
        calculatePracticeInvoicedSpendOverPeriod(
            organizationId: $organizationId
            periodStart: $periodStart
            periodEnd: $periodEnd
        ) {
            aggregate_spend_cents
            organization_id
        }
    }
`);
