import { InvoicesTableHeader } from './InvoicesTableHeader.graphql';
import { useInvoicesTableContext } from './components/providers/InvoicesTableProvider.graphql';
import { BillingContactList } from './invoices-table-components/BillingContactList';
import { InvoicesTableControls } from './invoices-table-components/InvoicesTableControls';
import { handleRowClick, InvoiceTableColumns, StyledCheckBox, tableRowIdPrefix } from './invoicing-table.utils';
import { previewUsageInvoiceId } from './invoicing.utils';
import { LoadBlocker } from '@orthly/ui';
import {
    ArrowSouthIcon,
    FlossPalette,
    FlossPaletteUtils,
    Icon,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Text,
    styled,
} from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

const StyledTableHeadRow = styled(TableRow)({
    height: '32px',
    borderTop: 'none',
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: FlossPalette.WHITE,
    position: 'sticky',
    zIndex: 1,
    top: '0px',
});

const StyledTableRow = styled(TableRow, {
    shouldForwardProp: p => p !== 'overdue' && p !== 'isPreview' && p !== 'isSelected',
})(({ overdue, isPreview, isSelected }: { overdue: boolean; isPreview: boolean; isSelected: boolean }) => {
    let backgroundColor = FlossPalette.WHITE;
    let hoverBackgroundColor = FlossPalette.TAN;

    // selection styles take precedence over overdue styles
    if (isSelected) {
        backgroundColor = FlossPalette.PRIMARY_BACKGROUND;
        hoverBackgroundColor = FlossPaletteUtils.toRgba('PRIMARY_BACKGROUND', 0.8);
    } else if (overdue) {
        backgroundColor = FlossPalette.ATTENTION_BACKGROUND;
        hoverBackgroundColor = FlossPaletteUtils.toRgba('ATTENTION_BACKGROUND', 0.8);
    }

    return {
        cursor: isPreview ? 'default' : 'pointer',
        height: '64px',
        width: '100%',
        backgroundColor,
        '&:hover': {
            backgroundColor: hoverBackgroundColor,
        },
    };
});

const HeaderTextContainer = styled('div')({
    position: 'sticky',
    zIndex: 1,
    top: 0,
    backgroundColor: FlossPalette.WHITE,
    width: '100%',
    padding: '0px 0px 24px 24px',
});

const Container = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const TableContainer = styled('div')({
    overflow: 'scroll',
    background: FlossPalette.WHITE,
    margin: '0px 24px 24px',
});

export const InvoicesTable: React.FC = () => {
    const { tableInvoices, loading, selectedInvoiceIds, setSelectedInvoiceIds } = useInvoicesTableContext();
    const history = useHistory();

    const latestInvoice = tableInvoices.sort(
        (a, b) => dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf(),
    )[0];
    const rowClickRef = React.useRef({ history });

    // prevents double click onRowClick
    React.useEffect(() => {
        rowClickRef.current = { history };
    }, [history]);

    const rowClickCallback = React.useMemo(() => {
        return handleRowClick(rowClickRef);
    }, []);

    const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('desc');

    const toggleSortDirection = () => {
        setSortDirection(curr => (curr === 'asc' ? 'desc' : 'asc'));
    };

    const sortedInvoices = tableInvoices.sort((a, b) => {
        if (sortDirection === 'asc') {
            return dayjs(a.period_start).valueOf() - dayjs(b.period_start).valueOf();
        }
        return dayjs(b.period_start).valueOf() - dayjs(a.period_start).valueOf();
    });

    const columns = InvoiceTableColumns();

    return (
        <Container>
            <InvoicesTableHeader
                lastInvoiceDate={latestInvoice ? dayjs(latestInvoice.created_at).toDate() : undefined}
            />
            <BillingContactList />
            <HeaderTextContainer>
                <Text variant={'h6'} medium data-test={'invoices-table-title'}>
                    Invoices
                </Text>
            </HeaderTextContainer>
            <TableContainer data-test={'invoices-table'}>
                <LoadBlocker blocking={loading}>
                    <Table>
                        <StyledTableHead>
                            <StyledTableHeadRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.name}
                                        variant={'head'}
                                        style={{
                                            color: FlossPalette.GRAY,
                                            fontWeight: 400,
                                            minWidth: column.width ?? '120px',
                                            ...column.style,
                                        }}
                                        width={column.width ?? 'auto'}
                                    >
                                        {column.title ?? column.name}
                                        {column.sort && (
                                            <TableSortLabel
                                                active={true}
                                                direction={sortDirection}
                                                IconComponent={ArrowSouthIcon}
                                                onClick={toggleSortDirection}
                                            />
                                        )}
                                        {column.name === 'checkbox' && (
                                            <StyledCheckBox
                                                color={'secondary'}
                                                size={'small'}
                                                icon={<Icon icon={'CheckboxIcon'} fontSize={'small'} />}
                                                checkedIcon={<Icon icon={'CheckboxCheckedIcon'} fontSize={'small'} />}
                                                checked={
                                                    // the pending invoice is not payable, so it is not checkable
                                                    selectedInvoiceIds.length === tableInvoices.length - 1
                                                }
                                                onChange={_ => {
                                                    if (
                                                        // the pending invoice is not payable, so it is not checkable
                                                        selectedInvoiceIds.length ===
                                                        tableInvoices.length - 1
                                                    ) {
                                                        setSelectedInvoiceIds([]);
                                                    } else {
                                                        setSelectedInvoiceIds(
                                                            tableInvoices.reduce<string[]>((acc, curr) => {
                                                                // the pending invoice is not payable, so it is not checkable
                                                                if (curr.pending_items) {
                                                                    return acc;
                                                                }
                                                                acc.push(curr.id);
                                                                return acc;
                                                            }, []),
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </StyledTableHeadRow>
                        </StyledTableHead>
                        <TableBody>
                            {sortedInvoices.map(invoice => (
                                <StyledTableRow
                                    key={invoice.id}
                                    id={`${tableRowIdPrefix}-${invoice.id}`}
                                    overdue={invoice.is_overdue}
                                    onClick={e => rowClickCallback(e, invoice)}
                                    isPreview={invoice.id === previewUsageInvoiceId}
                                    isSelected={selectedInvoiceIds.includes(invoice.id)}
                                >
                                    {columns.map(column => {
                                        if (typeof column.render === 'function') {
                                            return (
                                                <TableCell
                                                    key={`${column.name}-render`}
                                                    style={{ ...column.bodyCellWrapStyle }}
                                                >
                                                    {column.render(invoice)}
                                                </TableCell>
                                            );
                                        }
                                    })}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <InvoicesTableControls />
                </LoadBlocker>
            </TableContainer>
        </Container>
    );
};
