import type { AlignerEstimateStep } from '@orthly/shared-types';
import { orderedAlignerEstimateSteps } from '@orthly/shared-types';
import { FlossPalette, stylesFactory, Fade, Text, CheckIcon } from '@orthly/ui-primitives';
import React from 'react';

const LABEL_BY_STEP: { [K in AlignerEstimateStep]: string } = {
    AnalyzingScan: 'Analyzing scan',
    CheckingSmileConcerns: 'Checking smile concerns',
    ReviewingPhotos: 'Reviewing photos',
    CalculatingResults: 'Calculating results',
    Complete: '',
};

const useStyles = stylesFactory<{ stepIndex: number; activeStepIndex: number }>(theme => ({
    '@keyframes pulse1': {
        '0%': {
            backgroundColor: FlossPalette.STAR_GRASS,
        },
        '100%': {
            opacity: 0.5,
        },
    },
    row: ({ stepIndex, activeStepIndex }) => ({
        alignItems: 'center',
        borderRadius: 16,
        backgroundColor: stepIndex === activeStepIndex ? FlossPalette.STAR_GRASS : FlossPalette.TAN,
        display: 'flex',
        height: 64,
        marginBottom: 8,
        width: 336,
    }),
    pulsingRow: {
        // This is a separate class because putting the animation name in a callback breaks the animation https://github.com/mui-org/material-ui/issues/13793
        animation: `$pulse1 1s ${theme.transitions.easing.easeInOut} infinite alternate`,
    },
    stepNumber: ({ stepIndex, activeStepIndex }) => ({
        alignItems: 'center',
        backgroundColor:
            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
            // eslint-disable-next-line no-nested-ternary
            stepIndex === activeStepIndex
                ? FlossPalette.WHITE
                : stepIndex < activeStepIndex
                  ? FlossPalette.STAR_GRASS
                  : FlossPalette.DARK_TAN,
        borderRadius: 8,
        color:
            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
            // eslint-disable-next-line no-nested-ternary
            stepIndex === activeStepIndex
                ? FlossPalette.WHITE
                : stepIndex < activeStepIndex
                  ? FlossPalette.STAR_GRASS
                  : FlossPalette.TAN,
        display: 'flex',
        height: 48,
        justifyContent: 'center',
        margin: 8,
        position: 'relative',
        width: 48,
    }),
    stepNumberContent: {
        position: 'absolute',
        margin: 'auto',
    },
    text1: ({ stepIndex, activeStepIndex }) => ({
        color: stepIndex === activeStepIndex ? FlossPalette.STAR_GRASS : FlossPalette.WHITE,
    }),
    text2: ({ stepIndex, activeStepIndex }) => ({
        color: stepIndex === activeStepIndex ? FlossPalette.WHITE : FlossPalette.BLACK,
    }),
}));

interface EstimateProgressRowProps {
    step: AlignerEstimateStep;
    activeStep: AlignerEstimateStep;
}

export const EstimateProgressRow: React.FC<EstimateProgressRowProps> = ({ step, activeStep }) => {
    const activeStepIndex = orderedAlignerEstimateSteps.indexOf(activeStep);
    const stepIndex = orderedAlignerEstimateSteps.indexOf(step);
    const classes = useStyles({ stepIndex, activeStepIndex });
    const isBeforeCurrentStep = stepIndex < activeStepIndex;
    const isCurrentStep = stepIndex === activeStepIndex;

    return (
        <div className={`${classes.row} ${isCurrentStep ? classes.pulsingRow : ''}`}>
            <div className={classes.stepNumber}>
                <Fade in={isBeforeCurrentStep} mountOnEnter unmountOnExit>
                    <CheckIcon className={classes.stepNumberContent} color={'secondary'} />
                </Fade>
                <Fade in={!isBeforeCurrentStep} mountOnEnter unmountOnExit>
                    <Text className={`${classes.text1} ${classes.stepNumberContent}`} variant={'h6'}>
                        {stepIndex + 1}
                    </Text>
                </Fade>
            </div>
            <div>
                <Text
                    className={classes.text2}
                    variant={'body1'}
                    style={{ fontWeight: 700 }}
                    color={isCurrentStep ? 'WHITE' : 'BLACK'}
                >
                    {LABEL_BY_STEP[step]}
                </Text>
            </div>
        </div>
    );
};
